import React, { FC, useState, useEffect, memo } from 'react';
import { Button, Center, Text, useTheme, Tooltip, Box, Pressable } from 'native-base';
import { IQuizLearningObjective, IQuizLearningUnit } from '../types/QuizTypes';
import { useTranslation } from 'react-i18next';
import island_1 from '../images/islands/island_1.png'
import island_2 from '../images/islands/island_2.png'
import island_3 from '../images/islands/island_3.png'
import island_4 from '../images/islands/island_4.png'
import island_5 from '../images/islands/island_5.png'
import island_6 from '../images/islands/island_6.png'
import island_7 from '../images/islands/island_7.png'
import island_8 from '../images/islands/island_8.png'

interface QuizProgressButtonProps {
  type: 'learningUnit' | 'learningObjective';
  onPress?: () => void;
}

type PropsType = QuizProgressButtonProps &
  (IQuizLearningUnit | IQuizLearningObjective);

const QuizProgressButton: FC<PropsType> = memo((props) => {
  const { colors } = useTheme();
  const { t, i18n } = useTranslation()

  const size = 120;
  const strokeWidth = 20;
  const circleOneStroke = props.isUnlocked ? '#D3D3D3AA' : '#D3D3D3AA';
  const circleTwoStroke = colors.primary[500];


  const [offset, setOffset] = useState(0);

  const center = size / 2;
  const radius = size / 2 - strokeWidth / 2;
  const circumference = 2 * Math.PI * radius;

  const islands = [
    island_1,
    island_1,
    island_2,
    island_3,
    island_4,
    island_5,
    island_6,
    island_7,
    island_8,
  ]

  useEffect(() => {
    const progressOffset = ((1 - props.progress)) * circumference;
    setOffset(progressOffset);
  }, [setOffset, circumference, props.progress, offset]);

  return (
    <Pressable
      key={props._id}
      w={props.type === 'learningUnit' ? '250px' : '300px'}
      padding={'10px'}
      disabled={!props.isUnlocked}
      onPress={props.onPress}
    >
      <Box w={'100%'}>
        <Tooltip label={t('This is locked. Unlock the previous unit first!')} isDisabled={props.isUnlocked} openDelay={500}>
          <Center w={'100%'}>
            <svg width={size} height={size}>
              <circle
                style={{ fill: 'none' }}
                stroke={circleOneStroke}
                cx={center}
                cy={center}
                r={radius}
                strokeWidth={strokeWidth}
              />
              <circle
                transform={`rotate(-90 ${center} ${center})`}
                style={{ fill: 'none' }}
                stroke={circleTwoStroke}
                cx={center}
                cy={center}
                r={radius}
                strokeWidth={strokeWidth}
                strokeDasharray={circumference}
                strokeDashoffset={offset}
              />
              <circle
                style={{ fill: props.isUnlocked ? '#FFFFFFCC' : '#a8a8a8CC' }}
                cx={center}
                cy={center}
                r={radius - (strokeWidth / 2)}
              />
              <text
                style={{
                  fontSize: props.type === 'learningUnit' ? '28px' : '24px',
                  textAnchor: 'middle',
                  fontWeight: 'bold',
                  dominantBaseline: 'middle',
                  fill: props.isUnlocked ? 'black' : '#808080',
                }}
                x={center}
                y={center}
              >
                {props.code}
              </text>

              {props.type === 'learningObjective' &&
                <>
                  <rect x={size - 35} y={size - 35} rx="20" ry="20" width="30" height="30" style={{
                    fill: (props as IQuizLearningObjective).remainingAttempts < 2 ? 'red' : colors.success[500],
                    stroke: "black",
                    strokeWidth: 5,
                    opacity: 0.4
                  }} />
                  <text
                    style={{
                      fontSize: '20px',
                      textAnchor: 'middle',
                      fontWeight: 'bold',
                      dominantBaseline: 'middle',
                      fill: props.isUnlocked ? 'black' : '#808080',
                    }}
                    x={size - 20}
                    y={size - 18}
                  >
                    {(props as IQuizLearningObjective).remainingAttempts === 999 ? '∞' : (props as IQuizLearningObjective).remainingAttempts}
                  </text>
                </>
              }
            </svg>
          </Center>
        </Tooltip>
        <Text
          fontSize={props.type === 'learningUnit' ? "20px" : "20px"}
          w='100%'
          textAlign={'center'}
          color={props.isUnlocked ? '#000000' : '#808080'}
        >
          {i18n.language === 'zh' && props.nameChi !== '' ? props.nameChi : props.nameEng}
        </Text>
      </Box>
    </Pressable>
  );
});

export default QuizProgressButton;
