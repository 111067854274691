import React, { useEffect, useState } from "react";
import { useToast, Button, Modal as NbModal, IconButton } from "native-base";
import { Modal, Alert, AlertTitle, TextField } from "@mui/material";
import MaterialTable, { Column } from "@material-table/core";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { IoCloseOutline } from "react-icons/io5";
import * as XLSX from "xlsx";
import { tableIcons } from "../MaterialTableIcons";
import { adminCreateUserBulk, adminUpdateUser, getUsers } from "../api";
import { checkEmailFormat } from "../utils/authHelper";
import { RootState } from "../redux/reducers";
import moment from "moment";
import { useMutation, useQuery } from 'react-query';
import { IUser } from "../types/AuthTypes";

const mapStateToProps = (state: RootState) => ({
  user: state.auth.user,
  currentRole: state.auth.currentRole,
});

interface ContentProviderCsvUploadPreviewModalProps {
  file: File | undefined;
  onClose: () => void;
  onSubmitSuccess: (data?: any) => void;
}

const connector = connect(mapStateToProps);
type PropsType = ConnectedProps<typeof connector> &
  RouteComponentProps &
  ContentProviderCsvUploadPreviewModalProps;

const ContentProviderCsvUploadPreviewModal: React.FC<PropsType> = (props) => {
  const [contentProviders, setContentProviders] = useState<any[]>([]);
  const [columnObject, setColumnObject] = useState<Column<any>[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isBulkEditing, setIsBulkediting] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();

  const { t } = useTranslation("translation");

  const requiredFields = ['email', 'lastNameEng', 'firstNameEng'];

  const { data: usersData } = useQuery('Users', () => getUsers(), {
    refetchOnWindowFocus: false,
    onError: () => {
      setError("Something went wrong. Description: Cannot fetch users");
      setTimeout(() => {
        setError(undefined);
      }, 3000);
    },
  });

  const importMutation = useMutation(adminCreateUserBulk, {
    onSuccess: () => {
      props.onSubmitSuccess('import');
      props.onClose();
    },
    onError: (error: any) => {
      setError(`Failed to import students. Description: ${JSON.stringify(error.response?.data) ?? "Error occurred"}`);
      setTimeout(() => {
        setError(undefined);
      }, 5000);
    },
  });

  useEffect(() => {
    setIsLoading(true);
    setColumnObject([
      {
        title: `${t("Email")} *`,
        type: "string",
        field: "email",
        editComponent: (props: any) => (
          <TextField
            variant="standard"
            disabled={typeof props.rowData._id === "string"}
            value={props.value}
            onChange={(e) => {
              props.onChange(e.target.value);
            }}
          />
        ),
        validate: (rowData) => rowData.email && checkEmailFormat(rowData.email),
      },
      {
        title: `${t("Last Name")}  (${t("Eng")})*`,
        type: "string",
        field: "lastNameEng",
      },
      {
        title: `${t("First Name")}  (${t("Eng")})*`,
        type: "string",
        field: "firstNameEng",
      },
      {
        title: `${t("Last Name")}  (${t("Chi")})`,
        type: "string",
        field: "lastNameChi",
      },
      {
        title: `${t("First Name")}  (${t("Chi")})`,
        type: "string",
        field: "firstNameChi",
      },
      {
        title: t("Date of Birth"),
        type: "string",
        field: "dateOfBirth",
        render: (rowData) => {
          return moment(rowData.dateOfBirth).format("YYYY-MM-DD");
        },
      },
      {
        title: t("Gender"),
        type: "string",
        field: "gender",
        lookup: { M: "Male", F: "Female" },
      },
      {
        title: t("Password"),
        field: "password",
        type: "string",
        render: (rowData) => {
          if (rowData.isNewUser && !rowData.password) {
            return t("Password required")
          } else {
            return rowData.password
          }
        },
      },
      {
        title: t("Active"),
        field: "isActive",
        type: "boolean",
      },
    ]);
  }, []);

  const getGenderCharacter = (gender: string) => {
    if (gender === 'F' || gender === 'M') {
      return gender;
    }
    switch (gender?.toString().toLowerCase()) {
      case 'female':
        return 'F';
      case 'male':
        return 'M';
      default:
        return null;
    }
  };

  const parseFile = () => {
    return new Promise<any[]>((resolve) => {
      const reader = new FileReader();
      if (props.file) {
        reader.readAsArrayBuffer(props.file);
        reader.onload = async (e: any) => {
          const bufferArray = e.target.result;
          const sheets = XLSX.read(bufferArray, {
            type: "buffer",
            cellDates: true,
          });
          const teachersWs = sheets.Sheets[sheets.SheetNames[0]];
          const contentProviders = XLSX.utils.sheet_to_json(teachersWs);

          const data = contentProviders.map((i: any, index) => ({
            _id: i['User ID'] ?? i['用戶 ID'] ?? index,
            isNewUser: ((i['User ID'] ?? i['用戶 ID'] ?? "") === "" ? true : false),
            email: i.email ?? i["Email"] ?? i["電郵"] ?? "",
            lastNameEng:
              i.last_name_eng ?? i["Last Name (Eng)"] ?? i["姓 (英)"] ?? "",
            firstNameEng:
              i.first_name_eng ?? i["First Name (Eng)"] ?? i["名 (英)"] ?? "",
            lastNameChi:
              i.last_name_chi ?? i["Last Name (Chi)"] ?? i["姓 (中)"] ?? "",
            firstNameChi:
              i.first_name_chi ?? i["First Name (Chi)"] ?? i["名 (中)"] ?? "",
            dateOfBirth:
              i.date_of_birth ?? i["Date of Birth"] ?? i["出生日期"] ?? "",
            gender:
              i.gender ?? getGenderCharacter(i["Gender"]) ?? i["性別"] ?? "",
            isActive: i.isActive ?? i["Active"] ?? i["活躍"] ?? true,
            password:
              (i["User ID"] ?? i["用戶 ID"] ?? undefined) === undefined
                ? i["Password"] ?? i["密碼"]
                : "",
          }));
          resolve(data);
        };
      } else {
        resolve([]);
      }
    });
  };

  useEffect(() => {
    async function parseFilePromise() {
      const data = await parseFile();
      setContentProviders(data);
      setIsLoading(false);
    }
    parseFilePromise();
  }, []);

  const handleSubmit = async () => {
    setIsLoading(true);

    const isAllRequiredFieldsFilled = contentProviders.every((contentProvider: any) => {
      return Object.entries(contentProvider).every(([key, value]) => {
        if (!requiredFields.includes(key)) {
          return true;
        } else {
          if (value === undefined || value === '') {
            return false;
          } else {
            return true;
          }
        }
      });
    });

    const emailErrors = contentProviders.filter((contentProvider) => !checkEmailFormat(contentProvider.email));

    if (!isAllRequiredFieldsFilled) {
      setError(t('Some required fields are not filled'));
      setTimeout(() => {
        setError(undefined);
      }, 3000);
    } else if (emailErrors.length > 0) {
      setError(t('Email format is not correct'));
      setTimeout(() => {
        setError(undefined);
      }, 3000);
    }  else {
      const result = contentProviders.reduce(
        (res, contentProvider) => {
          res[usersData?.data.some((oldUser: IUser) => contentProvider._id === oldUser._id) ? 'oldUsers' : 'newUsers'].push(contentProvider);
          return res;
        },
        { oldUsers: [], newUsers: [] }
      );
      if (result.newUsers.every((newUser: any) => {
        return !newUser.password
      }) && result.newUsers.length > 0) {
        setError(t('Some required fields are not filled'));
        setTimeout(() => {
          setError(undefined);
        }, 3000);
      } else{
        if (result.oldUsers.length > 0) {
          await Promise.all(
            result.oldUsers.map(async (user: any) => {
              let editedStudent = usersData?.data.find((data: any) => data._id === user._id);

              Object.keys(editedStudent).forEach((key) => {
                if (key !== 'userProfile') {
                  editedStudent[key] = user[key];
                }
              });

              if (user.password !== ""){
                editedStudent.password = user.password
              }

              if (editedStudent.userProfile.student) {
                editedStudent.userProfile.student.schoolId = editedStudent.userProfile.student.school
                delete editedStudent.userProfile.student.school;
              }

              if (editedStudent.userProfile.teacher) {
                editedStudent.userProfile.teacher.schoolId = editedStudent.userProfile.teacher.school
                delete editedStudent.userProfile.teacher.school;
              }

              editedStudent.userProfile.contentProvider = {
                isActive: user.isActive,
              };

              return adminUpdateUser(editedStudent._id, editedStudent);
            })
          );
          if (result.newUsers.length === 0) {
            props.onSubmitSuccess('edit');
            props.onClose();
          }
        }
        if (result.newUsers.length > 0) {
          let payload = result.newUsers.map((i: any) => ({
            ...i,
            userProfile: {
              contentProvider : {
                isActive: i.isActive,
              },
            },
          }));
          importMutation.mutate(payload);
        }
      }
    }
    setIsLoading(false);
  };

  return (
    <>
      <Modal open={true} onClose={props.onClose}>
        <NbModal.Content height="100%" maxHeight="100%">
          <NbModal.Header background="primary.500" textTransform="capitalize">
            {t("Preview")}
            <IconButton
              style={{ position: "absolute", right: 10, top: 10 }}
              icon={<IoCloseOutline size={20} />}
              onPress={props.onClose}
            />
          </NbModal.Header>
          <NbModal.Body minHeight="md">
            <MaterialTable
              icons={tableIcons}
              onBulkEditOpen={(isOpen) => setIsBulkediting(isOpen)}
              style={{ width: "100%" }}
              title={t("Content Providers to be Imported")}
              isLoading={isLoading || importMutation.isLoading}
              columns={columnObject}
              data={contentProviders}
              options={{
                pageSize: 10,
                pageSizeOptions: [10, 25, 50],
                filtering: true,
              }}
              editable={{
                onBulkUpdate: (change) => {
                  let newData = Object.values(change).map((row) => row.newData);
                  const accountsById = new Map(newData.map((i) => [i._id, i]));
                  const updated = contentProviders.map((i) => {
                    const updatedSysAdmins = accountsById.get(i._id);
                    if (updatedSysAdmins) {
                      return updatedSysAdmins;
                    } else {
                      return i;
                    }
                  });
                  setContentProviders(updated);
                  return Promise.resolve();
                },
              }}
            />
            {error !== undefined && (
              <Alert
                severity="error"
                style={{
                  position: "fixed",
                  zIndex: 10000,
                  top: "50%",
                  backgroundColor: "#f8b6b6",
                  width: "90%",
                  margin: "2.5%",
                }}
              >
                <AlertTitle>Error</AlertTitle>
                {error}
              </Alert>
            )}
          </NbModal.Body>
          <NbModal.Footer alignItems="center" justifyContent="center">
            {!isBulkEditing && (
              <Button
                w="full"
                variant="subtle"
                onPress={handleSubmit}
                isLoading={importMutation.isLoading || isLoading}
              >
                {t("Submit")}
              </Button>
            )}
          </NbModal.Footer>
        </NbModal.Content>
      </Modal>
    </>
  );
};

export default withRouter(connector(ContentProviderCsvUploadPreviewModal));
