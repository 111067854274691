import axios from "axios";
import { auth } from "../utils/authHelper";

axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URL;
axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

axios.interceptors.request.use(
  async (config) => {
    // Do something before request is sent
    const token = await auth.currentUser?.getIdToken();
    config.headers!.Authorization = `Bearer ${token}`;
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  async function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    // if (error.response.status === 401) {
    //   const token = await auth.currentUser?.getIdToken(true);
    //   error.config.headers.token = token;
    //   return axios.request(error.config);
    // }
    return Promise.reject(error);
  }
);

export default axios;
