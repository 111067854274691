import { Box, Center } from 'native-base';
import { useTranslation } from 'react-i18next';
import MaterialTable from '@material-table/core';
import { tableIcons } from '../MaterialTableIcons';
import { useEffect, useState } from 'react';
import { IQuiz } from '../types/QuizTypes';
import { ILearningUnit } from '../types/LearningUnitType';
import { ILearningUnitArea } from '../types/LearningUnitAreaType';
import { getLearningUnitAreas, getLearningUnitAreasIsPublic, getLearningUnits, getLearningUnitsIsPublic } from '../api';
import { useQuery } from 'react-query';
import { ILearningObjective } from '../types/LearningObjectiveType';
import { ExportCsv } from '@material-table/exporters';
import dayjs from 'dayjs';

interface TeacherSummaryLearningObjectiveTableProps {
  data: IQuiz[] | undefined;
}

interface ILearningObjectiveSummaryTable {
  learningUnit: ILearningUnit;
  learningUnitArea: ILearningUnitArea;
  learningObjective: ILearningObjective;
  total: number;
  numOfAttempt: number;
  avg: number;
  displayedTotal: string;
  displayedAvg: string;
}
  
const TeacherSummaryLearningObjectiveTable: React.FC<TeacherSummaryLearningObjectiveTableProps> = (props) => {
  const { t, i18n } = useTranslation();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [learningObjectiveData, setLearningObjectiveData] = useState<ILearningObjectiveSummaryTable[]>([]);
  const [areaMap, setAreaMap] = useState<{ [key: string]: string }>();
  const [unitMap, setUnitMap] = useState<{ [key: string]: string }>();

  const {
    data: learningUnitAreas,
  } = useQuery('learningUnitArea', () => getLearningUnitAreas());

  const {
    data: learningUnitAreasIsPublic,
  } = useQuery('learningUnitAreaIsPublic', () => getLearningUnitAreasIsPublic());

  const {
    data: learningUnits,
  } = useQuery('learningUnit', () => getLearningUnits());

  const {
    data: learningUnitsIsPublic,
  } = useQuery('learningUnitIsPublic', () => getLearningUnitsIsPublic());

  useEffect(() => {
    setIsLoading(true);
    let result: ILearningObjectiveSummaryTable[] = [];
    props.data?.forEach((quiz) => {
      if(!result.some((data) => data.learningObjective._id === quiz.learningObjective._id)) {
        result = [...result, {
          learningUnit: quiz.learningUnit, 
          learningUnitArea: quiz.learningUnitArea,
          learningObjective: quiz.learningObjective,
          total: quiz.score*100,
          numOfAttempt: 1, 
          avg: 0,
          displayedTotal: "",
          displayedAvg: ""
        }]
      } else {
        let target = result.find((data) => data.learningObjective._id === quiz.learningObjective._id);
        if (target !== undefined) {
          target.total += quiz.score*100;
          target.numOfAttempt++;
        }
      }
    })

    result.forEach((data) => {
      data.avg = Math.round((data.total / data.numOfAttempt) * 10) / 10;
      // Use string for a better dispay in the table
      data.displayedTotal = data.total.toFixed(1);
      data.displayedAvg = data.avg.toFixed(1);
      return data;
    })

    // Sort by name
    result.sort((a, b) => {
      if (a.learningUnitArea.nameEng < b.learningUnitArea.nameEng) {
        return -1;
      } else if (a.learningUnitArea.nameEng > b.learningUnitArea.nameEng){
        return 1;
      } else if (a.learningUnit.nameEng < b.learningUnit.nameEng) {
        return -1;
      } else if (a.learningUnitArea.nameEng > b.learningUnitArea.nameEng){
        return 1;
      } else if (a.learningObjective.nameEng < b.learningObjective.nameEng) {
        return -1;
      } else if (a.learningObjective.nameEng > b.learningObjective.nameEng){
        return 1;
      }
      return 0
    })

    setLearningObjectiveData(result);
    setIsLoading(false);
  }, [areaMap, unitMap]);

  useEffect(() => {
    const _areaMap = learningUnitAreas?.reduce<{ [key: string]: string }>(
      (map, obj) => {
        return { ...map, [obj._id]: i18n.language === 'zh' && obj.nameChi !== null ? obj.nameChi : obj.nameEng }
      },
      {}
    );

    const _areaMapIsPublic = learningUnitAreasIsPublic?.reduce<{ [key: string]: string }>(
      (map, obj) => {
        return { ...map, [obj._id]: i18n.language === 'zh' && obj.nameChi !== null ? obj.nameChi : obj.nameEng }
      },
      {}
    );

    setAreaMap({
      ..._areaMap,
      ..._areaMapIsPublic
    });

  }, [learningUnitAreas, learningUnitAreasIsPublic, i18n]);

  useEffect(() => {
    const _unitMap = learningUnits?.reduce<{ [key: string]: string }>(
      (map, obj) => {
        return { ...map, [obj._id]: i18n.language === 'zh' && obj.nameChi !== null ? obj.nameChi : obj.nameEng }
      },
      {}
    );
    const _unitMapIsPublic = learningUnitsIsPublic?.reduce<{ [key: string]: string }>(
      (map, obj) => {
        return { ...map, [obj._id]: i18n.language === 'zh' && obj.nameChi !== null ? obj.nameChi : obj.nameEng }
      },
      {}
    );
    setUnitMap({
      ..._unitMap,
      ..._unitMapIsPublic
    });
  }, [learningUnits, learningUnitsIsPublic, i18n]);

  return (
    <Box>
      <Center padding="10px">
        <MaterialTable
          style={{ width: '100%' }}
          title={t('Learning Objective')}
          icons={tableIcons}
          isLoading={isLoading}
          columns={[
            {
              title: `${t('Name')}  (${t('Eng')})`,
              type: 'string',
              field: 'learningObjective.nameEng',
            },
            {
              title: `${t('Name')}  (${t('Chi')})`,
              type: 'string',
              field: 'learningObjective.nameChi',
            },
            {
              title: t('Code'),
              type: 'string',
              field: 'learningObjective.code',
            },
            {
              title: t('Learning Unit'),
              type: 'string',
              lookup: unitMap,
              field: 'learningUnit._id',
            },
            {
              title: t('Learning Unit Area'),
              type: 'string',
              field: 'learningUnitArea._id',
              lookup: areaMap,
            },
            {
              title: t('Total Score'),
              type: 'string',
              field: 'displayedTotal',
            },
            {
              title: t('Quantity'),
              type: 'string',
              field: 'numOfAttempt',
            },
            {
              title: t('Average Score'),
              type: 'string',
              field: 'displayedAvg',
            }
          ]}
          data={learningObjectiveData ?? []}
          options={{
            pageSize: 10,
            pageSizeOptions: [10, 25, 50],
            filtering: true,
            exportAllData: true,
            exportMenu: [
              {
                label: t('Export Summary'),
                exportFunc: (cols, data) => {
                  return ExportCsv(cols, data, `Learning Objective Summary Export ${dayjs().format('YYYYMMDDHHmmss')}`);
                },
              },
            ],
          }}
        />
      </Center>
    </Box>
  )
}

export default TeacherSummaryLearningObjectiveTable;
