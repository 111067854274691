import axios from "../config/axios";
import { CreateLearningMaterialRequest, ILearningMaterial } from "../types/LearningMaterialType";

export const getLearningMaterials = async (learningObjectiveId?: string): Promise<ILearningMaterial[]> => {
  const queryString = learningObjectiveId ? `?learningObjectiveId=${learningObjectiveId}` : "";
  return axios.get(`/learningmaterials${queryString}`).then((res) => res.data);
}

export const getLearningMaterialsIsContentProvider = async (learningObjectiveId?: string): Promise<ILearningMaterial[]> => {
  const queryString = learningObjectiveId ? `?learningObjectiveId=${learningObjectiveId}&isContentProvider=true&allData=false` : "?isContentProvider=true&allData=false";
  return axios.get(`/learningmaterials${queryString}`).then((res) => res.data);
}

export const getLearningMaterialsByStudentRole = async (learningObjectiveId?: string): Promise<ILearningMaterial[]> => {
  const queryString = learningObjectiveId ? `?learningObjectiveId=${learningObjectiveId}&isPublic=true` : "?isPublic=true";
  return axios.get(`/learningmaterials/student${queryString}`).then((res) => res.data);
}

export const addLearningMaterial = async (learningMaterial: CreateLearningMaterialRequest): Promise<ILearningMaterial> => {
  return axios.post("/learningmaterials", learningMaterial).then((res) => res.data);
}

export const deleteLearningMaterial = async (learningMaterialId: string): Promise<void> => {
  return axios.delete(`/learningmaterials/${learningMaterialId}`);
}

export const updateLearningMaterial = async (learningMaterial: CreateLearningMaterialRequest): Promise<ILearningMaterial> => {
  return axios.put(`/learningmaterials/${learningMaterial._id}`, learningMaterial).then((res) => res.data);
}