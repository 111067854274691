import React, { useEffect, useState, useRef } from 'react';
import { useToast, Button, Modal as NbModal, IconButton, Icon } from 'native-base';
import Modal from '@mui/material/Modal';
import { tableIcons } from '../MaterialTableIcons';
import MaterialTable, { Column } from '@material-table/core';
import { useMutation, useQueries, useQuery } from 'react-query';
import { adminUpdateUser, bulkCreateStudent, getLearningObjectives, getLearningUnits, getSchools, getStudentsBySchool, getSubjects, getUsers } from '../api';
import { Edit } from '@material-ui/icons';
import QuestionManagementModal from './QuestionManagementModal';
import { INewQuestion } from '../types/QuestionTypes';
import { createMarkup } from '../utils/htmlHelper';
import * as XLSX from 'xlsx';
import { useTranslation } from 'react-i18next';
import { checkEmailFormat } from '../utils/authHelper';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../redux/reducers';
import { RouteComponentProps, withRouter } from 'react-router';
import { IoCloseOutline } from 'react-icons/io5';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { TextField } from '@mui/material';
import { IUser } from '../types/AuthTypes';

const mapStateToProps = (state: RootState) => ({
  user: state.auth.user,
  currentRole: state.auth.currentRole,
});

interface StudentCsvUploadPreviewModalProps {
  file: File | undefined;
  onClose: () => void;
  onSubmitSuccess: (status: string) => void;
}

const connector = connect(mapStateToProps);
type PropsType = ConnectedProps<typeof connector> & RouteComponentProps & StudentCsvUploadPreviewModalProps;

interface PreviewData {
  _id: any;
  isNewUser: boolean;
  email: any;
  lastNameEng: any;
  firstNameEng: any;
  lastNameChi: any;
  firstNameChi: any;
  dateOfBirth: any;
  gender: any;
  studentId: any;
  grade: any;
  class: any;
  number: any;
  yearOfEntry: any;
  schoolId: any;
  isActive: any;
  password: any;
  schoolNameChi?: string;
  schoolNameEng?: string;
}

const StudentCsvUploadPreviewModal: React.FC<PropsType> = (props) => {
  const { t, i18n } = useTranslation('translation');

  const schoolId =
    (props.currentRole === "teacher" || props.currentRole === "systemAdmin") && props.user?.userProfile.teacher
      ? props.user?.userProfile.teacher?.school._id
      : "";
  
  const requiredFields = ['email', 'lastNameEng', 'firstNameEng', 'schoolId'];

  const [students, setStudents] = useState<any[]>([]);
  const [columnObject, setColumnObject] = useState<Column<any>[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isBulkEditing, setIsBulkediting] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();

  const importMutation = useMutation(bulkCreateStudent, {
    onSuccess: () => {
      props.onSubmitSuccess('import');
      props.onClose();
    },
    onError: (error: any) => {
      setError(`Failed to import students. Description: ${JSON.stringify(error.response?.data) ?? "Error occurred"}`);
      setTimeout(() => {
        setError(undefined);
      }, 5000);
    },
  });

  useEffect(() => {
    setIsLoading(true);
    setColumnObject([
      {
        title: t('Student') + ' ID',
        type: 'string',
        field: 'studentId',
      },
      {
        title: `${t('Email')} *`,
        type: 'string',
        field: 'email',
        editComponent: (props: any) => (
          <TextField
            variant="standard"
            disabled={typeof props.rowData._id === 'string'}
            value={props.value}
            onChange={(e) => {
              props.onChange(e.target.value);
            }}
          />
        ),
        validate: (rowData) => rowData.email && checkEmailFormat(rowData.email),
      },
      {
        title: `${t('Last Name')}  (${t('Eng')})*`,
        type: 'string',
        field: 'lastNameEng',
      },
      {
        title: `${t('First Name')}  (${t('Eng')})*`,
        type: 'string',
        field: 'firstNameEng',
      },
      {
        title: `${t('Last Name')}  (${t('Chi')})`,
        type: 'string',
        field: 'lastNameChi',
      },
      {
        title: `${t('First Name')}  (${t('Chi')})`,
        type: 'string',
        field: 'firstNameChi',
      },
      {
        title: t('Date of Birth'),
        type: 'date',
        field: 'dateOfBirth',
      },
      {
        title: t('Gender'),
        type: 'string',
        field: 'gender',
        lookup: { M: 'Male', F: 'Female' },
      },

      {
        title: `${t('Grade')}`,
        type: 'string',
        field: 'grade',
        lookup: { 1: 1, 2: 2, 3: 3, 4: 4, 5: 5, 6: 6 },
      },
      {
        title: `${t('Class')}`, 
        type: 'string',
        field: 'class',
      },
      {
        title: t('Student Number'),
        type: 'numeric',
        field: 'number',
      },
      {
        title: t('Year of Entry'),
        type: 'string',
        field: 'yearOfEntry',
      },
      {
        title: `${t('School')} *`,
        type: 'string',
        field: i18n.language === "zh" ? "schoolNameChi" : "schoolNameEng",
        editable: 'never',
      },
      {
        title: t('Active'),
        field: 'isActive',
        type: 'boolean',
      },
      {
        title: t('Password'),
        field: 'password',
        type: 'string',
        render: (rowData) => {
          if (rowData.isNewUser && !rowData.password) {
            return t("Password required")
          } else {
            return rowData.password
          }
        },
      },
    ]);
  }, []);

  const getGenderCharacter = (gender: string) => {
    if (gender === 'F' || gender === 'M') {
      return gender;
    }
    switch (gender?.toString().toLowerCase()) {
      case 'female':
        return 'F';
      case 'male':
        return 'M';
      default:
        return null;
    }
  };

  const { data: usersData } = useQuery('Users', () => getUsers(), {
    refetchOnWindowFocus: false,
    onError: () => {
      setError("Something went wrong. Description: Cannot fetch users");
      setTimeout(() => {
        setError(undefined);
      }, 3000);
    },
  });

  const parseFile = () => {
    return new Promise<any[]>((resolve) => {
      const reader = new FileReader();
      if (props.file) {
        reader.readAsArrayBuffer(props.file);
        reader.onload = async (e: any) => {
          const bufferArray = e.target.result;
          const sheets = XLSX.read(bufferArray, {
            type: 'buffer',
            cellDates: true,
          });
          const studentWs = sheets.Sheets[sheets.SheetNames[0]];
          const students = XLSX.utils.sheet_to_json(studentWs);

          const formattedStudents: PreviewData[] = students.map((student: any, index) => {
            return {
              _id: student['User ID'] ?? student['用戶 ID'] ?? index,
              isNewUser: ((student['User ID'] ?? student['用戶 ID'] ?? "") === "" ? true : false),
              email: student.email ?? student['Email'] ?? student['電郵'] ?? '',
              lastNameEng: student.last_name_eng ?? student['Last Name (Eng)'] ?? student['姓 (英)'] ?? '',
              firstNameEng: student.first_name_eng ?? student['First Name (Eng)'] ?? student['名 (英)'] ?? '',
              lastNameChi: student.last_name_chi ?? student['Last Name (Chi)'] ?? student['姓 (中)'] ?? '',
              firstNameChi: student.first_name_chi ?? student['First Name (Chi)'] ?? student['名 (中)'] ?? '',
              dateOfBirth: student.date_of_birth ?? student['Date of Birth'] ?? student['出生日期'] ?? '',
              gender: student.gender ?? getGenderCharacter(student['Gender']) ?? student['性別'] ?? '',
              studentId: student.student_id ?? student['Student ID'] ?? student['學生 ID'] ?? '',
              grade: student.grade ?? student['Grade'] ?? student['Primary'] ?? student['年級'] ?? '',
              class: student.class ?? student['Class'] ?? student['班'] ?? '',
              number: student.number ?? student['Class Number'] ?? student['學號'] ?? '',
              yearOfEntry: student.year_of_entry ?? student['Year of Entry'] ?? student['入學年份'] ?? '',
              schoolId: student['School'] ?? student['學校'] ?? (schoolId === '' ? null : schoolId) ?? '',
              isActive: student.isActive ?? student['Active'] ?? student['活躍'] ?? true,
              password: ((student['User ID'] ?? student['用戶 ID'] ?? undefined) === undefined) ? student['Password'] ?? student['密碼'] : '',
            };
          });

          await getSchools()
            .then((res) => {
              formattedStudents.forEach((student: PreviewData) => {
                let index = res.findIndex((school) => school._id === student.schoolId);
                student.schoolNameChi = (res[index]?.nameChi && res[index]?.nameChi !== "" ? res[index]?.nameChi : null) ?? res[index]?.nameEng;
                student.schoolNameEng = res[index]?.nameEng;
                return student;
              })
            })
            .catch(() => setError("Cannot fetch schools"));
          resolve(formattedStudents);
        };
      } else {
        resolve([]);
      }
    });
  };

  useEffect(() => {
    async function parseFilePromise() {
      const formattedStudents = await parseFile();
      setStudents(formattedStudents);
      setIsLoading(false);
    }
    parseFilePromise();
  }, []);

  const handleSubmit = async () => {
    setIsLoading(true);
    
    const isAllStudentsRequiredFieldsFilled = students.every((student: any) => {
      return Object.entries(student).every(([key, value]) => {
        if (!requiredFields.includes(key)) {
          return true;
        } else {
          if (value === undefined || value === '') {
            return false;
          } else {
            return true;
          }
        }
      });
    });
    const emailErrors = students.filter((student) => !checkEmailFormat(student.email));

    if (!isAllStudentsRequiredFieldsFilled) {
      setError(t('Some required fields are not filled'));
      setTimeout(() => {
        setError(undefined);
      }, 3000);
    } else if (emailErrors.length > 0) {
      setError(t('Email format is not correct'));
      setTimeout(() => {
        setError(undefined);
      }, 3000);
    } else {
      const result = students.reduce(
        (res, user) => {
          res[usersData?.data.some((oldUser: IUser) => user._id === oldUser._id) ? 'oldUsers' : 'newUsers'].push(user);
          return res;
        },
        { oldUsers: [], newUsers: [] }
      );
      if (result.newUsers.every((newUser: any) => {
        return !newUser.password
      }) && result.newUsers.length > 0) {
        setError(t('Some required fields are not filled'));
        setTimeout(() => {
          setError(undefined);
        }, 3000);
      } else{
        if (result.oldUsers.length > 0) {
          await Promise.all(
            result.oldUsers.map(async (user: any) => {
              let editedStudent = usersData?.data.find((data: any) => data._id === user._id);

              Object.keys(editedStudent).forEach((key) => {
                if (key !== 'userProfile') {
                  editedStudent[key] = user[key];
                }
              });

              if (user.password !== ""){
                editedStudent.password = user.password
              }

              if (editedStudent.userProfile.teacher) {
                editedStudent.userProfile.teacher.schoolId = editedStudent.userProfile.teacher.school
                delete editedStudent.userProfile.teacher.school;
              }

              editedStudent.userProfile.student = {
                schoolId: user.schoolId,
                yearOfEntry: user.yearOfEntry,
                grade: user.grade,
                class: user.class,
                number: user.number,
                isActive: user.isActive,
                studentId: user.studentId,
              };

              return adminUpdateUser(editedStudent._id, editedStudent);
            })
          );
          if (result.newUsers.length === 0) {
            props.onSubmitSuccess('edit');
            props.onClose();
          }
        }
        if (result.newUsers.length > 0) {
          let payload = result.newUsers.map((i: any) => ({
            ...i,
            userProfile: {
              student : {
                school: i.schoolId,
                yearOfEntry: i.yearOfEntry,
                grade: i.grade,
                class: i.class,
                number: i.number,
                isActive: i.isActive,
                studentId: i.studentId,
              },
            },
          }));
          importMutation.mutate(payload);
        }
      }
    }
    setIsLoading(false);
  };

  return (
    <>
      <Modal open={true} onClose={props.onClose}>
        <NbModal.Content height="100%" maxHeight="100%">
          <NbModal.Header background="primary.500" textTransform="capitalize">
            {t('Preview')}
            <IconButton style={{ position: 'absolute', right: 10, top: 10 }} icon={<IoCloseOutline size={20} />} onPress={props.onClose} />
          </NbModal.Header>
          <NbModal.Body minHeight="md">
            <MaterialTable
              icons={tableIcons}
              onBulkEditOpen={(isOpen) => setIsBulkediting(isOpen)}
              style={{ width: '100%' }}
              title={t('Student to be Imported')}
              isLoading={importMutation.isLoading || isLoading}
              columns={columnObject}
              data={students}
              options={{
                pageSize: 10,
                pageSizeOptions: [10, 25, 50],
                filtering: true,
              }}
              editable={{
                onBulkUpdate: (change) => {
                  let updatedStudents = Object.values(change).map((row) => row.newData);
                  const studentsById = new Map(updatedStudents.map((student) => [student._id, student]));
                  const updated = students.map((student) => {
                    const updatedStudent = studentsById.get(student._id);
                    if (updatedStudent) {
                      return updatedStudent;
                    } else {
                      return student;
                    }
                  });
                  setStudents(updated);
                  return Promise.resolve();
                },
              }}
            />
            {error !== undefined && (
              <Alert
                severity="error"
                style={{
                  position: 'absolute',
                  zIndex: 10000,
                  top: '30%',
                  backgroundColor: '#f8b6b6',
                  width: '90%',
                  margin: '2.5%',
                }}
              >
                <AlertTitle>Error</AlertTitle>
                {error}
              </Alert>
            )}
          </NbModal.Body>
          <NbModal.Footer alignItems="center" justifyContent="center">
            {!isBulkEditing && (
              <Button w="full" variant="subtle" onPress={handleSubmit} isLoading={importMutation.isLoading || isLoading}>
                {t('Submit')}
              </Button>
            )}
          </NbModal.Footer>
        </NbModal.Content>
      </Modal>
    </>
  );
};

export default withRouter(connector(StudentCsvUploadPreviewModal));
