import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { Heading, Center, useToast, ScrollView } from 'native-base';
import { tableIcons } from '../MaterialTableIcons'
import MaterialTable, { Column } from 'material-table';
import { useQuery } from 'react-query';
import { getSchools, addSchool, updateSchool, deleteSchool } from '../api';
import { ISchool } from '../types/SchoolTypes';
import { useTranslation } from 'react-i18next';

const SchoolManagementPage: React.FC<RouteComponentProps> = (props) => {

  const toast = useToast();
  const { t, i18n } = useTranslation();

  const { data: schools, isLoading, refetch } = useQuery('schools', () => getSchools(), {
    refetchOnWindowFocus: false,
    onError: () => {
      toast.show({
        title: 'Something went wrong',
        status: 'error',
        description: 'Cannot fetch learning unit',
      });
    },
  });

  useEffect(() => {
    setColumnObject([
      {
        title: 'ID',
        type: 'string',
        field: '_id',
        hidden: true,
      },
      {
        title: `${t('Name')}  (${t('Eng')})`,
        type: 'string',
        field: 'nameEng',
        editPlaceholder: `${t('Name')}  (${t('Eng')})*`,
      },
      {
        title: `${t('Name')}  (${t('Chi')})`,
        type: 'string',
        field: 'nameChi'
      },
      {
        title: t('Address'),
        type: 'string',
        field: 'address',
      },
      {
        title: t('Phone Number'),
        type: 'string',
        field: 'phoneNumber',
      },
      {
        title: t('Created Date'),
        type: 'datetime',
        field: 'createdAt',
        editable: 'never',
      },
      {
        title: t('Updated Date'),
        type: 'datetime',
        field: 'updatedAt',
        editable: 'never',
      }
    ])

  }, [i18n.language])

  //Need to define the columns in state, so that when filter is applied, and the data is refetched, the filter is kept
  //https://github.com/mbrn/material-table/issues/491#issuecomment-541011677
  const [columnObject, setColumnObject] = useState<Column<ISchool>[]>([]);

  return (
    <ScrollView paddingTop={["35px", '0']}>
      <Heading>{t('School Management')}</Heading>
      <Center padding='10px'>
        <MaterialTable
          icons={tableIcons}
          style={{ width: '100%' }}
          title={t("Schools")}
          isLoading={isLoading}
          editable={{
            onRowAdd: async (data) => {
              try {
                await addSchool(data)
                refetch()
              } catch (error: any) {
                toast.show({
                  title: 'Something went wrong',
                  status: 'error',
                  description: error.response.data.message ?? error.toString(),
                });
              }
            },
            onRowUpdate: async (data) => {
              try {
                await updateSchool(data)
                refetch()
              } catch (error: any) {
                toast.show({
                  title: 'Something went wrong',
                  status: 'error',
                  description: error.response.data.message ?? error.toString(),
                });
              }
            },
            onRowDelete: async (data) => {
              await deleteSchool(data._id)
              refetch()
            },
          }}
          columns={columnObject}
          data={schools ?? []}
          options={{
            pageSize: 10,
            pageSizeOptions: [10, 25, 50],
            filtering: true,
            addRowPosition: 'first',
          }}
        />
      </Center>
    </ScrollView>
  )
}

export default SchoolManagementPage;