import { extendTheme } from 'native-base';
import { newColorTheme } from './colors';
import { componentsTheme } from './components';

export const extendedTheme = extendTheme({
  colors: newColorTheme,
  components: componentsTheme,
});

// 2. Extend the internal NativeBase Theme
type CustomThemeType = typeof extendedTheme;

declare module 'native-base' {
  // 3. Get the type of the CustomTheme
  interface ICustomTheme extends CustomThemeType { }

  interface IBoxProps {
    variant?: keyof CustomThemeType['components']['Box']['variants'];
  }

  interface IHStackProps {
    variant?: keyof CustomThemeType['components']['HStack']['variants'];
  }
}

// 4. May need to restart npm start again if you still see ts error
