export const newColorTheme = {
  primary: {
    50: '#FFFDEB',
    100: '#fff8dc',
    200: '#fef3c7',
    300: '#fde68a',
    400: '#fedc7f',
    500: '#fece4d',
    600: '#fec01f',
    700: '#e4a707',
    800: '#b28201',
    900: '#7f5d00',
  }
};
