import { FC, Key, useEffect, useState } from 'react';
import {
  Button,
  CheckIcon,
  FormControl,
  HStack,
  Input,
  Modal,
  Select,
  Spinner,
  Text,
  VStack,
  Alert,
  WarningOutlineIcon,
  Switch,
  useTheme,
  Box,
  Popover,
} from 'native-base';
import { useMutation, useQuery } from 'react-query';
import { addLearningMaterial, updateLearningMaterial } from '../../api/LearningMaterial';
import { uploadFile } from '../../api/Attachment'
import { getLearningUnits, getSubjects, getLearningObjectives, getSubjectsIsPublic, getLearningUnitsIsPublic, getLearningObjectivesIsPublic } from '../../api'
import Edit from '@material-ui/icons/Edit';
import RichTextEditor from '../../components/RichTextEditor';
import { createMarkup } from '../../utils/htmlHelper';
import { CreateLearningMaterialRequest, FileUploadResponse, ILearningMaterial } from '../../types/LearningMaterialType';
import { useTranslation } from 'react-i18next';
import { FileUploader } from "react-drag-drop-files";
import LearningMaterialContentSegment from '../../components/LearningMaterialContentSegment'
import { AiFillDelete, AiOutlineUpload, AiOutlinePlus, AiOutlineMinus } from 'react-icons/ai'

interface LearningMaterialManagementModalProps {
  isModalShown: boolean
  status: string;
  learningMaterial: ILearningMaterial | undefined;
  currentRole: string | null;
  onClose: () => void;
  onEditClick: () => void;
  onSubmitSuccess?: (status: string, learningMaterial?: ILearningMaterial) => void;
  onEditCsv?: (learningMaterial: CreateLearningMaterialRequest) => void;
}

const LearningMaterialManagementModal: FC<LearningMaterialManagementModalProps> = (props) => {
  /**
   * Hooks
   */
  const { t, i18n } = useTranslation();
  const { colors } = useTheme()

  /**
   * States
   */
  const [learningMaterial, setLearningMaterial] = useState<CreateLearningMaterialRequest | undefined>();
  const [selectedSubjectId, setSelectedSubjectId] = useState<string>('');
  const [selectedLearningUnitId, setSelectedLearningUnitId] = useState<string>('');
  const [emptyInputs, setEmptyInputs] = useState<string[]>([]);
  const [error, setError] = useState<string>('');
  const [titleError, setTitleError] = useState<string>('');
  const [isUploadingFile, setIsUploadingFile] = useState<boolean>(false);
  const [youtubeLinkInput, setYoutubeLinkInput] = useState<string>('');
  const [youtubeLinkError, setYoutubeLinkError] = useState<string>('');
  const [isAddYoutubePopoverVisible, setIsAddYoutubePopoverVisible] = useState<boolean>(false);


  /**
   * Queries
   */
  const createMutation = useMutation(addLearningMaterial, {
    onSuccess: (data) => {
      if (props.onSubmitSuccess) {
        props.onSubmitSuccess('create', data);
        props.onClose();
      }
    },
    onError: (err: any) => {
      setError(err.message);
    },
  });

  const updateMutation = useMutation(updateLearningMaterial, {
    onSuccess: (data) => {
      if (props.onSubmitSuccess) {
        props.onSubmitSuccess('edit', data);
        props.onClose();
      }
    },
    onError: (err: any) => {
      setError(err.message);
    },
  });

  const { data: subjects, isLoading: isSubjectLoading } = useQuery('subjects', () => (props.currentRole !== 'contentProvider' ? getSubjects() : getSubjectsIsPublic()), {
    refetchOnWindowFocus: false,
  });

  const {
    data: learningUnits,
    isLoading: isLearningUnitLoading,
  } = useQuery(['learningUnits', selectedSubjectId], () => (props.currentRole !== 'contentProvider' ? getLearningUnits(selectedSubjectId) : getLearningUnitsIsPublic(selectedSubjectId)), {
    enabled: selectedSubjectId !== '',
    refetchOnWindowFocus: false,
  });

  const {
    data: learningObjectives,
    isLoading: isLearningObjectiveLoading,
  } = useQuery(['learningObjectives', selectedLearningUnitId], () => (props.currentRole !== 'contentProvider' ? getLearningObjectives(selectedLearningUnitId) : getLearningObjectivesIsPublic(selectedLearningUnitId)), {
    enabled: selectedLearningUnitId !== '',
    refetchOnWindowFocus: false,
  });

  /**
   * Effects
   */
  useEffect(() => {
    if (props.isModalShown === false) {
      setSelectedLearningUnitId('')
      setSelectedSubjectId('')
      setLearningMaterial(undefined)
      setError('')
      setTitleError('')
      setEmptyInputs([])
    } else {
      if (props.learningMaterial && props.status !== 'edit csv') {
        //edit
        setSelectedLearningUnitId(props.learningMaterial.learningObjective.learningUnit._id)
        setSelectedSubjectId(props.learningMaterial.learningObjective.learningUnit.subject._id)
        console.log(props.learningMaterial.learningObjective._id)
        setLearningMaterial({
          _id: props.learningMaterial._id,
          learningObjectiveId: props.learningMaterial.learningObjective._id,
          name: props.learningMaterial.name,
          content: convertPureTextToHtml(props.learningMaterial.content),
          isActive: props.learningMaterial.isActive,
          attachments: props.learningMaterial.attachments,
          youtubeLinks: props.learningMaterial.youtubeLinks,
          isPublic: (props.currentRole === 'contentProvider'),
        });
      } else {
        //add new
        setLearningMaterial({
          name: '',
          content: '',
          learningObjectiveId: '',
          isActive: props.learningMaterial?.isActive ?? true,
          attachments: [],
          youtubeLinks: [],
          isPublic: (props.currentRole === 'contentProvider'),
        });
      }
    }
  }, [props.isModalShown, props.status, props.learningMaterial])

  /**
   * Input handlers
   */

  const onSubjectSelectChange = (value: string) => {
    setSelectedSubjectId(value)
    setSelectedLearningUnitId('')
    if (learningMaterial) {
      setLearningMaterial({
        ...learningMaterial,
        learningObjectiveId: '',
      })
    }
  }

  const onLearningUnitSelectChange = (value: string) => {
    setSelectedLearningUnitId(value)
    if (learningMaterial) {
      setLearningMaterial({
        ...learningMaterial,
        learningObjectiveId: '',
      })
    }
  }

  const handleInputOnChange = (name: string, value: string) => {
    if (learningMaterial) {
      setLearningMaterial({ ...learningMaterial, [name]: value });
    }
  };

  const handleDeleteAttachment = (attachment: FileUploadResponse) => {
    if (learningMaterial && learningMaterial.attachments) {
      const newAttachments = learningMaterial.attachments.filter(item => item.filename !== attachment.filename);
      setLearningMaterial({ ...learningMaterial, attachments: newAttachments });
    }
  }

  const handleDeleteYoutubeLink = (link: string) => {
    if (learningMaterial && learningMaterial.youtubeLinks) {
      const newYoutubeLinks = learningMaterial.youtubeLinks.filter(item => item !== link);
      setLearningMaterial({ ...learningMaterial, youtubeLinks: newYoutubeLinks });
    }
  }

  const handleFileUploadChange = async (file: any) => {
    console.log(file)
    setIsUploadingFile(true);


    try {

      const result = await uploadFile(file, file.name);
      console.log(result)
      if (learningMaterial) {
        setLearningMaterial({
          ...learningMaterial,
          attachments: [...learningMaterial.attachments ?? [], result],
        })
      }
    } catch (e) {
      console.log(e)
    }
    setIsUploadingFile(false);
  }

  const onAttachmentOrderChange = (index: number, newPosition: number) => {
    if (learningMaterial && learningMaterial.attachments) {
      const newAttachments = learningMaterial.attachments;
      const newAttachment = newAttachments[index];
      newAttachments.splice(index, 1);
      newAttachments.splice(newPosition, 0, newAttachment);
      setLearningMaterial({ ...learningMaterial, attachments: newAttachments });
    }
  }

  const onYoutubeLinkOrderChange = (index: number, newPosition: number) => {
    if (learningMaterial && learningMaterial.youtubeLinks) {
      const newYoutubeLinks = learningMaterial.youtubeLinks;
      const newYoutubeLink = newYoutubeLinks[index];
      newYoutubeLinks.splice(index, 1);
      newYoutubeLinks.splice(newPosition, 0, newYoutubeLink);
      setLearningMaterial({ ...learningMaterial, youtubeLinks: newYoutubeLinks });
    }
  }

  const handleSubmit = () => {
    // check if inputs are empty first
    let emptyInputsTmp: string[] = [];

    Object.entries(learningMaterial!).forEach(([key, value]) => {
      if (!value && key !== 'isActive' && key !== 'content' && key !== 'isPublic') {
        emptyInputsTmp.push(key);
      }
    });

    setEmptyInputs(emptyInputsTmp);

    if (emptyInputsTmp.length === 0) {
      setTitleError('');
      if (props.status === 'edit csv' && learningMaterial && props.onEditCsv) {
        props.onEditCsv(learningMaterial);
        props.onClose();
      } else {
        let mutation;
        if (props.status === 'create') {
          mutation = createMutation;
        } else if (props.status === 'edit') {
          mutation = updateMutation;
        }
        if (learningMaterial && mutation) {
          mutation.mutate(learningMaterial);
        }
      }
    }
  };

  const onSaveYoutubeLinkClick = () => {
    if (!youtubeLinkInput.includes('https://www.youtube.com/') && !youtubeLinkInput.includes('https://youtu.be/')) {
      setYoutubeLinkError(t('Please enter a valid youtube link'));
    } else {
      setYoutubeLinkError('');
      setIsAddYoutubePopoverVisible(false)
      if (learningMaterial) {
        setLearningMaterial({
          ...learningMaterial,
          youtubeLinks: [...learningMaterial.youtubeLinks ?? [], youtubeLinkInput],
        });
        setYoutubeLinkInput('')
      }
    }
  }

  /**
   * Helper functions
   */
  const isQuillEmpty = (value: string) => {
    return value.replace(/<(.|\n)*?>/g, '').trim().length === 0 && !value.includes('<img');
  };

  const convertPureTextToHtml = (value: string) => {
    if (!/<\/?[a-z][\s\S]*>/i.test(value)) {
      return `<p>${value}</p>`;
    } else {
      return value;
    }
  };

  return (
    <Modal isOpen={props.isModalShown} onClose={props.onClose} size="full" closeOnOverlayClick={false} _backdrop={{ height: '100%' }}>
      <Modal.Content height="100%" maxHeight="100%">
        <Modal.CloseButton />
        <Modal.Header background="primary.500" textTransform="capitalize">
          <HStack>
            {props.status === 'view' && t('View Learning Material')}
            {props.status === 'create' && t('Create New Learning Material')}
            {props.status === 'edit' && t('Edit Learning Material')}
            {props.status === 'edit csv' && t('Edit Imported Learning Material')}
            {props.status === 'view' && (
              <Button ml="2" variant="unstyled" p="0" onPress={props.onEditClick}>
                <Edit style={{ width: 20, height: 20 }} />
              </Button>
            )}
          </HStack>
        </Modal.Header>
        <Modal.Body minHeight="md" p="3">
          <>
            {learningMaterial?._id && (
              <Text bold>
                {t('Learning Material')} ID: <Text>{learningMaterial?._id}</Text>
              </Text>
            )}
            {/* subject */}
            <FormControl
              isRequired={props.status !== 'view' || isSubjectLoading}
              w={['100%', '100%', '33%']}
              isInvalid={emptyInputs.includes('subjectId')}
              my="2"
            >
              <HStack alignItems="center">
                <FormControl.Label>{t('Subject')}</FormControl.Label>
                {isSubjectLoading && <Spinner accessibilityLabel="Loading subject" />}
              </HStack>
              <Select
                isDisabled={props.status === 'view'}
                w="100%"
                _selectedItem={{
                  bg: 'teal.600',
                  endIcon: <CheckIcon size="5" />,
                }}
                selectedValue={selectedSubjectId}
                onValueChange={(value: string) => onSubjectSelectChange(value)}
                placeholder={t('Choose Subject')}
              >
                {subjects ? (
                  subjects.map((subject) => (
                    <Select.Item key={subject._id} label={i18n.language === 'zh' ? subject.nameChi : subject.nameEng} value={subject._id} />
                  ))
                ) : (
                  <></>
                )}
                <Select.Item key="empty" label="" value="" />
              </Select>
            </FormControl>


            {/* learning unit */}
            <FormControl
              isRequired={props.status !== 'view'}
              w='100%'
              isInvalid={emptyInputs.includes('learningUnitId')}
              my="2"
            >
              <HStack alignItems="center">
                <FormControl.Label>{t('Learning Unit')}</FormControl.Label>
                {isLearningUnitLoading && <Spinner accessibilityLabel="Loading learning unit" />}
              </HStack>
              <Select
                isDisabled={props.status === 'view' || isLearningUnitLoading}
                w="100%"
                _selectedItem={{
                  bg: 'teal.600',
                  endIcon: <CheckIcon size="5" />,
                }}
                selectedValue={selectedLearningUnitId}
                onValueChange={(value: string) => onLearningUnitSelectChange(value)}
                placeholder={t('Choose Learning Unit')}
              >
                {learningUnits ? (
                  learningUnits.map((learningUnit) => (
                    <Select.Item
                      key={learningUnit._id}
                      label={`${learningUnit.code} ${i18n.language === 'zh' && learningUnit.nameChi !== null ? learningUnit.nameChi : learningUnit.nameEng
                        }`}
                      value={learningUnit._id}
                    />
                  ))
                ) : (
                  <></>
                )}
                <Select.Item key="empty" label="" value="" />
              </Select>
            </FormControl>

            {/* learning objective code */}
            <FormControl
              isRequired={props.status !== 'view'}
              w='100%'
              isInvalid={emptyInputs.includes('learningObjectiveId')}
              my="2"
            >
              <HStack alignItems="center">
                <FormControl.Label>{t('Learning Objective')}</FormControl.Label>
                {isLearningObjectiveLoading && <Spinner accessibilityLabel="Loading learning objective code" />}
              </HStack>
              <Select
                isDisabled={props.status === 'view' || isLearningObjectiveLoading}
                w="100%"
                _selectedItem={{
                  bg: 'teal.600',
                  endIcon: <CheckIcon size="5" />,
                }}
                selectedValue={learningMaterial?.learningObjectiveId}
                onValueChange={(value: string) => handleInputOnChange('learningObjectiveId', value)}
                placeholder={t('Choose Learning Objective')}
              >
                {learningObjectives ? (
                  learningObjectives.map((learningObjective) => (
                    <Select.Item
                      key={learningObjective._id}
                      label={`${learningObjective.code} ${i18n.language === 'zh' && learningObjective.nameChi !== null ? learningObjective.nameChi : learningObjective.nameEng
                        }`}
                      value={learningObjective._id}
                    />
                  ))
                ) : (
                  <></>
                )}
                <Select.Item key="empty" label="" value="" />
              </Select>
            </FormControl>

            <FormControl
              isRequired={props.status !== 'view'}
              w='100%'
              isInvalid={emptyInputs.includes('name')}
              my="2"
            >
              <FormControl.Label>{t('Name')}</FormControl.Label>
              <Input
                isDisabled={props.status === 'view'}
                size="md"
                onChangeText={(value: string) => {
                  handleInputOnChange('name', value);
                }}
                value={learningMaterial?.name}
              />
            </FormControl>

            <Text fontWeight={'500'}>{t('Visible to student')}</Text>
            <Switch
              size="sm"
              isDisabled={props.status === 'view'}
              isChecked={learningMaterial?.isActive}
              onToggle={() => setLearningMaterial({ ...learningMaterial!, isActive: !learningMaterial?.isActive })} />
            {/* learningMaterial title */}

            {props.status === 'view' && learningMaterial !== undefined ?

              <LearningMaterialContentSegment learningMaterial={learningMaterial} />
              :
              <>
                <FormControl
                  w="100%"
                  isInvalid={emptyInputs.includes('learningMaterialText') || Boolean(titleError)}
                  my="2"
                >
                  <FormControl.Label
                    mb={
                      props.status === 'view' ||
                        (learningMaterial)
                        ? 0
                        : 2
                    }
                  >
                    {t('Learning Material Content')}
                  </FormControl.Label>
                  {props.status === 'view' ? (
                    <div style={{ fontSize: 15 }} dangerouslySetInnerHTML={createMarkup(learningMaterial?.content ?? '')} />
                  ) : (
                    <RichTextEditor value={learningMaterial?.content ?? ''} onChange={(value: string) => handleInputOnChange('content', value)} />
                  )}
                  <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
                    {titleError ? titleError : t('Learning Material Content cannot be empty')}
                  </FormControl.ErrorMessage>
                </FormControl>



                <HStack alignItems={'center'}>
                  <FormControl.Label
                    mb={
                      props.status === 'view' ||
                        (learningMaterial)
                        ? 0
                        : 2
                    }
                  >
                    {t('YouTube Link')}
                  </FormControl.Label>
                  <Button onPress={() => setIsAddYoutubePopoverVisible(true)} >{t('Add')}</Button>
                </HStack>
                <VStack space={2} marginBottom={4}>

                  {learningMaterial?.youtubeLinks && learningMaterial?.youtubeLinks.map((youtubeLink, i) => (
                    <HStack key={i} alignItems={'center'} space={2}>
                      <Button variant={'subtle'} onPress={() => onYoutubeLinkOrderChange(i, i - 1)}><AiOutlineMinus /></Button>
                      <Text>
                        {(i + 1).toString()}
                      </Text>
                      <Button variant={'subtle'} onPress={() => onYoutubeLinkOrderChange(i, i + 1)}><AiOutlinePlus /></Button>
                      <Text>{youtubeLink}</Text>
                      <Button
                        variant="subtle"
                        colorScheme={'danger'}
                        endIcon={<AiFillDelete color={colors.danger[700]} />}
                        onPress={() => handleDeleteYoutubeLink(youtubeLink)}
                      >
                        {t('Delete')}
                      </Button>
                    </HStack>
                  ))}
                </VStack>

                <Modal isOpen={isAddYoutubePopoverVisible} onClose={() => setIsAddYoutubePopoverVisible(false)}>
                  <Modal.Content maxWidth="400px">
                    <Modal.CloseButton />
                    <Modal.Header>{t('Add YouTube Link')}</Modal.Header>
                    <Modal.Body>
                      <FormControl isInvalid={youtubeLinkError !== ''}>
                        <Input
                          rounded="sm"
                          fontSize="xs"
                          placeholder={t('Paste the YouTube link here')}
                          value={youtubeLinkInput}
                          onChangeText={(value: string) => setYoutubeLinkInput(value)}
                        />
                        <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
                          {youtubeLinkError}
                        </FormControl.ErrorMessage>
                      </FormControl>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button.Group>
                        <Button onPress={onSaveYoutubeLinkClick}>Save</Button>
                      </Button.Group>
                    </Modal.Footer>
                  </Modal.Content>
                </Modal>

                <FormControl.Label
                  mb={
                    props.status === 'view' ||
                      (learningMaterial)
                      ? 0
                      : 2
                  }
                >
                  {t('Attachment')}
                </FormControl.Label>

                <Box marginY={4}>
                  <FileUploader
                    handleChange={handleFileUploadChange}
                    name="file">
                    <Box borderStyle={'dashed'} borderColor={colors.primary[800]} borderWidth={'2px'} padding={4}>
                      {isUploadingFile ?
                        <HStack>
                          <Spinner accessibilityLabel="Uploading file" />
                          {t('Uploading')}
                        </HStack>
                        :
                        <HStack space={2} alignItems={'center'} w={'100%'}>
                          <Text color={colors.primary[800]}>{t('Click to select a file or Drag and drop it here')}</Text>
                          <AiOutlineUpload color={colors.primary[800]} />
                        </HStack>
                      }
                    </Box>
                  </FileUploader>
                </Box>

                <VStack>
                  {learningMaterial?.attachments?.map((attachment, i) => (
                    <HStack key={i} marginY={1} space={4} bgColor={colors.primary[50]} alignItems={'center'}>
                      <Button variant={'outline'} onPress={() => onAttachmentOrderChange(i, i - 1)}><AiOutlineMinus /></Button>
                      <Text>
                        {(i + 1).toString()}
                      </Text>
                      <Button variant={'outline'} onPress={() => onAttachmentOrderChange(i, i + 1)}><AiOutlinePlus /></Button>
                      <Button
                        variant="subtle"
                        colorScheme={'danger'}
                        endIcon={<AiFillDelete color={colors.danger[700]} />}
                        onPress={() => handleDeleteAttachment(attachment)}
                      >
                        {t('Delete')}
                      </Button>
                      <Text
                        w='50%'>
                        {attachment.originalname}
                      </Text>
                    </HStack>
                  ))}
                </VStack>
              </>
            }


            {/* error msg */}
            {error && (
              <Alert w="100%" status="error">
                <VStack space={2} flexShrink={1} w="100%">
                  <HStack flexShrink={1} space={2} justifyContent="space-between">
                    <HStack w="full" space={2} flexShrink={1} alignItems="center" justifyContent="space-between">
                      <HStack space={2} flexShrink={1} alignItems="center">
                        <Alert.Icon />
                        <Text fontSize="sm" color="coolGray.800">
                          {error}
                        </Text>
                      </HStack>
                    </HStack>
                  </HStack>
                </VStack>
              </Alert>
            )}
          </>
        </Modal.Body>
        {props.status !== 'view' && (
          <Modal.Footer alignItems="center" justifyContent="center">
            <Button w="full" onPress={handleSubmit} isLoading={createMutation.isLoading || updateMutation.isLoading}>
              {t('Submit')}
            </Button>
          </Modal.Footer>
        )}
      </Modal.Content>
    </Modal>
  );
};

export default LearningMaterialManagementModal;
