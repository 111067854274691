import { FC } from 'react';
import { Text, Button } from 'native-base';

interface QuestionNumberButtonProps {
  questionNumber: number;
  isCurrent: boolean;
  isAnswered: boolean;
  onClick: () => void;
  disabled: boolean;
}

const QuestionNumberButton: FC<QuestionNumberButtonProps> = (props) => {
  return (
    <Button
      disabled={props.disabled}
      borderRadius="50%"
      width={50}
      height={50}
      mx={1}
      my={2}
      borderWidth={4}
      borderColor={
        props.isAnswered || props.isCurrent ? 'primary.500' : 'light.300'
      }
      textAlign="center"
      justifyContent="center"
      backgroundColor={props.isCurrent ? 'primary.500' : 'white'}
      onPress={!props.isAnswered ? undefined : props.onClick}
      pointerEvents={!props.isAnswered ? 'none' : 'auto'}
    >
      <Text fontSize="lg" bold color={props.isCurrent ? 'white' : 'light.300'}>
        {props.questionNumber}
      </Text>
    </Button>
  );
};

export default QuestionNumberButton;
