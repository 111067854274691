import React, { FC } from 'react';
import { Button } from 'native-base';
import { useTranslation } from 'react-i18next';

const ChangeLanguageButton: FC = () => {
  const { t, i18n } = useTranslation('translation');
  return (
    <>
      <Button
        size="sm"
        variant="subtle"
        mr={2}
        colorScheme={i18n.language === 'zh' ? 'primary' : 'gray'}
        onPress={() => i18n.changeLanguage('zh')}
      >
        繁
      </Button>
      <Button
        size="sm"
        variant="subtle"
        mr={2}
        colorScheme={i18n.language.includes('en') ? 'primary' : 'gray'}
        onPress={() => i18n.changeLanguage('en')}
      >
        ENG
      </Button>
    </>
  );
};

export default ChangeLanguageButton;
