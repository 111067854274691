import { Center, useToast, Text, Heading, Spinner, Pressable, Box } from 'native-base';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { RouteComponentProps, withRouter } from 'react-router';
import { getSubjectsByStudentRole } from '../api';
import brown_map from '../images/brown_map.png'
import choose_subject_background from '../images/choose_subject_background.png'

const ChooseSubjectPage: React.FC<RouteComponentProps> = (props) => {
  const toast = useToast();
  const { t, i18n } = useTranslation()

  const { data: subjects, isLoading } = useQuery('subjectsByStudentRole', getSubjectsByStudentRole, {
    refetchOnWindowFocus: false,
    onError: () => {
      toast.show({
        title: 'Something went wrong',
        status: 'error',
        description: 'Cannot fetch subjects',
      });
    },
  });

  return (
    <>
      {isLoading ? (
        <Center flex={1} px="3" w="full" h="full">
          <Spinner size="lg" />
        </Center>
      ) : (
        <div style={{
          backgroundImage: `url(${choose_subject_background})`,
          height: '100%',
          width: '100%',
          overflow: 'scroll',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat'
        }}>
          <Box h='full' paddingTop={'50px'}>
            <Heading size="md" textAlign="center" w='full'>
              {t('Choose your subject to start')}
            </Heading>
            <Center px="3" w="full" marginTop={10}>
              {subjects?.length === 0 ? (
                <Text>{t('No active subjects')}</Text>
              ) : (
                <Center w={['100%', '700']}>
                  {subjects
                    ?.filter((subject) => subject.isActive)
                    .map((subject) => (
                      <Pressable
                        key={subject._id}
                        onPress={() =>
                          props.history.push(
                            `/subjects/${subject._id}/learning-unit`
                          )
                        }
                      >
                        <div style={{
                          backgroundImage: `url(${brown_map})`,
                          backgroundRepeat: 'no-repeat',
                          backgroundSize: 'contain',
                          width: 350,
                          height: 200,
                          maxWidth: '100%',
                        }}
                        >
                          <Center w='full' h='full'>
                            <Text
                              fontWeight={'700'}
                              fontSize={'20px'}
                              color='primary.900'
                            >
                              {i18n.language === 'zh' ? subject.nameChi : subject.nameEng}
                            </Text>
                          </Center>
                        </div>
                      </Pressable>
                    ))}
                </Center>
              )}
            </Center>
          </Box>
        </div>
      )}
    </>
  );
};

export default withRouter(ChooseSubjectPage);
