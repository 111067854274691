import { Box, Center } from 'native-base';
import { useTranslation } from 'react-i18next';
import MaterialTable from '@material-table/core';
import { tableIcons } from '../MaterialTableIcons';
import { useEffect, useState } from 'react';
import { IQuiz } from '../types/QuizTypes';
import { ILearningUnitArea } from '../types/LearningUnitAreaType';
import { ExportCsv } from '@material-table/exporters';
import dayjs from 'dayjs';

interface TeacherSummaryLearningUnitAreaTableProps {
  data: IQuiz[] | undefined;
}

interface ILearningUnitAreaSummaryTable {
  learningUnitArea: ILearningUnitArea;
  total: number;
  numOfAttempt: number;
  avg: number;
  displayedTotal: string;
  displayedAvg: string;
}
  
const TeacherSummaryLearningUnitAreaTable: React.FC<TeacherSummaryLearningUnitAreaTableProps> = (props) => {
  const { t, i18n } = useTranslation();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [learningUnitAreaData, setLearningUnitAreaData] = useState<ILearningUnitAreaSummaryTable[]>([]);

  useEffect(() => {
    setIsLoading(true);
    let result: ILearningUnitAreaSummaryTable[] = [];
    props.data?.forEach((quiz) => {
      if(!result.some((area) => area.learningUnitArea._id === quiz.learningUnitArea._id)) {
        result = [...result, {
          learningUnitArea: quiz.learningUnitArea, 
          total: quiz.score*100,
          numOfAttempt: 1, 
          avg: 0,
          displayedTotal: "",
          displayedAvg: ""
        }]
      } else {
        let target = result.find((data) => data.learningUnitArea._id === quiz.learningUnitArea._id);
        if (target !== undefined) {
          target.total += quiz.score*100;
          target.numOfAttempt++;
        }
      }
    })

    // Cal the average
    result.forEach((data) => {
      data.avg = Math.round((data.total / data.numOfAttempt) * 10) / 10;
      // Use string for a better dispay in the table
      data.displayedTotal = data.total.toFixed(1);
      data.displayedAvg = data.avg.toFixed(1);
      return data;
    })

    // Sort by name
    result.sort((a, b) => {
      if (a.learningUnitArea.nameEng < b.learningUnitArea.nameEng) {
        return -1;
      } else if (a.learningUnitArea.nameEng > b.learningUnitArea.nameEng){
        return 1;
      }
      return 0
    });

    setLearningUnitAreaData(result);
    setIsLoading(false);
  }, [props]);

  return (
    <Box>
      <Center padding="10px">
        <MaterialTable
          style={{ width: '100%' }}
          title={t('Learning Unit Area')}
          icons={tableIcons}
          isLoading={isLoading}
          columns={[
            {
              title: `${t('Name')}  (${t('Eng')})`,
              type: 'string',
              field: 'learningUnitArea.nameEng',
            },
            {
              title: `${t('Name')}  (${t('Chi')})`,
              type: 'string',
              field: 'learningUnitArea.nameChi'
            },
            {
              title: t('Total Score'),
              type: 'string',
              field: 'displayedTotal',
            },
            {
              title: t('Quantity'),
              type: 'string',
              field: 'numOfAttempt',
            },
            {
              title: t('Average Score'),
              type: 'string',
              field: 'displayedAvg',
            }
          ]}
          data={learningUnitAreaData ?? []}
          options={{
            pageSize: 10,
            pageSizeOptions: [10, 25, 50],
            filtering: true,
            exportAllData: true,
            exportMenu: [
              {
                label: t('Export Summary'),
                exportFunc: (cols, data) => {
                  return ExportCsv(cols, data, `Learning Unit Area Summary Export ${dayjs().format('YYYYMMDDHHmmss')}`);
                },
              },
            ],
          }}
        />
      </Center>
    </Box>
  )
}

export default TeacherSummaryLearningUnitAreaTable;
