import React, { useState } from 'react';
import { Flex, Box, HStack, Button } from 'native-base';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import TeacherSummaryStudentListTable from './TeacherSummaryStudentListTable';
import TeacherSummaryClassTable from './TeacherSummaryClassTable';
import TeacherSummaryLearningUnitAreaTable from './TeacherSummaryLearningUnitAreaTable';
import TeacherSummaryLearningUnitTable from './TeacherSummaryLearningUnitTable';
import TeacherSummaryLearningObjectiveTable from './TeacherSummaryLearningObjectiveTable';
import TeacherSummaryHighestLearningUnitAreaTable from './TeacherSummaryHighestLearningUnitAreaTable';
import { IQuiz } from '../types/QuizTypes';
import { useQuery } from 'react-query';
import { getLearningUnitAreas } from '../api';

interface TeacherSummaryTabProps {
  allData: IQuiz[] | undefined;
  handleBackToReport: () => void
}

const selectStyles = {
  container: (base: any, state: any) => ({
    ...base,
    margin: '0 0.5rem',
    width: '100%',
    zIndex: '999',
  }),
};

const TeacherSummaryTab: React.FC<TeacherSummaryTabProps> = (props) => {

  const { t, i18n } = useTranslation('translation');

  const [table, setTable] = useState<string | undefined>('student');
  const [summaryData, setSummaryData] = useState<IQuiz[]>([]);

  const {
    data: learningUnitAreas,
  } = useQuery('learningUnitArea', () => getLearningUnitAreas(), 
  {
    onSuccess: (data) => {
      let temp = props.allData;
      if (temp !== undefined) {
        setSummaryData(temp.filter((quiz) => {
          return data.some((area) => area._id === quiz.learningUnitArea._id)
        }));
      }
    }
  });

  const StudentRoute = <TeacherSummaryStudentListTable data={summaryData}/>;

  const ClassRoute = <TeacherSummaryClassTable data={summaryData}/>;

  const LearningUnitAreaRoute = <TeacherSummaryLearningUnitAreaTable data={summaryData}/>

  const LearningUnit = <TeacherSummaryLearningUnitTable data={summaryData} />
  
  const LearningObjective = <TeacherSummaryLearningObjectiveTable data={summaryData} />

  const HighestLearningUnitArea = <TeacherSummaryHighestLearningUnitAreaTable data={summaryData} />

  const renderTable= {
    'student': StudentRoute,
    'class': ClassRoute,
    'learningUnitArea': LearningUnitAreaRoute,
    'learningUnit': LearningUnit,
    'learningObjective': LearningObjective,
    'highestLearningUnitArea': HighestLearningUnitArea
  };

  const renderMap = new Map<string | undefined, JSX.Element>(Object.entries(renderTable))

  return (
    <Flex flexDirection={['column', 'row', 'row', 'row']} flex={1} margin={2} flexWrap={'wrap'} >
      <HStack space={2} marginLeft={2}>
        <Select
          placeholder={t('Choose Summary')}
          styles={selectStyles}
          menuPortalTarget={document.body}
          onChange={(newValue) => setTable(newValue?.value)}
          options={[
            { value: 'student', label: t('Student') },
            { value: 'class', label: t('Class') },
            { value: 'learningUnitArea', label: t('Learning Unit Area') },
            { value: 'learningUnit', label: t('Learning Unit') },
            { value: 'learningObjective', label: t('Learning Objective') },
            { value: 'highestLearningUnitArea', label: t('Highest number of levels') }
          ]}
        />
        <Button variant="outline" onPress={props.handleBackToReport}>
          {t("Back to Reports")}
        </Button>
      </HStack>
      <Box style={{ width: '100%' }}>
        {renderMap.get(table)}
      </Box>
    </Flex>
  )
}

export default TeacherSummaryTab;