import React, { useEffect, useState, useRef } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Heading, Center, useToast, useTheme, Button, Text, Alert, CloseIcon, HStack, IconButton, VStack, Box } from 'native-base';
import { tableIcons } from '../MaterialTableIcons';
import MaterialTable, { Column, MTableToolbar } from '@material-table/core';
import { ExportCsv } from '@material-table/exporters';
import { useQuery } from 'react-query';
import { addStudent, getStudentsBySchool, updateUser, deleteStudent } from '../api';
import Assessment from '@material-ui/icons/Assessment';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../redux/reducers';
import { ICreateStudent, IUser } from '../types/AuthTypes';
import { makeStyles } from '@material-ui/core';
import Select from 'react-select';
import { checkEmailFormat } from '../utils/authHelper';
import { FaFileUpload, FaFileDownload } from 'react-icons/fa';
import FileSaver from 'file-saver';
import StudentCsvUploadPreviewModal from '../components/StudentCsvUploadPreviewModal';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';


const mapStateToProps = (state: RootState) => ({
  user: state.auth.user,
  currentRole: state.auth.currentRole,
});

const connector = connect(mapStateToProps);
type PropsType = ConnectedProps<typeof connector> & RouteComponentProps;

const StudentManagementPage: React.FC<PropsType> = (props) => {
  const toast = useToast();
  const { colors } = useTheme();
  const { t, i18n } = useTranslation();
  const tableRef = useRef(MaterialTable);

  const ref = useRef<HTMLInputElement>(null);

  // TODO: handle admin case for school id
  const schoolId = props.currentRole === 'teacher' && props.user?.userProfile.teacher ? props.user?.userProfile.teacher?.school?._id : '';

  const [columnObject, setColumnObject] = useState<Column<IUser>[]>([]);
  const [error, setError] = useState<string>('');
  const [file, setFile] = useState<File>();
  const [isPreviewModalShown, setIsPreviewModalShown] = useState<boolean>(false);

  const requiredFields = ['email', 'lastNameEng', 'firstNameEng', 'class', 'grade'];

  const {
    data: students,
    isLoading,
    refetch,
  } = useQuery(['studentsBySchool', schoolId], () => getStudentsBySchool(schoolId), {
    refetchOnWindowFocus: false,
    onError: () => {
      toast.show({
        title: 'Something went wrong',
        status: 'error',
        description: 'Cannot fetch students',
      });
    },
  });

  useEffect(() => {
    setColumnObject([
      {
        title: 'User ID',
        type: 'string',
        field: '_id',
        hidden: true,
        editable: 'never',
        export: true,
      },
      {
        title: t('Student') + ' ID',
        type: 'string',
        field: 'userProfile.student.studentId',
      },
      {
        title: t('Email'),
        type: 'string',
        field: 'email',
        editPlaceholder: `${t('Email')} *`,
        editable: 'onAdd',
        validate: (rowData) => rowData.email && rowData.email !== '' && checkEmailFormat(rowData.email),
      },
      {
        title: `${t('Last Name')} (${t('Eng')})`,
        type: 'string',
        editPlaceholder: `${t('Last Name')}  (${t('Eng')}) *`,
        field: 'lastNameEng',
      },
      {
        title: `${t('First Name')} (${t('Eng')})`,
        type: 'string',
        editPlaceholder: `${t('First Name')}  (${t('Eng')}) *`,
        field: 'firstNameEng',
      },
      {
        title: `${t('Last Name')} (${t('Chi')})`,
        type: 'string',
        field: 'lastNameChi',
      },
      {
        title: `${t('First Name')} (${t('Chi')})`,
        type: 'string',
        field: 'firstNameChi',
      },
      {
        title: t('Date of Birth'),
        type: 'date',
        field: 'dateOfBirth',
      },
      {
        title: t('Gender'),
        type: 'string',
        field: 'gender',
        lookup: { M: 'Male', F: 'Female' },
      },
      {
        title: t('Grade'),
        type: 'string',
        field: 'userProfile.student.grade',
        lookup: [1, 2, 3, 4, 5, 6]?.reduce<{ [key: string]: string }>((map, i) => {
          return { ...map, [i]: i };
        }, {}),
      },
      {
        title: t('Class'),
        type: 'string',
        field: 'userProfile.student.class',
      },
      {
        title: t('Student Number'),
        type: 'numeric',
        field: 'userProfile.student.number',
      },
      {
        title: t('Year of Entry'),
        type: 'numeric',
        field: 'userProfile.student.yearOfEntry',
      },
      {
        title: t('School'),
        type: 'string',
        field: i18n.language === 'zh' ? 'userProfile.student.school.nameChi' : 'userProfile.student.school.nameEng',
        editable: 'never',
        export: false,
      },
      {
        title: t('Password'),
        field: 'password',
        editPlaceholder: `${t('Password')} *`,
        render: (rowData: IUser) => <Text>Confidential</Text>,
        editable: 'onAdd',
        export: false,
      },
      {
        title: t('Active'),
        field: 'userProfile.student.isActive',
        type: 'boolean',
      },
      {
        title: t('Created Date'),
        field: 'createdAt',
        type: 'datetime',
        export: false,
      },
      {
        title: '',
        render: (rowData: IUser) => (
          <Button
            onPress={() => props.history.push(`/studentreports/${rowData._id}`)}
            variant={'ghost'}
            leftIcon={<Assessment style={{ color: colors.primary['500'] }} />}
          >
            {t('Reports')}
          </Button>
        ),
      },
    ]);
  }, [i18n.language]);

  const useStyles = makeStyles({
    stickyActionsColumn: {
      width: '100%',
      '& table:first-child': {
        '& tr': {
          '& td:first-child, th:first-child': {
            backgroundColor: '#f5f5f5',
            position: 'sticky',
            left: 0,
            zIndex: 1,
          },
          '& th:first-child': {
            zIndex: 999,
          },
        },
      },
    },
  });

  const classes = useStyles();

  const handleCsvOnImport = async (event: any) => {
    const file = event.target.files[0];
    if (file) {
      setFile(file);
      setIsPreviewModalShown(true);
    }
    event.target.value = null;
  };

  const handlePreviewModalOnClose = () => {
    setIsPreviewModalShown(false);
  };

  const handleSubmitOnSuccess = (status: string) => {
    if (status === 'create') {
      toast.show({
        title: 'A new student has been created successfully',
        status: 'success',
      });
    } else if (status === 'edit') {
      toast.show({
        title: 'The student has been updated successfully',
        status: 'success',
      });
    } else if (status === 'import') {
      toast.show({
        title: 'Student(s) have been imported successfully',
        status: 'success',
      });
    }
    refetch();
  };

  return (
    <div style={{ overflow: isPreviewModalShown ? 'hidden' : 'scroll' }}>
      <Box>
        <Heading>{t('Student Management')}</Heading>
        <Center padding="10px">
          <div className={classes.stickyActionsColumn}>
            <MaterialTable
              tableRef={tableRef}
              icons={tableIcons}
              style={{ width: '100%' }}
              title={t('Student')}
              isLoading={isLoading}
              columns={columnObject}
              components={{
                Toolbar: (props) => (
                  <>
                    <MTableToolbar {...props} />
                    {error && (
                      <Box justifyContent="center" alignItems="center">
                        <Alert w="100%" status="error" borderRadius={0}>
                          <VStack space={2} flexShrink={1} w="100%">
                            <HStack flexShrink={1} space={2} justifyContent="space-between">
                              <HStack space={2} flexShrink={1}>
                                <Alert.Icon mt="1" />
                                <Text fontSize="md" color="coolGray.800">
                                  {error}
                                </Text>
                              </HStack>
                              <IconButton onPress={() => setError('')} variant="unstyled" icon={<CloseIcon size="3" color="coolGray.600" />} />
                            </HStack>
                          </VStack>
                        </Alert>
                      </Box>
                    )}
                  </>
                ),
              }}
              editable={{
                onRowAdd: async (newData: any) => {
                  const newStudent: ICreateStudent = {
                    dateOfBirth: newData.dateOfBirth ?? undefined,
                    email: newData.email,
                    firstNameChi: newData.firstNameChi ?? undefined,
                    firstNameEng: newData.firstNameEng,
                    gender: newData.gender ?? undefined,
                    lastNameChi: newData.lastNameChi ?? undefined,
                    lastNameEng: newData.lastNameEng ?? undefined,
                    password: newData.password,
                    role: 'student',
                    grade: newData.userProfile?.student.grade,
                    schoolId: schoolId,
                    yearOfEntry: newData.userProfile?.student.yearOfEntry ?? undefined,
                    studentId: newData.userProfile?.student.studentId ?? undefined,
                    class: newData.userProfile?.student.class ?? undefined,
                  };
                  // check if inputs are empty

                  const requiredFieldsFilled = Object.entries(newStudent).every(([key, value]) => {
                    if (!requiredFields.includes(key) && key !== 'password') {
                      return true;
                    } else {
                      if (value === undefined || value === '') {
                        return false;
                      } else {
                        return true;
                      }
                    }
                  });
                  if (requiredFieldsFilled) {
                    try {
                      await addStudent(newStudent);
                      setError('');
                      refetch();
                    } catch (error: any) {
                      toast.show({
                        title: 'Something went wrong ' + (error.response && error.response.status ? `(${error.response.status})` : ''),
                        status: 'error',
                        description: error.response?.data?.message ?? 'Unknown error.',
                      });
                      return Promise.reject(error);
                    }
                  } else {
                    toast.show({
                      title: 'Missing field(s)',
                      status: 'error',
                      description: 'Please fill in all the fields. ' + requiredFields.join(', ') + ' are required.',
                    });
                    return Promise.reject();
                  }
                },
                onRowUpdate: async (newData, oldData) => {
                  const editedStudent = {
                    ...newData,
                    role: 'student',
                    grade: newData.userProfile?.student?.grade,
                    schoolId: newData.userProfile?.student?.school._id,
                    yearOfEntry: newData.userProfile?.student?.yearOfEntry ?? undefined,
                    class: newData.userProfile?.student?.class ?? '',
                  };
                  const requiredFieldsFilled = Object.entries(editedStudent).every(([key, value]) => {
                    if (!requiredFields.includes(key)) {
                      return true;
                    } else {
                      if (value === undefined || value === '') {
                        return false;
                      } else {
                        return true;
                      }
                    }
                  });
                  if (requiredFieldsFilled) {
                    try {
                      await updateUser(editedStudent);
                      await refetch();
                    } catch (error: any) {
                      toast.show({
                        title: 'Something went wrong ' + (error.response && error.response.status ? `(${error.response.status})` : ''),
                        status: 'error',
                        description: error.response?.data?.message ?? 'Unknown error.',
                      });
                      return Promise.reject(error);
                    }
                  } else {
                    toast.show({
                      title: 'Missing field(s)',
                      status: 'error',
                      description: 'Please fill in all the fields ',
                    });
                    return Promise.reject();
                  }
                },
                onRowDelete: async (oldData) => {
                  console.log('delete ' + oldData);
                  try {
                    await deleteStudent(oldData._id)
                    await refetch();
                  } catch (error: any) {
                    toast.show({
                      title: 'Something went wrong ' + (error.response && error.response.status ? `(${error.response.status})` : ''),
                      status: 'error',
                      description: error.response?.data?.message ?? 'Unknown error.',
                    });
                    return Promise.reject();
                  }
                },
              }}
              data={students ?? []}
              options={{
                pageSize: 10,
                pageSizeOptions: [10, 25, 50],
                filtering: true,
                addRowPosition: 'first',
                actionsColumnIndex: 0,
                exportAllData: true,
                exportMenu: [
                  {
                    label: t('Export all students to CSV'),
                    exportFunc: (cols, data) => {
                      const sortedData = data.sort((a: any, b: any) => {
                        if ((a['userProfile.student.grade'] ?? '') === (b['userProfile.student.grade'] ?? '')) {
                          if ((a['userProfile.student.class'] ?? '') === (b['userProfile.student.class'] ?? '')) {
                            return (a['userProfile.student.number'] ?? '') - ((b['userProfile.student.number'] ?? '').toString());
                          }
                          return (a['userProfile.student.class'] ?? '').toString().localeCompare((b['userProfile.student.class'] ?? '').toString()) ?? 0;
                        }
                        return (a['userProfile.student.grade'] ?? '') - (b['userProfile.student.grade'] ?? '');
                      });
                      return ExportCsv(cols, data, `Students Export ${dayjs().format('YYYYMMDDHHmmss')}`);
                    },
                  },
                  {
                    label: t('Download template for import'),
                    exportFunc: (cols, data) => {
                      return FileSaver.saveAs('/templates/import_student_template.csv', 'student_template.csv');
                    },
                  },
                ],
              }}
              actions={[
                {
                  icon: () => <FaFileUpload />,
                  tooltip: t('Import from Excel/CSV'),
                  isFreeAction: true,
                  onClick: (event) => ref.current?.click(),
                },
              ]}
            />
          </div>
        </Center>
        <input ref={ref} type="file" style={{ display: 'none' }} accept=".csv, .xls, .xlsx" onChange={handleCsvOnImport} />
        {isPreviewModalShown && (
          <StudentCsvUploadPreviewModal file={file} onClose={handlePreviewModalOnClose} onSubmitSuccess={handleSubmitOnSuccess} />
        )}
      </Box>
    </div>
  );
};

export default withRouter(connector(StudentManagementPage));
