import {
  USER_RESET,
  ILoggedInAction,
  IResetAuthAction,
  IUser,
  ISwitchRoleAction,
  LOGGED_IN,
  CURRENT_ROLE_UPDATED,
  ILoginFailedAction,
  LOGIN_FAILED,
  REQUEST_USER_OBJ,
} from '../../types/AuthTypes';

export const requestUserObj = () => ({ type: REQUEST_USER_OBJ });

export const setUserObjError = (error: string): ILoginFailedAction => ({
  type: LOGIN_FAILED,
  data: error,
});

export const setUserObj = (userObj: IUser): ILoggedInAction => ({
  type: LOGGED_IN,
  data: userObj,
});

export const setCurrentRole = (role: string): ISwitchRoleAction => ({
  type: CURRENT_ROLE_UPDATED,
  data: role,
});

export const resetIAuthState = (): IResetAuthAction => ({ type: USER_RESET });
