import {
  sendEmailVerification,
  signInWithEmailAndPassword,
} from 'firebase/auth';
import {
  Alert,
  Box,
  Button,
  Center,
  Container,
  Flex,
  Heading,
  HStack,
  Input,
  Modal,
  Stack,
  Text,
  VStack,
  useToast,
} from 'native-base';
import { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { auth, generateAuthErrorMessage } from '../utils/authHelper';
import { RootState } from '../redux/reducers';
import { ILoginUser } from '../types/AuthTypes';
import { useTranslation } from 'react-i18next';
import { TextInputKeyPressEventData } from 'react-native';
import ChangeLanguageButton from '../components/ChangeLanguageButton';
import {
  setUserObj,
  setCurrentRole,
  requestUserObj,
} from '../redux/actions/authAction';
import login_island from '../images/login_island.png';

const mapStateToProps = (state: RootState) => ({
  userObj: state.auth.user,
  currentRole: state.auth.currentRole,
  error: state.auth.error,
  isRequest: state.auth.isRequest,
});

const mapDispatch = {
  setUserObj: setUserObj,
  setCurrentRole: setCurrentRole,
  requestUserObj: requestUserObj,
};

const connector = connect(mapStateToProps, mapDispatch);
type PropsType = ConnectedProps<typeof connector> & RouteComponentProps;

const LoginPage: React.FC<PropsType> = (props) => {
  const { t, i18n } = useTranslation('translation');
  const toast = useToast();

  useEffect(() => {
    let title = '';
    if (process.env.NODE_ENV === 'production') {
      if ((process.env.REACT_APP_CUSTOM_ENV)?.toLowerCase() === 'uat') {
        title = 'You are running on UAT environment';
      }
    } else {
      title = 'You are running on DEVELOPMENT environment';
    }
    if (title !== '') {
      toast.show({
        title: title,
        status: 'info',
        duration: 5000,
      })
    }
  }, [])

  const [account, setAccount] = useState<ILoginUser>({
    email: '',
    password: '',
  });
  const [emptyInputs, setEmptyInputs] = useState<string[]>([]);
  const [errorCode, setErrorCode] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [modalError, setModalError] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isModalShown, setIsModalShown] = useState<boolean>(false);

  // in case user switch language after the error message is generated
  i18n.on('languageChanged', () => {
    if (errorCode) {
      const errorMessage = generateAuthErrorMessage(errorCode);
      setError(errorMessage);
    }
  });

  // return error and stop loading when fail to fetch user obj
  useEffect(() => {
    if (props.error) {
      setIsLoading(false);
      setError(props.error);
    }
  }, [props.error, props.isRequest]);

  // check if user is email verified every 5 sec when modal is shown
  useEffect(() => {
    if (isModalShown) {
      const interval = setInterval(() => {
        const user = auth.currentUser;
        if (!user?.emailVerified) {
          setIsModalShown(true);
          user?.reload();
        } else {
          setIsModalShown(false);
          setIsLoading(false);
          // trigger firebase IAuthStateChanged and redirect to dashboard
          // window.location.reload();
        }
      }, 5000);

      return () => clearInterval(interval);
    } else {
      setIsLoading(false);
    }
  }, [isModalShown]);

  const handleInputOnChange = (name: string, value: string) => {
    setAccount({ ...account, [name]: value });
  };

  const handleLogin = async () => {
    // check if inputs are empty first
    let emptyInputsTmp: string[] = [];
    Object.entries(account).forEach(([key, value]) => {
      if (!value) {
        emptyInputsTmp.push(key);
      }
    });
    setEmptyInputs(emptyInputsTmp);

    // firebase auth
    const { email, password } = account;
    if (emptyInputsTmp.length === 0) {
      try {
        setIsLoading(true);
        await signInWithEmailAndPassword(auth, email, password);
        if (!auth.currentUser?.emailVerified) {
          // modal will handle the verification
          setIsModalShown(true);
        } else {
          props.requestUserObj();
        }
      } catch (error: any) {
        const errorMessage = generateAuthErrorMessage(error.code);
        setErrorCode(error.code);
        setError(errorMessage);
        setIsLoading(false);
      }
    }
  };

  const handleResendEmail = async () => {
    try {
      await sendEmailVerification(auth.currentUser!);
      setModalError('');
    } catch (error: any) {
      const errorMessage = generateAuthErrorMessage(error.code);
      setModalError(errorMessage);
    }
  };

  const onInputKeyPress = (
    e: React.BaseSyntheticEvent<TextInputKeyPressEventData>
  ) => {
    setEmptyInputs([]);
    setError('');
    if (e.nativeEvent.key === 'Enter') {
      handleLogin();
    }
  };

  return (
    <div
      style={{
        backgroundImage: `url(${login_island})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        bg="#fff"
        p={["4", "12"]}
        rounded="2xl"
        width={["90%", "80%"]}
        justifyContent="center"
        marginTop={5}
        marginBottom={5}
      >
        <Flex
          flexDirection={['column', 'column', 'row']}
          justifyContent="space-between"
          alignItems="center"
        >
          {/* left side */}
          <Container
            display="flex"
            justifyContent="center"
            textAlign="center"
            pr={['0', '0', '5']}
            pb={['5', '5', '0']}
            alignItems={'center'}
          >
            <Heading size="xl">{t('Welcome')}!</Heading>
            <Text fontSize="lg">{t('Login to your existing account')}</Text>
            <HStack mt={5}>
              <ChangeLanguageButton />
            </HStack>
          </Container>
          {/* right side */}
          <Box
            p={["2", "10"]}
            w={['100%', '100%', '50%']}
            bg="#F8F8F8"
            rounded="2xl"
            shadow={3}
            display="flex"
            justifyContent="center"
          >
            <Heading size="lg" textAlign="center" pb="10">
              Edventures
            </Heading>
            <Stack space={4} w="100%" alignItems="flex-end">
              <Input
                variant="underlined"
                w="100%"
                keyboardType='email-address'
                placeholder={`${t('Email')} *`}
                onChangeText={(value: string) =>
                  handleInputOnChange('email', value)
                }
                onKeyPress={onInputKeyPress}
                value={account.email}
                isInvalid={emptyInputs.includes('email')}
              />
              <Input
                type="password"
                variant="underlined"
                w="100%"
                placeholder={`${t('Password')} *`}
                onChangeText={(value: string) =>
                  handleInputOnChange('password', value)
                }
                onKeyPress={onInputKeyPress}
                value={account.password}
                isInvalid={emptyInputs.includes('password')}
              />
              {error && (
                <Alert w="100%" status="error">
                  <VStack space={2} flexShrink={1} w="100%">
                    <HStack
                      flexShrink={1}
                      space={2}
                      justifyContent="space-between"
                    >
                      <HStack space={2} flexShrink={1}>
                        <Alert.Icon />
                        <Text fontSize="sm" color="coolGray.800">
                          {error}
                        </Text>
                      </HStack>
                    </HStack>
                  </VStack>
                </Alert>
              )}

              <Button
                size="sm"
                variant="unstyled"
                onPress={() => props.history.push('/forgot-password')}
              >
                {t('Forgot Password?')}
              </Button>
              <Button
                width="100%"
                variant="solid"
                borderRadius="full"
                onPress={handleLogin}
                isLoading={isLoading}
              >
                {t('Login')}
              </Button>
              <Button
                width="full"
                variant="outline"
                borderRadius="full"
                onPress={() => props.history.push('/register')}
              >
                {t('Register')}
              </Button>
            </Stack>
          </Box>
        </Flex>
      </Box>

      {/* Modal */}
      <Modal isOpen={isModalShown} onClose={() => setIsModalShown(false)}>
        <Modal.Content maxWidth="md">
          <Modal.CloseButton />
          <Modal.Header background="primary.500">
            {t('Verify your account')}
          </Modal.Header>
          <Modal.Body textAlign="center">
            <Text fontSize="md">
              {t(
                'Your account has not been verified, please check your mailbox and verify it.'
              )}
            </Text>
            {modalError && (
              <Alert w="100%" status="error" my={3}>
                <VStack space={2} flexShrink={1} w="100%">
                  <HStack
                    flexShrink={1}
                    space={2}
                    justifyContent="space-between"
                  >
                    <HStack space={2} flexShrink={1}>
                      <Alert.Icon />
                      <Text fontSize="sm" color="coolGray.800">
                        {modalError}
                      </Text>
                    </HStack>
                  </HStack>
                </VStack>
              </Alert>
            )}
          </Modal.Body>
          <Modal.Footer alignItems="center">
            <Text fontSize="sm" mr={2}>
              {t('Did not receive a verification email?')}
            </Text>
            <Button variant="ghost" onPress={handleResendEmail}>
              {t('Send Again')}
            </Button>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
    </div>
  );
};

export default withRouter(connector(LoginPage));
