import { UserInfo } from 'firebase/auth';
import { ISchool } from './SchoolType';

export const USER_RESET = 'USER_RESET';
export const LOGGED_IN = 'LOGGED_IN';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const REQUEST_USER_OBJ = 'REQUEST_USER_OBJ';
export const CURRENT_ROLE_UPDATED = 'CURRENT_ROLE_UPDATED';
export interface IUserProfile {
  profileType: string;
  userId: string;
}
export interface IUser extends UserInfo {
  _id: string;
  password: string;
  email: string;
  firstNameEng: string;
  lastNameEng: string;
  firstNameChi?: string;
  lastNameChi?: string;
  dateOfBirth?: string;
  gender?: string;
  userProfile: IUserProfile;
}

export interface IUserProfile {
  student: IStudentProfile | undefined;
  teacher: ITeacherProfile | undefined;
  systemAdmin: ISystemAdminProfile | undefined;
  contentProvider: IContentProviderProfile | undefined;
}

export interface IStudentProfile {
  school: ISchool;
  yearOfEntry?: number;
  grade: number;
  class: string;
  studentId?: string;
  number?: number;
  isActive: boolean;
}

export interface IContentProviderProfile {
  isActive: boolean;
}

export interface ISystemAdminProfile {
  isActive: boolean;
}

export interface ITeacherProfile {
  school: ISchool;
  isActive: boolean;
}
export interface ILoginUser {
  email: string;
  password: string;
}

export interface IRegisterUser {
  email: string;
  password: string;
  firstNameEng: string;
  lastNameEng: string;
  firstNameChi?: string;
  lastNameChi?: string;
  dateOfBirth?: string;
  gender?: string;
  role: string;
  userProfile?: IUserProfile;
}
export interface ICreateStudent extends IRegisterUser {
  _id?: string;
  schoolId?: string;
  yearOfEntry?: number;
  studentId?: string;
  grade?: number;
  class: string;
  number?: number;
}
export interface IAuthState {
  user: IUser | null;
  currentRole: string | null;
  error: string;
  isRequest: boolean;
}

export interface ILoggedInAction {
  type: typeof LOGGED_IN;
  data: IUser;
}

export interface ILoginFailedAction {
  type: typeof LOGIN_FAILED;
  data: string;
}

export interface IResetAuthAction {
  type: typeof USER_RESET;
}

export interface ISwitchRoleAction {
  type: typeof CURRENT_ROLE_UPDATED;
  data: string;
}
