import { FC } from 'react';
import { Pressable } from 'native-base';
import { createMarkup } from '../utils/htmlHelper';

interface QuizRadioButtonProps {
  option: string;
  isSelected: boolean;
  isCorrect: boolean;
  isReadOnly: boolean;
}

const QuizRadioButton: FC<QuizRadioButtonProps> = (props) => {
  return (
    <Pressable
      p={3}
      w={['100%', '50%']}
      pointerEvents={props.isReadOnly ? 'none' : 'auto'}
    >
      <input
        type="radio"
        id={props.option}
        name="radio"
        value={props.option}
        style={{ display: 'none' }}
      />
      <label
        htmlFor={props.option}
        style={{
          boxShadow: 'rgb(0 0 0 / 25%) 0px 2px 3.84px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer',
          padding: '1rem',
          textAlign: 'center',
          borderRadius: 10,
          flexGrow: 1,
          backgroundColor: props.isSelected ? '#fedc7f' : 'white',
          border: props.isCorrect ? '5px solid #34d399' : 'none',
        }}
        dangerouslySetInnerHTML={createMarkup(props.option)}
      />
    </Pressable>
  );
};

export default QuizRadioButton;
