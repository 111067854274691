import { useEffect, useState } from 'react';
import './App.css';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { Center, NativeBaseProvider, Spinner } from 'native-base';
import LoginPage from './pages/LoginPage';
import { history } from './utils/history';
import { useQuery } from 'react-query';
import { auth } from './utils/authHelper';
import RegisterPage from './pages/RegisterPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import Navbar from './components/Navbar';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from './redux/reducers';
import { getUserObj } from './api';
import {
  setCurrentRole,
  setUserObj,
  resetIAuthState,
  setUserObjError,
  requestUserObj,
} from './redux/actions/authAction';
import DashboardPage from './pages/DashboardPage';
import ProfilePage from './pages/ProfilePage';
import ChooseSubjectPage from './pages/ChooseSubjectPage';
import QuizLearningUnitPage from './pages/QuizLearningUnitPage';
import QuizLearningObjectivePage from './pages/QuizLearningObjectivePage';
import QuestionManagementPage from './pages/QuestionManagementPage';
import StudentManagementPage from './pages/StudentManagementPage';
import StudentReportPage from './pages/StudentReportPage';
import LearningUnitManagementPage from './pages/LearningUnitManagementPage';
import LearningUnitAreaManagementPage from './pages/LearningUnitAreaManagementPage';
import LearningMaterialManagementPage from './pages/LearningMaterialManagementPage';
import SubjectManagementPage from './pages/SubjectManagementPage';
import AdminUserManagementPage from './pages/AdminUserManagementPage';
import LearningMaterialPage from './pages/LearningMaterialPage';
import { newColorTheme } from './themes/colors';
import { componentsTheme } from './themes/components';
import { signOut } from '@firebase/auth';
import { IUserProfile } from './types/AuthTypes';
import { extendedTheme } from './themes/extendedTheme';
import LearningObjectiveManagementPage from './pages/LearningObjectiveManagementPage';
import { ReactFlowProvider } from 'react-flow-renderer';
import SchoolManagementPage from './pages/SchoolManagementPage';


const mapStateToProps = (state: RootState) => ({
  userObj: state.auth.user,
  currentRole: state.auth.currentRole,
  isRequest: state.auth.isRequest,
});

const mapDispatch = {
  setUserObjError: setUserObjError,
  setUserObj: setUserObj,
  setCurrentRole: setCurrentRole,
  resetIAuthState: resetIAuthState,
  requestUserObj: requestUserObj,
};

const connector = connect(mapStateToProps, mapDispatch);
type PropsType = ConnectedProps<typeof connector>;

const App: React.FC<PropsType> = (props) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  // get and set userObj
  const query = useQuery('userObj', getUserObj, {
    enabled: props.isRequest,
    refetchOnWindowFocus: false,
    retry: false,
    onSettled: () => setIsLoading(false),
    onSuccess: (data) => {
      props.setUserObj(data);
      const storedRole = props.currentRole;
      let roles = [];
      for (const [key, value] of Object.entries(
        data.userProfile as IUserProfile
      )) {
        if (value.isActive === true) {
          roles.push(key);
        }
      }
      // check if the current user has the stored role
      if (
        (storedRole !== null &&
          roles.length > 0 &&
          !roles.includes(storedRole)) ||
        storedRole === null
      ) {
        props.setCurrentRole(roles[0]);
      }
      setIsAuthenticated(true);
    },
    onError: (error) => {
      // prevent error msg shown when the page is first loaded and no firebase currentUser yet
      if (auth.currentUser) {
        props.setUserObjError('Failed to fetch user object');
      } else {
        props.setUserObjError('');
      }
      setIsAuthenticated(false);
    },
  });

  // listen to firebase user auth state
  useEffect(() => {
    setIsLoading(true);
    auth.onAuthStateChanged((user) => {
      if (user && user.emailVerified) {
        // get userObj
        props.requestUserObj();
      } else {
        setIsAuthenticated(false);
        setIsLoading(false);
      }
    });
  }, []);

  return (
    <div className="App">
      <ReactFlowProvider>

        <NativeBaseProvider theme={extendedTheme}>
          <Router history={history}>
            {isLoading ? (
              <Center flex={1} px="3" w="full" h="full">
                <Spinner size="lg" />
              </Center>
            ) : // guest routes
              !isAuthenticated ? (
                <Switch>
                  <Route path="/login" component={LoginPage} />
                  <Route path="/register" component={RegisterPage} />
                  <Route path="/forgot-password" component={ForgotPasswordPage} />
                  <Redirect to="/login" />
                </Switch>
              ) : (
                <>
                  {/* {props.currentRole === 'student' && <Sidebar />} */}
                  <Switch>
                    {/* common authenticated routes */}
                    <Route path="/profile" component={ProfilePage} exact />
                    <Route path="/dashboard" component={DashboardPage} exact />
                    <Route path="/" component={DashboardPage} exact />
                    {/* student routes */}
                    {props.currentRole === 'student' && [
                      <Route
                        key="subjects"
                        path="/subjects"
                        component={ChooseSubjectPage}
                        exact
                      />,
                      <Route
                        key="quizLearningUnit"
                        path="/subjects/:subject/learning-unit"
                        component={QuizLearningUnitPage}
                        exact
                      />,
                      <Route
                        key="quizLearningObjective"
                        path="/subjects/:subject/learning-unit/:learningUnit/learning-objective"
                        component={QuizLearningObjectivePage}
                        exact
                      />,
                      <Route
                        key="report"
                        path="/studentreports"
                        component={StudentReportPage}
                        exact
                      />,
                      <Route
                        key="learningmaterials"
                        path="/learningmaterials"
                        component={LearningMaterialPage}
                        exact
                      />,
                    ]}
                    {/* teacher routes */}
                    {props.currentRole === 'teacher' && [
                      <Route
                        key="questionManagement"
                        path="/managements/questions"
                        component={QuestionManagementPage}
                        exact
                      />,
                      <Route
                        key="studentManagement"
                        path="/managements/students"
                        component={StudentManagementPage}
                        exact
                      />,
                      <Route
                        key="report"
                        path="/studentreports/:userId?"
                        component={StudentReportPage}
                        exact
                      />,
                      <Route
                        key="learningUnitManagement"
                        path="/managements/learningunits"
                        component={LearningUnitManagementPage}
                        exact
                      />,
                      <Route
                        key="learninnAreaManagement"
                        path="/managements/learningunitareas"
                        component={LearningUnitAreaManagementPage}
                        exact
                      />,
                      <Route
                        key="learningObjectiveManagement"
                        path="/managements/objectives"
                        component={LearningObjectiveManagementPage}
                        exact
                      />,
                      <Route
                        key="learningMaterialManagement"
                        path="/managements/learningmaterials"
                        component={LearningMaterialManagementPage}
                        exact
                      />,
                      <Route
                        key="subjectManagement"
                        path="/managements/subjects"
                        component={SubjectManagementPage}
                        exact
                      />
                    ]}
                    {/* systemAdmin routes */}
                    {props.currentRole === 'systemAdmin' && [
                      <Route
                        key="schoolManagement"
                        path="/managements/schools"
                        component={SchoolManagementPage}
                        exact
                      />,
                      <Route
                        path="/managements/users"
                        component={AdminUserManagementPage}
                        exact
                      />,
                      <Route
                        key="subjectManagement"
                        path="/managements/subjects"
                        component={SubjectManagementPage}
                        exact
                      />,
                      <Route
                        key="learninnAreaManagement"
                        path="/managements/learningunitareas"
                        component={LearningUnitAreaManagementPage}
                        exact
                      />,
                      <Route
                        key="learningUnitManagement"
                        path="/managements/learningunits"
                        component={LearningUnitManagementPage}
                        exact
                      />,
                      <Route
                        key="learningObjectiveManagement"
                        path="/managements/objectives"
                        component={LearningObjectiveManagementPage}
                        exact
                      />
                    ]}
                    {props.currentRole === 'contentProvider' && [
                      <Route
                        key="subjectManagement"
                        path="/managements/subjects"
                        component={SubjectManagementPage}
                        exact
                      />,
                      <Route
                        key="learninnAreaManagement"
                        path="/managements/learningunitareas"
                        component={LearningUnitAreaManagementPage}
                        exact
                      />,
                      <Route
                        key="learningUnitManagement"
                        path="/managements/learningunits"
                        component={LearningUnitManagementPage}
                        exact
                      />,
                      <Route
                        key="learningObjectiveManagement"
                        path="/managements/objectives"
                        component={LearningObjectiveManagementPage}
                        exact
                      />,
                      <Route
                        key="questionManagement"
                        path="/managements/questions"
                        component={QuestionManagementPage}
                        exact
                      />,
                      <Route
                        key="learningMaterialManagement"
                        path="/managements/learningmaterials"
                        component={LearningMaterialManagementPage}
                        exact
                      />
                    ]}
                    <Redirect to="/" />
                  </Switch>
                  <Navbar />
                </>
              )}
          </Router>
        </NativeBaseProvider>
      </ReactFlowProvider>
    </div>
  );
};

export default connector(App);
