import React, { useCallback, useEffect, useRef, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import {
  Heading,
  Center,
  useToast,
  Text,
  HStack,
  useTheme,
  Box,
  ScrollView,
  Flex,
  Spinner,
  View,
  Button,
} from "native-base";
import { tableIcons } from "../MaterialTableIcons";
import { useInfiniteQuery, useQuery } from "react-query";
import {
  getLearningObjectivesByStudentRole,
  getLearningUnitsByStudentRole,
  getStudentClasses,
  getStudentGrades,
  getStudentQuizReports,
  getUserById,
} from "../api";
import Check from "@material-ui/icons/Check";
import Clear from "@material-ui/icons/Clear";
import QuizReportModal from "../components/QuizReportModal";
import { useSelector } from "react-redux";
import { RootState } from "../redux/reducers";
import { IUser } from "../types/AuthTypes";
import { useTranslation } from "react-i18next";
import moment from "moment";
import StudentReportLearningUnitBarChart from "../components/StudentReportLearningUnitBarChart";
import StudentReportLearningObjectiveBarChart from "../components/StudentReportLearningObjectiveBarChart";
import StudentReportRadarChart from "../components/StudentReportRadarChart";
import StudentReportFrequencyBarChart from "../components/StudentReportFrequencyBarChart";
import TeacherSummaryTab from "../components/TeacherSummaryTab";
import { IQuiz } from "../types/QuizTypes";
import MaterialTable, { Column, MTablePagination } from "@material-table/core";
import { FaStar, FaRegStar } from "react-icons/fa";
import Select from "react-select";
import {
  getQuestions,
  getSubjects,
  deleteQuestion,
  updateQuestion,
  getLearningUnits,
  getLearningObjectives,
  getLearningUnitAreas,
  getStudentsBySchool,
} from "../api";
import { ExportCsv } from "@material-table/exporters";
import dayjs from "dayjs";
import { parseLinkHeader, getPageParam } from "../utils/parseLinkHeader";
import queryString from "query-string";

const selectStyles = {
  container: (base: any, state: any) => ({
    ...base,
    margin: "0 0.5rem",
    width: "100%",
    zIndex: "999",
  }),
};

const StudentReportPage: React.FC<RouteComponentProps> = (props) => {
  const schoolId = useSelector(
    (state: RootState) =>
      state.auth.user?.userProfile.teacher?.school?._id ?? ""
  );
  const toast = useToast();

  const { colors } = useTheme();

  const [queryUserId, setQueryUserId] = useState<string>();

  const { t, i18n } = useTranslation("translation");

  const authedUser = useSelector<RootState, IUser | null>(
    (state) => state.auth.user
  );
  const currentRole = useSelector<RootState, string | null>(
    (state) => state.auth.currentRole
  );

  const [currentPage, setCurrentPage] = useState<number>(0);

  const [totalPages, setTotalPages] = useState<number>(0);

  const { data: students, isLoading: isLoadingStudents } = useQuery(
    ["studentsBySchool", schoolId],
    () => getStudentsBySchool(schoolId),
    {
      refetchOnWindowFocus: false,
      enabled: currentRole !== "student",
    }
  );

  const [studentOptions, setStudentOptions] = useState<
    { label: string; value: string }[] | undefined
  >();

  const [isShowSummary, setIsShowSummary] = useState<boolean>(false);

  const [summaryData, setSummaryData] = useState<IQuiz[]>();

  const [filters, setFilters] = useState<{
    student: any;
    grade: any[];
    class: any[];
    subject: any[];
    learningUnit: any[];
    learningObjective: any[];
  }>({
    student: undefined,
    grade: [],
    class: [],
    subject: [],
    learningUnit: [],
    learningObjective: [],
  });

  useEffect(() => {
    if (students) {
      const uniqueStudents = students.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t._id === value._id)
      );
      const _studentOptions = uniqueStudents
        .map((user) => ({
          label:
            i18n.language === "zh" && user.lastNameChi && user.firstNameChi
              ? user.lastNameChi + user.firstNameChi
              : user.lastNameEng + user.firstNameEng,
          value: user._id,
        }))
        .sort((a, b) => a.label.localeCompare(b.label));
      setStudentOptions(_studentOptions);
    }
  }, [students]);

  useEffect(() => {
    if (currentRole === "student") {
      setQueryUserId(authedUser?._id);
    } else {
      if (props.location.pathname.split("/")[2] !== undefined) {
        setQueryUserId(props.location.pathname.split("/")[2]);
        setFilters((prev: any) => {
          const selectedStudent = studentOptions?.find(
            (x) => x.value === props.location.pathname.split("/")[2]
          );
          return { ...prev, student: selectedStudent };
        });
      } else {
        setQueryUserId(undefined);
      }
    }
  }, [isLoadingStudents, students, studentOptions]);

  const [selectedReport, setSelectedReport] = useState<any>();

  const [quizReportData, setQuizReportData] = useState<IQuiz[]>([]);

  const [isShowOnlyTopScore, setIsShowOnlyTopScore] = useState<boolean>(true);

  const [studentQuizReportData, setStudentQuizReportData] = useState<IQuiz[]>(
    []
  );

  useEffect(() => {
    console.log(studentQuizReportData);
    if (summaryData === undefined) {
      setSummaryData(studentQuizReportData);
    }
  }, [studentQuizReportData]);

  const { refetch, isFetching } = useInfiniteQuery(
    [
      "studentQuizReport",
      currentPage,
      queryUserId,
      filters.class,
      filters.grade,
      filters.subject,
      filters.learningUnit,
      filters.learningObjective,
    ],
    () =>
      getStudentQuizReports({
        pageParam: currentPage,
        perPage: 10,
        userId: queryUserId,
        studentClass: filters.class.map((x) => x.value),
        grade: filters.grade.map((x) => x.value),
        subject: filters.subject.map((x) => x._id),
        learningUnit: filters.learningUnit.map((x) => x._id),
        learningObjective: filters.learningObjective.map((x) => x._id),
      }),
    {
      getNextPageParam: (page) => getPageParam("next", page),
      getPreviousPageParam: (page) => getPageParam("prev", page),
      enabled:
        queryUserId !== undefined ||
        filters.class.length > 0 ||
        filters.grade.length > 0 ||
        filters.subject.length > 0 ||
        filters.learningUnit.length > 0 ||
        filters.learningObjective.length > 0,
      refetchOnWindowFocus: false,
      onError: () => {
        toast.show({
          title: "Something went wrong",
          description: "Cannot fetch report",
        });
      },
      onSuccess: (data) => {
        console.log(data);
        setTotalPages(Math.round(data.pages[0].headers["x-total-count"] / 10));
        console.log([].concat(...data.pages.map((page: any) => page.data)));
        setStudentQuizReportData(
          [].concat(...data.pages.map((page: any) => page.data))
        );
        if (summaryData === undefined) {
          setSummaryData(
            [].concat(...data.pages.map((page: any) => page.data))
          );
        }
      },
    }
  );

  useEffect(() => {
    if (studentQuizReportData !== undefined) {
      const _quizReportData = studentQuizReportData.reduce(
        (acc: IQuiz[], cur: IQuiz) => {
          const added = acc.find(
            (x) =>
              x.user._id === cur.user._id &&
              x.learningObjective._id === cur.learningObjective._id
          );
          if (added) {
            if (added.score < cur.score) {
              const removed = acc.filter(
                (x) =>
                  x.user._id !== cur.user._id ||
                  x.learningObjective._id !== cur.learningObjective._id
              );
              return [...removed, cur];
            } else {
              return acc;
            }
          } else {
            return [...acc, cur];
          }
        },
        []
      );

      setQuizReportData(_quizReportData);
    }
  }, [isShowOnlyTopScore, studentQuizReportData]);

  const [columnObject, setColumnObject] = useState<Column<IQuiz>[]>([]);

  const handleSelectOnChange = (name: string, newValue: any) => {
    console.log(name, newValue);
    setFilters({ ...filters, [name]: newValue });
  };

  useEffect(() => {
    refetch();
  }, [filters, queryUserId, currentPage]);

  const handleBackToReport = () => {
    setQueryUserId(undefined);
    setIsShowSummary(false);
  };

  useEffect(() => {
    setColumnObject([
      {
        title: "ID",
        type: "string",
        field: "_id",
        hidden: true,
      },
      {
        title: t("Student"),
        type: "string",
        field: "user._id",
        customSort: (a, b) =>
          a.user.lastNameEng.localeCompare(b.user.lastNameEng),
        lookup: quizReportData?.reduce(
          (map, obj) => ({
            ...map,
            [obj.user._id]:
              i18n.language === "zh"
                ? `${obj.user?.lastNameChi}${obj.user?.firstNameChi}`
                : `${obj.user?.lastNameEng} ${obj.user?.firstNameEng}`,
          }),
          {}
        ),
        exportTransformer: (rowData) =>
          `${rowData.user.lastNameChi}${rowData.user.firstNameChi} ${rowData.user.lastNameEng} ${rowData.user.firstNameEng}`,
      },
      {
        title: t("Student Number"),
        type: "string",
        field: "user.userProfile.student.number",
        sorting: false,
        exportTransformer: (rowData) =>
          rowData.user.userProfile.student?.number,
      },
      {
        title: t("Grade"),
        type: "string",
        field: "user.userProfile.student.grade",
        lookup: quizReportData?.reduce(
          (map, obj) => ({
            ...map,
            [obj.user.userProfile.student?.grade as number]:
              obj.user.userProfile.student?.grade,
          }),
          {}
        ),
        exportTransformer: (rowData) => rowData.user.userProfile.student?.grade,
      },
      {
        title: t("Class"),
        type: "string",
        field: "user.userProfile.student.class",
        lookup: quizReportData?.reduce(
          (map, obj) => ({
            ...map,
            [obj.user.userProfile.student?.class as string]:
              obj.user.userProfile.student?.class,
          }),
          {}
        ),
        exportTransformer: (rowData) => rowData.user.userProfile.student?.class,
      },
      {
        title: t("Subject"),
        type: "string",
        field: "subject",
        render: (rowData) =>
          i18n.language === "zh"
            ? `${rowData.subject?.nameChi ?? rowData.subject.nameEng}`
            : `${rowData.subject.nameEng}`,
        exportTransformer: (rowData) =>
          i18n.language === "zh"
            ? rowData.subject.nameChi
            : rowData.subject.nameEng,
        customSort: (a, b) =>
          a.subject.nameEng.localeCompare(b.subject.nameEng),
      },
      {
        title: t("Learning Unit Area"),
        type: "string",
        field: "learningUnitArea._id",
        customSort: (a, b) =>
          a.learningUnitArea.nameEng.localeCompare(b.learningUnitArea.nameEng),
        lookup: quizReportData?.reduce(
          (map, obj) => ({
            ...map,
            [obj.learningUnitArea._id]:
              i18n.language === "zh" && obj.learningUnitArea.nameChi !== null
                ? obj.learningUnitArea.nameChi
                : obj.learningUnitArea.nameEng,
          }),
          {}
        ),
        render: (rowData) =>
          i18n.language === "zh" && rowData.learningUnitArea.nameChi !== null
            ? `${rowData.learningUnitArea.nameChi}`
            : `${rowData.learningUnitArea.nameEng}`,
        exportTransformer: (rowData) =>
          i18n.language === "zh"
            ? rowData.learningUnitArea.nameChi
            : rowData.learningUnitArea.nameEng,
      },
      {
        title: `${t("Learning Unit")} ${t("Code")}`,
        type: "string",
        field: "learningUnit.code",
        render: (rowData) => rowData.learningUnit.code,
        exportTransformer: (rowData) => rowData.learningUnit.code,
      },
      {
        title: t("Learning Unit"),
        type: "string",
        field: "learningUnit",
        customSort: (a, b) =>
          a.learningUnit.code.localeCompare(b.learningUnit.code),
        render: (rowData) =>
          `${
            i18n.language === "zh"
              ? `${rowData.learningUnit.nameChi}`
              : `${rowData.learningUnit.nameEng}`
          }`,
        exportTransformer: (rowData) =>
          i18n.language === "zh"
            ? rowData.learningUnit.nameChi
            : rowData.learningUnit.nameEng,
      },
      {
        title: `${t("Learning Objective")} ${t("Code")}`,
        type: "string",
        field: "learningObjective.code",
        render: (rowData) => rowData.learningObjective.code,
        exportTransformer: (rowData) => rowData.learningObjective.code,
      },
      {
        title: t("Learning Objective"),
        type: "string",
        field: "learningObjective",
        customSort: (a, b) =>
          a.learningObjective.code.localeCompare(b.learningObjective.code),
        render: (rowData) =>
          i18n.language === "zh" && rowData.learningObjective.nameChi !== null
            ? `${rowData.learningObjective.nameChi}`
            : `${rowData.learningObjective.nameEng}`,
        exportTransformer: (rowData) =>
          i18n.language === "zh" && rowData.learningObjective.nameChi !== null
            ? rowData.learningObjective.nameChi
            : rowData.learningObjective.nameEng,
      },
      {
        title: t("No of Questions"),
        type: "numeric",
        field: "questions",
        customSort: (a, b) => a.questions.length - b.questions.length,
        render: (rowData) => rowData.questions.length,
        exportTransformer: (value) => {
          return value.questions.length;
        },
      },
      {
        title: `${t("Correct")}`,
        type: "string",
        field: "questions",
        sorting: false,
        hidden: true,
        export: true,
        exportTransformer: (value) =>
          value.questions.filter((x) => x.correctAnswer === x.userAnswer)
            .length,
      },
      {
        title: `${t("Incorrect")}`,
        type: "string",
        field: "questions",
        sorting: false,
        hidden: true,
        export: true,
        exportTransformer: (value) =>
          value.questions.filter((x) => x.correctAnswer !== x.userAnswer)
            .length,
      },
      {
        title: `${t("Correct")}/${t("Incorrect")}`,
        type: "string",
        sorting: false,
        export: false,
        render: (rowData) => (
          <HStack alignItems={"center"}>
            <Check style={{ color: colors.success["500"] }} />
            <Text color={colors.success["500"]}>
              {
                rowData.questions.filter(
                  (x) => x.correctAnswer === x.userAnswer
                ).length
              }
            </Text>
            <Clear style={{ color: colors.error["500"] }} />
            <Text color={colors.error["500"]}>
              {
                rowData.questions.filter(
                  (x) => x.correctAnswer !== x.userAnswer
                ).length
              }
            </Text>
          </HStack>
        ),
      },
      {
        title: t("Score"),
        type: "numeric",
        field: "score",
        exportTransformer: (rowData) => rowData.score * 100,
        render: (rowData) => {
          return (
            <Text
              fontSize={20}
              bold={true}
              color={
                rowData.score < 0.7
                  ? colors.error["500"]
                  : colors.success["500"]
              }
            >
              {(rowData.score * 100).toFixed(2)}
            </Text>
          );
        },
      },
      {
        title: t("Bonus"),
        type: "numeric",
        field: "bonus",
        render: (rowData) => {
          return rowData.bonus ? (
            <HStack space={1}>
              {[...Array(rowData.bonus)].map((_) => (
                <FaStar size={20} color={colors.primary["700"]} />
              ))}
            </HStack>
          ) : (
            ""
          );
        },
      },
      {
        title: t("Time used"),
        type: "numeric",
        field: "elapsedTimeMs",
        exportTransformer: (rowData) => rowData.elapsedTimeMs / 1000,
        render: (rowData) => {
          const elapsedTime = rowData.elapsedTimeMs;
          let elapsedTimeDisplay = "";
          if (elapsedTime / 1000 / 60 / 60 > 1) {
            elapsedTimeDisplay = moment.utc(elapsedTime).format("HH:mm:ss");
          } else {
            elapsedTimeDisplay = moment.utc(elapsedTime).format("mm:ss");
          }
          return elapsedTimeDisplay;
        },
      },
      {
        title: t("Attempted Date"),
        type: "datetime",
        field: "createdAt",
        exportTransformer: (rowData) =>
          dayjs(rowData.createdAt).format("YYYY-MM-DD HH:mm:ss"),
      },
    ]);
  }, [quizReportData, i18n.language]);

  // useEffect(() => {
  //   if (filters.student !== undefined) {
  //     setQueryUserId(filters.student.value);
  //     setIsShowOnlyTopScore(false)
  //   }
  //   const filterLength = Object.entries(filters).filter((key: any, value: any) => {
  //     return key !== 'student' || value.length > 0;
  //   })
  //   if (filterLength.length === 0) {
  //     setFilteredData(quizReportData);
  //   } else {
  //     const _filterData = quizReportData.filter((x) => {
  //       return (
  //         (filters.learningUnit.length === 0 || filters.learningUnit.map((x: any) => x.value).includes(x.learningUnit._id)) &&
  //         (filters.learningObjective.length === 0 || filters.learningObjective.map((x: any) => x.value).includes(x.learningObjective._id)) &&
  //         (filters.class.length === 0 || filters.class.map((x: any) => x.value).includes(x.user.userProfile.student?.class)) &&
  //         (filters.grade.length === 0 || filters.grade.map((x: any) => x.value).includes(x.user.userProfile.student?.grade))
  //       );
  //     })
  //     setFilteredData(_filterData);
  //   }
  // }, [filters, quizReportData]);

  const { data: subjects } = useQuery("subjects", () => getSubjects(), {
    refetchOnWindowFocus: false,
  });

  const { data: learningUnits } = useQuery(
    "learningUnits",
    () => getLearningUnits(),
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: learningObjectives } = useQuery(
    "learningObjectives",
    () => getLearningObjectives(),
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: studentClasses } = useQuery(
    "studentClass",
    () => getStudentClasses(schoolId),
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: studentGrades } = useQuery(
    "studentGrade",
    () => getStudentGrades(schoolId),
    {
      refetchOnWindowFocus: false,
    }
  );

  const ReportRoute = () => (
    <>
      <Flex
        flexDirection={["column", "row", "row", "row"]}
        flex={1}
        margin={2}
        flexWrap={"wrap"}
      >
        {currentRole !== "student" && (
          <Box w={["90%", "50%", "33%", "25%"]}>
            <Select
              placeholder={t("Choose Primary")}
              styles={selectStyles}
              menuPortalTarget={document.body}
              isMulti
              value={filters.grade}
              onChange={(newValue) => handleSelectOnChange("grade", newValue)}
              options={
                studentGrades &&
                studentGrades.map((x) => ({
                  label: x,
                  value: x,
                }))
              }
            />
          </Box>
        )}
        {currentRole !== "student" && (
          <Box w={["90%", "50%", "33%", "25%"]}>
            <Select
              placeholder={t("Choose Class")}
              styles={selectStyles}
              menuPortalTarget={document.body}
              isMulti
              value={filters.class}
              onChange={(newValue) => handleSelectOnChange("class", newValue)}
              options={
                studentClasses &&
                studentClasses.map((x) => ({
                  label: x,
                  value: x,
                }))
              }
            />
          </Box>
        )}
        <Box w={["90%", "50%", "33%", "25%"]}>
          <Select
            placeholder={t("Choose Subject")}
            styles={selectStyles}
            menuPortalTarget={document.body}
            isMulti
            value={filters.subject}
            onChange={(newValue) => handleSelectOnChange("subject", newValue)}
            options={subjects?.map((subject) => ({
              ...subject,
              label: `${
                i18n.language === "zh" && subject.nameChi !== null
                  ? subject.nameChi
                  : subject.nameEng
              }`,
              value: subject._id,
            }))}
          />
        </Box>
        <Box w={["90%", "50%", "33%", "25%"]}>
          <Select
            placeholder={t("Choose Learning Unit")}
            styles={selectStyles}
            menuPortalTarget={document.body}
            isMulti
            value={filters.learningUnit}
            onChange={(newValue) =>
              handleSelectOnChange("learningUnit", newValue)
            }
            options={learningUnits?.map((learningUnit) => ({
              ...learningUnit,
              label: `${learningUnit.code} - ${
                i18n.language === "zh" && learningUnit.nameChi !== null
                  ? learningUnit.nameChi
                  : learningUnit.nameEng
              }`,
              value: learningUnit._id,
            }))}
          />
        </Box>
        <Box w={["90%", "50%", "33%", "25%"]}>
          <Select
            placeholder={t("Choose Learning Objective")}
            styles={selectStyles}
            menuPortalTarget={document.body}
            isMulti
            value={filters.learningObjective}
            onChange={(newValue) =>
              handleSelectOnChange("learningObjective", newValue)
            }
            options={learningObjectives?.map((learningObjective) => ({
              ...learningObjective,
              label: `${learningObjective.code} - ${
                i18n.language === "zh" && learningObjective.nameChi !== null
                  ? learningObjective.nameChi
                  : learningObjective.nameEng
              }`,
              value: learningObjective._id,
            }))}
          />
        </Box>
      </Flex>

      <Center padding="10px">
        {/* {!isLoading &&
          studentQuizReportData &&
          studentQuizReportData.length > 0 && (
            <Box
              flexDirection={["column", "column", "row"]}
              w="full"
              flexWrap={"wrap"}
            >
              <StudentReportRadarChart quizReports={studentQuizReportData} />
              <StudentReportFrequencyBarChart
                quizReports={studentQuizReportData}
              />
              <StudentReportLearningUnitBarChart
                quizReports={studentQuizReportData}
              />
              <StudentReportLearningObjectiveBarChart
                quizReports={studentQuizReportData}
              />
            </Box>
          )} */}
        <MaterialTable
          components={{
            Pagination: (props) => (
              <MTablePagination
                {...props}
                classes={{ root: props.classes.root }}
                rowsPerPageOptions={[10]}
                rowsPerPage={10}
                page={currentPage}
                count={totalPages}
                onPageChange={(e: any, value: number) => {
                  console.log(value);
                  setCurrentPage(value);
                }}
              />
            ),
          }}
          icons={tableIcons}
          style={{ width: "100%" }}
          title={""}
          isLoading={isFetching}
          onRowClick={(event, rowData) => {
            setSelectedReport(rowData);
          }}
          columns={columnObject}
          data={studentQuizReportData ?? []}
          options={{
            paging: true,
            pageSize: 10,
            actionsColumnIndex: 0,
            filtering: false,
            search: false,
            exportAllData: true,
            exportMenu: [
              {
                label: t("Export Report"),
                exportFunc: async (cols) => {
                  const response = await getStudentQuizReports({
                    pageParam: undefined,
                    userId: queryUserId,
                    studentClass: filters.class.map((x) => x.value),
                    grade: filters.grade.map((x) => x.value),
                    subject: filters.subject.map((x) => x._id),
                    learningUnit: filters.learningUnit.map((x) => x._id),
                    learningObjective: filters.learningObjective.map(
                      (x) => x._id
                    ),
                  });

                  const data = response.data;

                  const sortedData = data.sort((a: any, b: any) => {
                    if (
                      (a["user.userProfile.student.grade"] ?? "") ===
                      (b["user.userProfile.student.grade"] ?? "")
                    ) {
                      if (
                        (a["user.userProfile.student.class"] ?? "") ===
                        (b["user.userProfile.student.class"] ?? "")
                      ) {
                        return (
                          (a["user.userProfile.student.number"] ?? 999) -
                          (b["user.userProfile.student.number"] ?? 999)
                        );
                      }
                      return (
                        (a["user.userProfile.student.class"] ?? "")
                          .toString()
                          .localeCompare(
                            (
                              b["user.userProfile.student.class"] ?? ""
                            ).toString()
                          ) ?? 0
                      );
                    }
                    return (
                      (a["user.userProfile.student.grade"] ?? "") -
                      (b["user.userProfile.student.grade"] ?? "")
                    );
                  });
                  console.log(cols);
                  return ExportCsv(
                    cols,
                    sortedData,
                    `Quiz Result Export ${dayjs().format("YYYYMMDDHHmmss")}`
                  );
                },
              },
            ],
          }}
        />
      </Center>
    </>
  );

  return (
    <ScrollView paddingTop={["35px", "0"]}>
      {selectedReport ? (
        <QuizReportModal
          onClose={() => setSelectedReport(undefined)}
          {...selectedReport}
        />
      ) : (
        <>
          <Heading>{isShowSummary ? t("Summary") : t("Reports")}</Heading>

          <Box w={["90%", "50%", "33%", "25%"]}>
            {currentRole !== "student" && !isShowSummary && (
              <>
                {studentOptions !== undefined ? (
                  <HStack space={2} marginLeft={2}>
                    <Select
                      placeholder={t("Choose Student")}
                      styles={selectStyles}
                      menuPortalTarget={document.body}
                      value={filters.student}
                      onChange={(newValue) => setQueryUserId(newValue!.value)}
                      options={[
                        ...[{ label: "", value: undefined }],
                        ...studentOptions,
                      ]}
                    />
                    {/* <Button
                      variant="outline"
                      onPress={() => setIsShowSummary(true)}
                    >
                      {t("Summary")}
                    </Button> */}
                  </HStack>
                ) : (
                  <HStack space={2} marginLeft={2}>
                    <Spinner accessibilityLabel="Loading students" />
                    <Heading color="primary.500" fontSize="md">
                      Loading Student
                    </Heading>
                  </HStack>
                )}
              </>
            )}
          </Box>

          {isShowSummary ? (
            <TeacherSummaryTab
              allData={summaryData}
              handleBackToReport={handleBackToReport}
            />
          ) : (
            <ReportRoute />
          )}
        </>
      )}
    </ScrollView>
  );
};

export default StudentReportPage;
