import { Center, Heading, VStack, HStack, Text, Flex, Box } from 'native-base';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import PageContainer from '../components/PageContainer';
import { RootState } from '../redux/reducers';
import { IStudentProfile, ITeacherProfile, IUserProfile } from '../types/AuthTypes';
import profile_background from '../images/profile_background.png';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import choose_subject_background from '../images/choose_subject_background.png'

const genderMapEng: { [key: string]: string } = {
  'M': 'Male',
  'F': 'Female'
}

const genderMapChi: { [key: string]: string } = {
  'M': '男',
  'F': '女'
}


const mapStateToProps = (state: RootState) => ({
  user: state.auth.user,
  currentRole: state.auth.currentRole,
});

const connector = connect(mapStateToProps);
type PropsType = ConnectedProps<typeof connector> & RouteComponentProps;


const ProfilePage: React.FC<PropsType> = (props) => {

  const user = props.user;
  const currentProfile = props.user?.userProfile[props.currentRole as keyof IUserProfile]
  const currentRole = props.currentRole;

  const { t, i18n } = useTranslation();

  return (
    <>

      <div style={{
        backgroundImage: `url(${choose_subject_background})`,
        height: '100%',
        width: '100%',
        overflow: 'scroll',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}>


        <Box padding='0' h='full' marginTop='50px' display={'flex'} alignItems={'center'} >
          <Box
            w={['full', 'full', '700']}
            minHeight={'100%'}
          >
            <div style={{
              backgroundImage: `url(${profile_background})`,
              height: '100%',
              width: '100%',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center top',
              backgroundSize: 'cover',
              display: 'flex',
              flexDirection: 'column',
              paddingTop: '190px',
              paddingBottom: '45px',
            }}>

              {user &&
                <VStack
                  space={4}
                  alignItems="center"
                  paddingLeft={'20px'}
                  paddingRight={'20px'}
                  height='100%'
                >
                  <Heading
                    color='black'
                    alignSelf={'center'}>
                    {currentRole ? currentRole.charAt(0).toUpperCase() + currentRole.slice(1) : ''} Profile
                  </Heading>

                  <Box variant='responsive'>
                    <Text variant='keyLabel'>{t('Email')}</Text>
                    <Text variant='valueLable'>{user?.email}</Text>
                  </Box>

                  <Box variant='responsive'>
                    <Text variant='keyLabel'>{t('Last Name')} ({t('Eng')})</Text>
                    <Text variant='valueLable'>{user?.lastNameEng}</Text>
                  </Box>

                  <Box variant='responsive'>
                    <Text variant='keyLabel'>{t('First Name')} ({t('Eng')})</Text>
                    <Text variant='valueLable'>{user?.firstNameEng}</Text>
                  </Box>

                  <Box variant='responsive'>
                    <Text variant='keyLabel'>{t('Last Name')} ({t('Chi')})</Text>
                    <Text variant='valueLable'>{user?.lastNameChi}</Text>
                  </Box>

                  <Box variant='responsive'>
                    <Text variant='keyLabel'>{t('First Name')} ({t('Chi')})</Text>
                    <Text variant='valueLable'>{user?.firstNameChi}</Text>
                  </Box>

                  <Box variant='responsive'>
                    <Text variant='keyLabel'>{t('Date of Birth')}</Text>
                    <Text variant='valueLable'>{user?.dateOfBirth !== undefined && user?.dateOfBirth !== null ? moment(user?.dateOfBirth).format('YYYY-MM-DD') : ""}</Text>
                  </Box>

                  <Box variant='responsive'>
                    <Text variant='keyLabel'>{t('Gender')}</Text>
                    <Text variant='valueLable'>{user.gender ? (i18n.language === 'zh' ? genderMapChi[user.gender] : genderMapEng[user.gender]) : ""}</Text>
                  </Box>
                  {(currentProfile && currentRole === 'teacher' || currentProfile && currentRole === 'student') &&
                    (
                      <Box variant='responsive'>
                        <Text variant='keyLabel'>{t('School')}</Text>
                        <Text variant='valueLable'>{i18n.language === 'zh' ? (currentProfile as ITeacherProfile).school.nameChi : (currentProfile as ITeacherProfile).school.nameEng}</Text>
                      </Box>
                    )}

                  {currentProfile && currentRole === 'student' &&
                    ([
                      <Box variant='responsive'>
                        <Text variant='keyLabel'>{t('Year of Entry')}</Text>
                        <Text variant='valueLable' alignContent={'right'}>{(currentProfile as IStudentProfile).yearOfEntry}</Text>
                      </Box>
                      ,
                      <Box variant='responsive'>
                        <Text variant='keyLabel'>{t('Grade')}</Text>
                        <Text variant='valueLable'>{(currentProfile as IStudentProfile).grade}</Text>
                      </Box>
                      ,
                      <Box variant='responsive'>
                        <Text variant='keyLabel'>{t('Class')}</Text>
                        <Text variant='valueLable'>{(currentProfile as IStudentProfile).class}</Text>
                      </Box>
                      ,
                      <Box variant='responsive'>
                        <Text variant='keyLabel'>{t('Student Number')}</Text>
                        <Text variant='valueLable'>{(currentProfile as IStudentProfile).number}</Text>
                      </Box>
                    ])}
                </VStack>
              }
            </div>
          </Box>
        </Box>
      </div>
    </>
  );
};

export default withRouter(connector(ProfilePage));
