import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Heading, Center, useToast, Box, Switch, ScrollView } from 'native-base';
import { tableIcons } from '../../MaterialTableIcons';
import MaterialTable, { Column } from 'material-table';
import { useQuery } from 'react-query';
import {
  getLearningMaterials,
  deleteLearningMaterial,
  addLearningMaterial,
  updateLearningMaterial,
  getLearningMaterialsIsContentProvider,
} from '../../api/LearningMaterial';
import { ILearningMaterial } from '../../types/LearningMaterialType';
import { useTranslation } from 'react-i18next';
import { PageviewOutlined, Edit, AddBox } from '@material-ui/icons';
import { createMarkup } from '../../utils/htmlHelper';
import LearningMaterialManagementModal from './LearningMaterialManagementModal';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../redux/reducers';

const mapStateToProps = (state: RootState) => ({
  userObj: state.auth.user,
  currentRole: state.auth.currentRole,
});

const connector = connect(mapStateToProps);
type PropsType = ConnectedProps<typeof connector> & RouteComponentProps;

const LearningMaterialManagementPage: React.FC<PropsType> = (
  props
) => {
  const toast = useToast();
  const { t, i18n } = useTranslation();

  const [learningMaterials, setLearningMaterials] = useState<ILearningMaterial[]>([])
  const [selectedLearningMaterial, setSelectedLearningMaterial] = useState<ILearningMaterial>();
  const [isModalShown, setIsModalShown] = useState<boolean>(false);
  const [modalStatus, setModalStatus] = useState<string>('');

  const {
    data: learningMaterialData,
    isLoading,
    refetch,
  } = useQuery('learningMaterials', () => (props.currentRole !== 'contentProvider' ? getLearningMaterials() : getLearningMaterialsIsContentProvider()), {
    refetchOnWindowFocus: false,
    onError: () => {
      toast.show({
        title: 'Something went wrong',
        status: 'error',
        description: 'Cannot fetch learning Material',
      });
    },
  });

  useEffect(() => {
    if (learningMaterialData) {
      setLearningMaterials(learningMaterialData)
    }
  }, [learningMaterialData])

  useEffect(() => {
    setColumnObject([
      {
        title: 'ID',
        type: 'string',
        field: '_id',
        hidden: true,
      },
      {
        title: `${t('Name')}`,
        type: 'string',
        field: 'name',
      },
      {
        title: t('Content'),
        type: 'string',
        render: (rowData: any) => <div style={{ fontSize: 16, maxHeight: '300px', overflow: 'scroll' }} dangerouslySetInnerHTML={createMarkup(rowData.content)} />,
        field: 'content',
      },
      {
        title: t('Attachment'),
        type: 'string',
        render: (rowData: any) => rowData.attachments.length,
        field: 'content',
      },
      {
        title: t('YouTube Link'),
        type: 'string',
        render: (rowData: any) => rowData.youtubeLinks.map((link: string) => <div><a href={link} target='_blank'>{link}</a></div>),
        field: 'content',
      },
      {
        title: t('Subject'),
        type: 'string',
        field: 'learningObject.learningUnit.subject._id',
        render: (rowData: any) => i18n.language === 'zh' ? rowData.learningObjective.learningUnit.subject.nameChi : rowData.learningObjective.learningUnit.subject.nameEng,
      },
      {
        title: t('Learning Unit'),
        type: 'string',
        field: 'learningObject.learningUnit._id',
        render: (rowData: any) => `${rowData.learningObjective.learningUnit.code} ${i18n.language === 'zh' ? rowData.learningObjective.learningUnit.nameChi : rowData.learningObjective.learningUnit.nameEng}`,
      },
      {
        title: t('Learning Objective'),
        type: 'string',
        render: (rowData: any) => `${rowData.learningObjective.code} ${i18n.language === 'zh' ? rowData.learningObjective.nameChi : rowData.learningObjective.nameEng}`,
        field: 'learningObjective._id',
      },
      {
        title: t('Visible to student'),
        type: 'boolean',
        field: 'isActive',
        render: (rowData) => {
          return (
            <Switch
              size="sm"
              isChecked={rowData.isActive}
              onToggle={async () => {
                const currentActive = rowData.isActive;
                const learningMaterialToUpdate = {
                  ...rowData,
                  learningObjectiveId: rowData.learningObjective._id,
                  isActive: !currentActive,
                };
                setLearningMaterials(learningMaterials.map((lm) => (lm._id === learningMaterialToUpdate._id ? learningMaterialToUpdate : lm)));
                try {
                  const updatedQuestion = await updateLearningMaterial(learningMaterialToUpdate);
                  setLearningMaterials(learningMaterials.map((lm) => (lm._id === updatedQuestion._id ? updatedQuestion : lm)));
                } catch (e) {
                  toast.show({
                    title: 'Something went wrong',
                    status: 'error',
                    description: 'Cannot update question visibility',
                  });
                  const olduestion = {
                    ...learningMaterialToUpdate,
                    isActive: currentActive,
                  };
                  setLearningMaterials(learningMaterials.map((lm) => (lm._id === olduestion._id ? olduestion : lm)));
                }
              }}
            />
          );
        },
      },
      {
        title: t('Created By'),
        type: 'string',
        field: 'updatedAt',
        render: (rowData) => {
          return (i18n.language === 'zh' && rowData.createdBy?.lastNameChi !== null && rowData.createdBy?.firstNameChi !== null ? `${rowData.createdBy?.lastNameChi} ${rowData.createdBy?.firstNameChi}` : `${rowData.createdBy?.lastNameEng} ${rowData.createdBy?.firstNameEng}`);
        },
        editable: 'never',
      },
      {
        title: t('Updated By'),
        type: 'string',
        field: 'updatedAt',
        render: (rowData) => {
          return (rowData.updatedBy === undefined ? 
            (i18n.language === 'zh' && rowData.createdBy?.lastNameChi !== null && rowData.createdBy?.firstNameChi !== null ? `${rowData.createdBy?.lastNameChi} ${rowData.createdBy?.firstNameChi}` : `${rowData.createdBy?.lastNameEng} ${rowData.createdBy?.firstNameEng}`) : 
            (i18n.language === 'zh' && rowData.updatedBy?.lastNameChi !== null && rowData.updatedBy?.firstNameChi !== null ? `${rowData.updatedBy?.lastNameChi} ${rowData.updatedBy?.firstNameChi}` : `${rowData.updatedBy?.lastNameEng} ${rowData.updatedBy?.firstNameEng}`));
        },
        editable: 'never',
      },
      {
        title: t('Created Date'),
        type: 'datetime',
        field: 'createdAt',
        editable: 'never',
      },
      {
        title: t('Updated Date'),
        type: 'datetime',
        field: 'updatedAt',
        editable: 'never',
      },
    ]);
  }, [i18n.language, learningMaterials]);

  //Need to define the columns in state, so that when filter is applied, and the data is refetched, the filter is kept
  //https://github.com/mbrn/material-table/issues/491#issuecomment-541011677
  const [columnObject, setColumnObject] = useState<
    Column<ILearningMaterial>[]
  >([]);

  const handleViewOnClick = (rowData: any) => {
    setSelectedLearningMaterial(rowData);
    setModalStatus('view');
    setIsModalShown(true);
  };

  const handleAddOnClick = () => {
    setSelectedLearningMaterial(undefined);
    setModalStatus('create');
    setIsModalShown(true);
  };

  const handleEditOnClick = (rowData: any) => {
    console.log('edit', rowData);
    setSelectedLearningMaterial(rowData);
    setModalStatus('edit');
    setIsModalShown(true);
  };

  const handleModalOnClose = () => {
    setSelectedLearningMaterial(undefined);
    setIsModalShown(false);
  };

  const handleSubmitOnSuccess = (status: string, newlearningMaterial?: ILearningMaterial) => {
    if (status === 'create') {
      if (newlearningMaterial) {
        setLearningMaterials([newlearningMaterial, ...learningMaterials]);
      } else {
        refetch();
      }
      toast.show({
        title: 'A new learningMaterial has been created successfully',
        status: 'success',
      });
    } else if (status === 'edit') {
      if (newlearningMaterial) {
        setLearningMaterials(learningMaterials.map((q) => (q._id === newlearningMaterial._id ? newlearningMaterial : q)));
      } else {
        refetch();
      }
      toast.show({
        title: 'The learningMaterial has been updated successfully',
        status: 'success',
      });
    } else if (status === 'import') {
      //TODO merge to learningMaterial and avoid refetch
      refetch();
      toast.show({
        title: 'learningMaterials have been imported successfully',
        status: 'success',
      });
    }
  };

  return (
    <>
      <ScrollView paddingTop={["35px", '0']}>
        <Heading>{t('Learning Material Management')}</Heading>
        <Center padding="10px">
          <MaterialTable
            icons={tableIcons}
            style={{ width: '100%' }}
            title={t('Learning Material')}
            isLoading={isLoading}
            editable={{
              onRowDelete: async (oldData) => {
                if (oldData._id) {
                  await deleteLearningMaterial(oldData._id);
                  refetch();
                }
              },
            }}
            columns={columnObject}
            data={learningMaterials ?? []}
            options={{
              pageSize: 10,
              pageSizeOptions: [10, 25, 50],
              filtering: true,
              addRowPosition: 'first',
            }}
            actions={[
              {
                icon: () => <PageviewOutlined />,
                tooltip: 'View Details',
                onClick: (event, rowData: any) => handleViewOnClick(rowData),
              },
              {
                icon: () => <Edit />,
                tooltip: 'Edit Details',
                onClick: (event, rowData) => handleEditOnClick(rowData),
              },
              {
                icon: () => <AddBox />,
                tooltip: 'Add Learning Material',
                isFreeAction: true,
                onClick: (event) => handleAddOnClick(),
              },
            ]}
          />
        </Center>

      </ScrollView>
      <LearningMaterialManagementModal
        isModalShown={isModalShown}
        status={modalStatus}
        learningMaterial={selectedLearningMaterial}
        currentRole={props.currentRole}
        onClose={handleModalOnClose}
        onEditClick={() => setModalStatus('edit')}
        onSubmitSuccess={handleSubmitOnSuccess}
      />
    </>

  );
};

export default withRouter(connector(LearningMaterialManagementPage));
