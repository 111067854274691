import { sendPasswordResetEmail } from 'firebase/auth';
import {
  Alert,
  Box,
  Button,
  Center,
  Container,
  Flex,
  Heading,
  HStack,
  IconButton,
  Input,
  Modal,
  Stack,
  Text,
  VStack,
} from 'native-base';
import { useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { auth, generateAuthErrorMessage } from '../utils/authHelper';
import { useTranslation } from 'react-i18next';
import BackButton from '../components/BackButton';
import login_island from '../images/login_island.png';

const ForgotPasswordPage: React.FC<RouteComponentProps> = (props) => {
  const { t } = useTranslation('translation');

  /*************************************************************************
   * States
   ************************************************************************/
  const [email, setEmail] = useState<string>('');
  const [isEmailEmpty, setIsEmailEmpty] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [modalError, setModalError] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isModalShown, setIsModalShown] = useState<boolean>(false);

  /*************************************************************************
   * Events
   ************************************************************************/

  const handleForgotPassword = async () => {
    if (!email) {
      setIsEmailEmpty(true);
    } else {
      try {
        setIsLoading(true);
        await sendPasswordResetEmail(auth, email);
        setIsModalShown(true);
      } catch (error: any) {
        const errorMessage = generateAuthErrorMessage(error.code);
        setError(errorMessage);
        setIsLoading(false);
      }
    }
  };

  const handleResendEmail = async () => {
    try {
      await sendPasswordResetEmail(auth, email);
      setModalError('');
    } catch (error: any) {
      const errorMessage = generateAuthErrorMessage(error.code);
      setModalError(errorMessage);
    }
  };

  /*************************************************************************
   * UI
   ************************************************************************/
  return (
    <div
      style={{
        backgroundImage: `url(${login_island})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        bg="#fff"
        p={["4", "12"]}
        rounded="2xl"
        width={["90%", "80%"]}
        justifyContent="center"
        marginTop={5}
        marginBottom={5}
        position={'relative'}
        paddingTop={['70px', '0px']}
      >
        <div style={{
          position: 'absolute',
          top: 20,
          left: 20,
        }}>
          <BackButton />
        </div>
        <Flex
          flexDirection={['column', 'column', 'row']}
          justifyContent="space-between"
          alignItems="center"
        >
          {/* left side */}
          <Container
            display="flex"
            textAlign="center"
            pr={['0', '0', '5']}
            pb={['5', '5', '0']}
          >

            <Center flex="1">
              <Heading size="xl">{t('Forgot Password')}?</Heading>
              <Text fontSize="lg">
                {t('Enter your email to reset password.')}
              </Text>
            </Center>
          </Container>
          {/* right side */}
          <Box
            p={["2", "10"]}
            h={['sm', 'sm', 'md']}
            w={['100%', '100%', '50%']}
            bg="#F8F8F8"
            rounded="2xl"
            shadow={3}
            display="flex"
            justifyContent="center"
          >
            <Heading size="lg" textAlign="center" pb="10">
              Edventures
            </Heading>
            <Stack space={4} w="100%" alignItems="flex-end">
              <Input
                variant="underlined"
                keyboardType='email-address'
                w="100%"
                placeholder={t('Email') + ' *'}
                onChangeText={(value: string) => setEmail(value)}
                value={email}
                isInvalid={isEmailEmpty}
              />
              {error && (
                <Alert w="100%" status="error">
                  <VStack space={2} flexShrink={1} w="100%">
                    <HStack
                      flexShrink={1}
                      space={2}
                      justifyContent="space-between"
                    >
                      <HStack space={2} flexShrink={1}>
                        <Alert.Icon mt="1" />
                        <Text fontSize="sm" color="coolGray.800">
                          {error}
                        </Text>
                      </HStack>
                    </HStack>
                  </VStack>
                </Alert>
              )}

              <Button
                width="100%"
                variant="solid"
                borderRadius="full"
                onPress={handleForgotPassword}
                isLoading={isLoading}
              >
                {t('Confirm')}
              </Button>
            </Stack>
          </Box>
        </Flex>
      </Box>

      {/* Modal */}
      <Modal isOpen={isModalShown} onClose={() => setIsModalShown(false)}>
        <Modal.Content maxWidth="md">
          <Modal.Header background="primary.500">
            {t('Reset your password')}
          </Modal.Header>
          <Modal.Body textAlign="center">
            <Text fontSize="md" mb={5}>
              {t(
                'A password reset email has been sent to your mailbox, please check to reset your password.'
              )}
            </Text>
            <HStack alignItems="center" justifyContent="center">
              <Text fontSize="sm" mr={2}>
                {t('Did not receive a password reset email?')}
              </Text>
              <Button variant="link" onPress={handleResendEmail}>
                {t('Send Again')}
              </Button>
            </HStack>
            {modalError && (
              <Alert w="100%" status="error" my={3}>
                <VStack space={2} flexShrink={1} w="100%">
                  <HStack
                    flexShrink={1}
                    space={2}
                    justifyContent="space-between"
                  >
                    <HStack space={2} flexShrink={1}>
                      <Alert.Icon mt="1" />
                      <Text fontSize="sm" color="coolGray.800">
                        {modalError}
                      </Text>
                    </HStack>
                  </HStack>
                </VStack>
              </Alert>
            )}
          </Modal.Body>
          <Modal.Footer alignItems="center">
            <Button
              variant="ghost"
              onPress={() => {
                setIsLoading(false);
                props.history.push('/login');
              }}
            >
              {t('Back to Login')}
            </Button>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
    </div>
  );
};

export default withRouter(ForgotPasswordPage);
