import axios from "./config/axios";
import { ICreateStudent, IRegisterUser, IUser } from "./types/AuthTypes";
import { IQuestion, IQuestionWithUserAnswer } from "./types/QuestionTypes";
import {
  IQuiz,
  IQuizLearningObjective,
  IQuizLearningUnit,
  IQuizResult,
  IQuizResultWithBonus,
  IQuizWithUserAnswerCreateRequest,
} from "./types/QuizTypes";
import { ICreateSubject, ISubject } from "./types/SubjectTypes";
import {
  ILearningUnit,
  ICreateLearningUnitRequest,
} from "./types/LearningUnitType";
import {
  ICreateLearningUnitAreaRequest,
  ILearningUnitArea,
} from "./types/LearningUnitAreaType";
import {
  ICreateLearningObjectiveRequest,
  ILearningObjective,
} from "./types/LearningObjectiveType";
import { ICreateSchool, ISchool } from "./types/SchoolTypes";
import qs from "qs";
import { IReport } from "./types/ReportTypes";
import { AxiosResponse } from "axios";

export const getUserObj = async (): Promise<IUser> => {
  return axios.get("/auth/user").then((res) => res.data);
};

export const createUserObj = async (userObj: IRegisterUser): Promise<IUser> => {
  return axios.post("/users", userObj).then((res) => res.data);
};

export const getSubjects = async (): Promise<ISubject[]> => {
  return axios.get("/subjects").then((res) => res.data);
};

export const getSubjectsIsPublic = async (): Promise<ISubject[]> => {
  return axios
    .get("/subjects?isPublic=true&allData=true")
    .then((res) => res.data);
};

export const getSubjectsByStudentRole = async (): Promise<ISubject[]> => {
  return axios.get("/subjects/student?isPublic=true").then((res) => res.data);
};

export const getSubjectById = async (id: string): Promise<ISubject> => {
  return axios.get(`/subjects/${id}`).then((res) => res.data);
};

export const getQuizLearningUnitsByStudentAndSubject = async (
  subjectId: string
): Promise<IQuizLearningUnit[]> => {
  return axios
    .get(`/quizzes/subjects/${subjectId}/learningunits?isPublic=true`)
    .then((res) => res.data);
};

export const getQuestions = async (query?: any): Promise<IQuestion[]> => {
  const queryString = query ? `?${qs.stringify(query, { encode: false })}` : "";
  return axios.get(`/questions${queryString}`).then((res) => res.data);
};

export const getQuestionsIsContentProvider = async (
  query?: any
): Promise<any> => {
  const queryString = query
    ? `?${qs.stringify(query, {
        encode: false,
      })}&isContentProvider=true&allData=false`
    : "?isContentProvider=true&allData=false";
  return axios.get(`/questions${queryString}`).then((res) => res.data);
};

export const getQuestionById = async (id: string): Promise<IQuestion> => {
  return axios.get(`/questions/${id}`).then((res) => res.data);
};

export const createQuestion = async (question: any): Promise<IQuestion> => {
  return axios.post("/questions", question).then((res) => res.data);
};

export const bulkCreateQuestion = async (
  questions: Array<any>
): Promise<IQuestion[]> => {
  return axios.post("/questions/bulk", questions).then((res) => res.data);
};

export const bulkCreateStudent = async (
  students: Array<any>
): Promise<IQuestion[]> => {
  return axios.post("/students/bulk", students).then((res) => res.data);
};

export const updateQuestion = async (question: any): Promise<IQuestion> => {
  return axios
    .put(`/questions/${question._id}`, question)
    .then((res) => res.data);
};

export const deleteQuestion = (id: string) => {
  return axios.delete(`/questions/${id}`);
};

export const getQuizQuestions = async (
  objectiveId: string
): Promise<IQuestionWithUserAnswer[]> => {
  return axios
    .get(`/quizzes/learningobjectives/${objectiveId}?isPublic=true`)
    .then((res) => res.data);
};

export const submitQuizAnswers = async (
  answers: IQuizWithUserAnswerCreateRequest
): Promise<IQuizResult> => {
  return axios.post("/quizzes/answers", answers).then((res) => res.data);
};

export const submitQuizHighQuestionsAnswers = async (
  quizId: string,
  answers: IQuizWithUserAnswerCreateRequest
): Promise<IQuizResultWithBonus> => {
  return axios
    .put(`/quizzes/answers/${quizId}`, answers)
    .then((res) => res.data);
};

export const getLearningUnits = async (
  subjectId?: string
): Promise<ILearningUnit[]> => {
  const queryString = subjectId ? `?subject=${subjectId}` : "";
  return axios.get(`/learningunits${queryString}`).then((res) => res.data);
};

export const getLearningUnitsIsPublic = async (
  subjectId?: string
): Promise<ILearningUnit[]> => {
  const queryString = subjectId
    ? `?subject=${subjectId}&isPublic=true&allData=true`
    : "?isPublic=true&allData=true";
  return axios.get(`/learningunits${queryString}`).then((res) => res.data);
};

export const getLearningUnitsByStudentRole = async (
  subjectId?: string
): Promise<ILearningUnit[]> => {
  const queryString = subjectId
    ? `?subject=${subjectId}&isPublic=true`
    : "?isPublic=true";
  return axios
    .get(`/learningunits/student${queryString}`)
    .then((res) => res.data);
};

export const getLearningObjectives = async (
  learningUnitId?: string
): Promise<ILearningObjective[]> => {
  const queryString = learningUnitId ? `?learningunit=${learningUnitId}` : "";

  return axios.get(`/learningobjectives${queryString}`).then((res) => res.data);
};

export const getLearningObjectivesIsPublic = async (
  learningUnitId?: string
): Promise<ILearningObjective[]> => {
  const queryString = learningUnitId
    ? `?learningunit=${learningUnitId}&isPublic=true&allData=true`
    : "?isPublic=true&allData=true";

  return axios.get(`/learningobjectives${queryString}`).then((res) => res.data);
};

export const getLearningObjectivesByStudentRole = async (
  learningUnitId?: string
): Promise<ILearningObjective[]> => {
  const queryString = learningUnitId
    ? `?learningunit=${learningUnitId}&isPublic=true`
    : "?isPublic=true";

  return axios
    .get(`/learningobjectives/student${queryString}`)
    .then((res) => res.data);
};

export const getStudentsBySchool = async (
  schoolId: string
): Promise<IUser[]> => {
  return axios.get("/students?schoolId=" + schoolId).then((res) => res.data);
};

export const addStudent = async (student: ICreateStudent): Promise<IUser> => {
  return axios.post("/students", student).then((res) => res.data);
};

export const getLearningUnitAreas = async (): Promise<ILearningUnitArea[]> => {
  return axios.get("/learningunitareas").then((res) => res.data);
};

export const getLearningUnitAreasIsPublic = async (): Promise<
  ILearningUnitArea[]
> => {
  return axios
    .get("/learningunitareas?isPublic=true&allData=true")
    .then((res) => res.data);
};

export const addLearningUnit = async (
  learningUnit: ICreateLearningUnitRequest
) => {
  return axios.post("/learningunits", learningUnit);
};

export const addLearningUnitArea = async (
  learningUnitArea: ICreateLearningUnitAreaRequest
) => {
  return axios.post("/learningunitareas", learningUnitArea);
};

export const addSubject = async (subject: ICreateSubject) => {
  return axios.post("/subjects", subject);
};

export const updateLearningUnit = async (
  learningUnit: ICreateLearningUnitRequest
) => {
  return axios.put(`/learningunits/${learningUnit._id}`, learningUnit);
};

export const updateLearningUnitArea = async (
  learningUnitArea: ICreateLearningUnitAreaRequest
) => {
  return axios.put(
    `/learningunitareas/${learningUnitArea._id}`,
    learningUnitArea
  );
};

export const updateSubject = async (subject: ICreateSubject) => {
  return axios.put(`/subjects/${subject._id}`, subject);
};

export const deleteLearningUnit = async (learningUnitId: string) => {
  return axios.delete(`/learningunits/${learningUnitId}`);
};

export const deleteLearningUnitArea = async (learningUnitAreaId: string) => {
  return axios.delete(`/learningunitareas/${learningUnitAreaId}`);
};

export const deleteSubject = async (subject: string) => {
  return axios.delete(`/subjects/${subject}`);
};

export const getStudentQuizReports = async ({
  pageParam,
  perPage,
  userId,
  studentClass,
  grade,
  subject,
  learningUnit,
  learningObjective,
}: {
  pageParam?: number;
  perPage?: number;
  userId: string | undefined;
  studentClass: string[] | undefined;
  grade: string[] | undefined;
  subject: string[] | undefined;
  learningUnit: string[] | undefined;
  learningObjective: string[] | undefined;
}): Promise<AxiosResponse<any, any>> => {
  let queryString = pageParam !== undefined ? `page=${pageParam}&` : "";
  queryString += perPage !== undefined ? `perPage=${perPage}&` : "";
  queryString += userId ? `userId=${userId}&` : "";
  queryString +=
    studentClass && studentClass.length > 0
      ? `studentClass=${studentClass.join(",")}&`
      : "";
  queryString += grade && grade.length > 0 ? `grade=${grade.join(",")}&` : "";
  queryString +=
    subject && subject.length > 0 ? `subject=${subject.join(",")}&` : "";
  queryString +=
    learningUnit && learningUnit.length > 0
      ? `learningUnit=${learningUnit.join(",")}&`
      : "";
  queryString +=
    learningObjective && learningObjective.length > 0
      ? `learningObjective=${learningObjective.join(",")}&`
      : "";
  return axios.get(`/quizreports?${queryString}`).then((res) => res);
};

export const getQuizReport = async (
  quizId: string | undefined
): Promise<IReport> => {
  return axios.get(`/quizreports/${quizId}`).then((res) => res.data);
};

export const getUserById = async (userId: string): Promise<IUser> => {
  return axios.get("/users/" + userId).then((res) => res.data);
};

export const updateUser = async (user: ICreateStudent): Promise<IUser> => {
  return axios.put("/students/" + user._id, user).then((res) => res.data);
};

export const addLearningObjective = async (
  learningObjective: ICreateLearningObjectiveRequest
) => {
  return axios.post("/learningobjectives", learningObjective);
};

export const updateLearningObjective = async (
  learningObjective: ICreateLearningObjectiveRequest
) => {
  return axios.put(
    `/learningobjectives/${learningObjective._id}`,
    learningObjective
  );
};

export const deleteLearningObjective = async (learningObjectiveId: string) => {
  return axios.delete(`/learningobjectives/${learningObjectiveId}`);
};

export const getSchools = async (): Promise<ISchool[]> => {
  return axios.get("/schools").then((res) => res.data);
};

export const getUsers = () => {
  return axios.get("/admin/users");
};

export const adminCreateUser = (user: object) => {
  return axios.post("/admin/users", user);
};

export const adminUpdateUser = (_id: string, user: object) => {
  return axios.put(`/admin/users/${_id}`, user);
};

export const adminDeleteUser = (userId: string) => {
  return axios.delete("/admin/users/" + userId);
};
export const deleteStudent = async (studentId: string) => {
  return axios.delete("/students/" + studentId).then((res) => res.data);
};

export const addSchool = async (subject: ICreateSchool) => {
  return axios.post("/schools", subject);
};

export const updateSchool = async (subject: ICreateSchool) => {
  return axios.put(`/schools/${subject._id}`, subject);
};

export const deleteSchool = async (subject: string) => {
  return axios.delete(`/schools/${subject}`);
};

export const adminCreateUserBulk = (users: Array<object>) => {
  return axios.post("/admin/users/bulk", users);
};

export const getStudentClasses = (schoolId: string): Promise<string[]> => {
  return axios
    .get(`/students/classes?schoolId=${schoolId}`)
    .then((res) => res.data);
};

export const getStudentGrades = (schoolId: string): Promise<number[]> => {
  return axios
    .get(`/students/grades?schoolId=${schoolId}`)
    .then((res) => res.data);
};
