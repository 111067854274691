import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../redux/reducers';
import { Heading, Center, useToast, Text, HStack, useTheme, ScrollView } from 'native-base';
import { tableIcons } from '../MaterialTableIcons'
import MaterialTable, { Column } from 'material-table';
import { useQuery } from 'react-query';
import { getLearningUnitAreas, addLearningUnitArea, updateLearningUnitArea, getSubjects, deleteLearningUnitArea, getLearningUnitAreasIsPublic, getSubjectsIsPublic } from '../api';
import { ILearningUnitArea } from '../types/LearningUnitAreaType';
import { useTranslation } from 'react-i18next';

const mapStateToProps = (state: RootState) => ({
  userObj: state.auth.user,
  currentRole: state.auth.currentRole,
});

const connector = connect(mapStateToProps);
type PropsType = ConnectedProps<typeof connector> & RouteComponentProps;

const LearningUnitAreaManagementPage: React.FC<PropsType> = (props) => {

  const toast = useToast();
  const { t, i18n } = useTranslation();

  const publicDataRole = ['systemAdmin', 'contentProvider'];

  const { data: learningUnitAreas, isLoading, isFetching, refetch } = useQuery('learningUnitAreas', () => (!publicDataRole.includes(props.currentRole || '') ? getLearningUnitAreas() : getLearningUnitAreasIsPublic()), {
    refetchOnWindowFocus: false,
    onError: () => {
      toast.show({
        title: 'Something went wrong',
        status: 'error',
        description: 'Cannot fetch learning unit area',
      });
    },
  });

  const { data: subjects, isLoading: isLoadingSubjects, refetch: refetchSubjects } = useQuery('subject', () => (!publicDataRole.includes(props.currentRole || '') ? getSubjects() : getSubjectsIsPublic()));

  const [subjectMap, setSubjectMap] = useState<{ [key: string]: string }>();


  useEffect(() => {
    const _subjectMap = subjects?.reduce<{ [key: string]: string }>((map, obj) => {
      return { ...map, [obj._id]: i18n.language === 'zh' && obj.nameChi !== null ? obj.nameChi : obj.nameEng }
    }, {});
    console.log(_subjectMap)
    setSubjectMap(_subjectMap)
  }, [subjects])

  useEffect(() => {
    setColumnObject([
      {
        title: 'ID',
        type: 'string',
        field: '_id',
        hidden: true,
      },
      {
        title: `${t('Name')}  (${t('Eng')})`,
        type: 'string',
        field: 'nameEng',
      },
      {
        title: `${t('Name')}  (${t('Chi')})`,
        type: 'string',
        field: 'nameChi'
      },
      {
        title: t('Subject'),
        type: 'string',
        lookup: subjectMap,
        field: 'subject._id',
      },
      {
        title: t('Code'),
        type: 'string',
        field: 'code',
      },
      {
        title: t('Created By'),
        type: 'string',
        field: 'updatedAt',
        render: (rowData) => {
          return (i18n.language === 'zh' && rowData.createdBy?.lastNameChi !== null && rowData.createdBy?.firstNameChi !== null ? `${rowData.createdBy?.lastNameChi} ${rowData.createdBy?.firstNameChi}` : `${rowData.createdBy?.lastNameEng} ${rowData.createdBy?.firstNameEng}`);
        },
        editable: 'never',
      },
      {
        title: t('Updated By'),
        type: 'string',
        field: 'updatedAt',
        render: (rowData) => {
          return (rowData.updatedBy === undefined ? 
            (i18n.language === 'zh' && rowData.createdBy?.lastNameChi !== null && rowData.createdBy?.firstNameChi !== null ? `${rowData.createdBy?.lastNameChi} ${rowData.createdBy?.firstNameChi}` : `${rowData.createdBy?.lastNameEng} ${rowData.createdBy?.firstNameEng}`) : 
            (i18n.language === 'zh' && rowData.updatedBy?.lastNameChi !== null && rowData.updatedBy?.firstNameChi !== null ? `${rowData.updatedBy?.lastNameChi} ${rowData.updatedBy?.firstNameChi}` : `${rowData.updatedBy?.lastNameEng} ${rowData.updatedBy?.firstNameEng}`));
        },
        editable: 'never',
      },
      {
        title: t('Created Date'),
        type: 'datetime',
        field: 'createdAt',
        editable: 'never',
      },
      {
        title: t('Updated Date'),
        type: 'datetime',
        field: 'updatedAt',
        editable: 'never',
      }
    ])

  }, [subjectMap])

  //Need to define the columns in state, so that when filter is applied, and the data is refetched, the filter is kept
  //https://github.com/mbrn/material-table/issues/491#issuecomment-541011677
  const [columnObject, setColumnObject] = useState<Column<ILearningUnitArea>[]>([]);

  return (
    <ScrollView paddingTop={["35px", '0']}>
      <Heading>{t('Area Management')}</Heading>
      <Center padding='10px'>
        <MaterialTable
          icons={tableIcons}
          style={{ width: '100%' }}
          title={t("Learning Unit Area")}
          isLoading={isLoading || isFetching}
          editable={{
            onRowAdd:  (props.currentRole !== 'contentProvider') ? async (newData) => {
              let newLearningUnitArea = { 
                ...newData, 
                subjectId: newData.subject._id,
                isPublic: (props.currentRole === 'systemAdmin'),
              };
              const { subject, ...newLearningUnitAreaRequest } = newLearningUnitArea
              console.log(newLearningUnitAreaRequest)
              await addLearningUnitArea(newLearningUnitAreaRequest)
              refetch()
            } : undefined,
            onRowUpdate: (props.currentRole !== 'contentProvider') ? async (newData, oldData) => {
              console.log(newData)
              let newLearningUnitArea = { ...newData, subjectId: newData.subject._id };
              const { subject, ...newLearningUnitAreaRequest } = newLearningUnitArea
              console.log(newLearningUnitAreaRequest)
              await updateLearningUnitArea(newLearningUnitAreaRequest)
              refetch()
            } : undefined,
            onRowDelete: (props.currentRole !== 'contentProvider') ? async (oldData) => {
              await deleteLearningUnitArea(oldData._id)
              refetch()
            } : undefined,
          }}
          columns={columnObject}
          data={learningUnitAreas ?? []}
          options={{
            pageSize: 10,
            pageSizeOptions: [10, 25, 50],
            filtering: true,
            addRowPosition: 'first',
          }}
        />
      </Center>
    </ScrollView>
  )
}

export default withRouter(connector(LearningUnitAreaManagementPage));