import { createRef, useEffect, useState } from "react";
import {
  Heading,
  Center,
  useToast,
  Box,
  Switch,
  HStack,
  // Input,
  Text,
  Flex,
  Spinner,
} from "native-base";
import { tableIcons } from "../MaterialTableIcons";
import MaterialTable, {
  Column,
  MTableFilterRow,
  MTablePagination,
  MTableToolbar,
} from "@material-table/core";
import { useQuery } from "react-query";
import {
  getQuestions,
  getSubjects,
  deleteQuestion,
  updateQuestion,
  getLearningUnits,
  getLearningObjectives,
  getLearningUnitAreas,
  getSubjectsIsPublic,
  getLearningUnitsIsPublic,
  getLearningUnitAreasIsPublic,
  getLearningObjectivesIsPublic,
  getQuestionsIsContentProvider,
} from "../api";
import { PageviewOutlined, Edit, AddBox } from "@material-ui/icons";
import QuestionManagementModal from "../components/QuestionManagementModal";
import { IQuestion } from "../types/QuestionTypes";
import { FaFileUpload, FaFileDownload, FaFileExport } from "react-icons/fa";
import { createMarkup } from "../utils/htmlHelper";
import CsvPreviewModal from "../components/CsvPreviewModal";
import FileSaver from "file-saver";
import { useTranslation } from "react-i18next";
import {
  Input,
  InputAdornment,
  makeStyles,
  TextField,
} from "@material-ui/core";
import { IoMdReturnLeft } from "react-icons/io";
import Search from "@material-ui/icons/Search";
import FilterListIcon from "@material-ui/icons/FilterList";
import Select from "react-select";
import { ILearningUnit } from "../types/LearningUnitType";
import { ILearningObjective } from "../types/LearningObjectiveType";
import { ILearningUnitArea } from "../types/LearningUnitAreaType";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../redux/reducers";
import { RouteComponentProps, withRouter } from "react-router";
import { ExportCsv } from "@material-table/exporters";
import dayjs from "dayjs";
import { ISubject } from "../types/SubjectTypes";

const mapStateToProps = (state: RootState) => ({
  userObj: state.auth.user,
  currentRole: state.auth.currentRole,
});

const connector = connect(mapStateToProps);
type PropsType = ConnectedProps<typeof connector> & RouteComponentProps;

const QuestionManagementPage: React.FC<PropsType> = (props) => {
  const toast = useToast();
  const { t, i18n } = useTranslation();

  const ref: any = createRef();

  const useStyles = makeStyles({
    stickyActionsColumn: {
      width: "100%",
      "& table:first-child": {
        "& tr": {
          "& td:first-child, th:first-child": {
            backgroundColor: "#f5f5f5",
            position: "sticky",
            left: 0,
            zIndex: 1,
          },
          "& th:first-child": {
            zIndex: 999,
          },
        },
      },
    },
    customToolbar: {
      display: "flex",
      justifyContent: "space-between",
      "& .MuiToolbar-root": {
        width: "100%",
      },
    },
    searchbar: {
      width: "100%",
      marginInline: "1rem",
    },
  });

  const classes = useStyles();

  const selectStyles = {
    container: (base: any, state: any) => ({
      ...base,
      margin: "0 0.5rem",
      width: "100%",
      zIndex: "999",
    }),
  };

  const [selectedQuestion, setSelectedQuestion] = useState<IQuestion>();
  const [isModalShown, setIsModalShown] = useState<boolean>(false);
  const [modalStatus, setModalStatus] = useState<string>("");
  const [columnObject, setColumnObject] = useState<Column<IQuestion>[]>([]);
  const [isPreviewModalShown, setIsPreviewModalShown] =
    useState<boolean>(false);
  const [file, setFile] = useState<File>();

  const [subjectMap, setSubjectMap] = useState<{ [key: string]: string }>();
  const [query, setQuery] = useState<any>({
    page: 1,
    pageSize: 10,
    includeMeta: true,
    learningUnit: "",
    learningObjective: "",
    learningUnitArea: "",
    questionType: "",
  });
  const [filters, setFilters] = useState<any>({
    learningUnit: [],
    learningUnitArea: [],
    learningObjective: [],
    questionType: [],
  });

  const [isExportingQuestion, setIsExportingQuestion] = useState(false);
  const exportQuestions = async () => {
    setIsExportingQuestion(true);
    const { page, pageSize, ...restQuery } = query;
    const questions = await getQuestions(restQuery);
    setIsExportingQuestion(false);
    const exportQuestions = questions.map((question) => {
      return {
        ...question,
        subject:
          i18n.language === "zh"
            ? (question.subject as ISubject).nameChi
            : (question.subject as ISubject).nameEng,
        learningUnit:
          i18n.language === "zh"
            ? (question.learningUnit as ILearningUnit).nameChi
            : (question.learningUnit as ILearningUnit).nameEng,
        learningObjective:
          i18n.language === "zh"
            ? (question.learningObjective as ILearningObjective).nameChi
            : (question.learningObjective as ILearningObjective).nameEng,
        learningUnitArea:
          i18n.language === "zh"
            ? (question.learningUnitArea as ILearningUnitArea).nameChi
            : (question.learningUnitArea as ILearningUnitArea).nameEng,
        "learningObjective.code": (
          question.learningObjective as ILearningObjective
        ).code,
        "learningUnit.level": (question.learningUnit as ILearningUnit).level,
        "learningUnit.number": (question.learningUnit as ILearningUnit).number,
        questionText: `"${question.questionText}"`,
        questionOptions: `"${question.questionOptions?.join(",")}"`,
        correctAnswer: `"${question.correctAnswer}"`,
      };
    });
    const exportColumnObject = columnObject.map((columnObject) => {
      switch (columnObject.field) {
        case "subject._id":
          return {
            title: "Subject",
            type: "string",
            field: "subject",
          };
        case "learningUnit.nameEng":
          return {
            title: "Learning Unit",
            type: "string",
            field: "learningUnit",
          };
        case "learningUnitArea.nameEng":
          return {
            title: "Learning Unit Area",
            type: "string",
            field: "learningUnitArea",
          };
        case "learningObjective.nameEng":
          return {
            title: "Learning Objective",
            type: "string",
            field: "learningObjective",
          };
        default:
          return columnObject;
      }
    });
    console.log(exportColumnObject);
    ExportCsv(
      exportColumnObject as any,
      exportQuestions,
      `Questions Export ${dayjs().format("YYYYMMDDHHmmss")}`
    );
  };

  const {
    data: subjects,
    isLoading: isLoadingSubjects,
    isFetching: isFetchingSubjects,
    refetch: refetchSubjects,
  } = useQuery(
    "subject",
    () =>
      props.currentRole !== "contentProvider"
        ? getSubjects()
        : getSubjectsIsPublic(),
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: learningUnits } = useQuery(
    "learningUnits",
    () =>
      props.currentRole !== "contentProvider"
        ? getLearningUnits()
        : getLearningUnitsIsPublic(),
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: learningUnitAreas } = useQuery(
    "learningUnitAreas",
    () =>
      props.currentRole !== "contentProvider"
        ? getLearningUnitAreas()
        : getLearningUnitAreasIsPublic(),
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: learningObjectives } = useQuery(
    "learningObjectives",
    () =>
      props.currentRole !== "contentProvider"
        ? getLearningObjectives()
        : getLearningObjectivesIsPublic(),
    {
      refetchOnWindowFocus: false,
    }
  );

  const [questions, setQuestions] = useState<IQuestion[]>([]);
  useEffect(() => {
    if (subjects) {
      const _subjectMap = subjects.reduce<{ [key: string]: string }>(
        (map, obj) => {
          return {
            ...map,
            [obj._id]:
              i18n.language === "zh" && obj.nameChi !== null
                ? obj.nameChi
                : obj.nameEng,
          };
        },
        {}
      );
      setSubjectMap(_subjectMap);
    }
  }, [subjects, i18n.language]);

  const {
    data: questionsData,
    isLoading,
    refetch,
  } = useQuery(
    ["questions", query],
    () =>
      props.currentRole !== "contentProvider"
        ? getQuestions(query)
        : getQuestionsIsContentProvider(query),
    {
      refetchOnWindowFocus: false,
      onError: () => {
        toast.show({
          title: "Something went wrong",
          status: "error",
          description: "Cannot fetch questions",
        });
      },
    }
  );

  useEffect(() => {
    if (questionsData) {
      setQuestions(questionsData.questions);
    }
  }, [questionsData]);

  useEffect(() => {
    setColumnObject([
      {
        title: "Question ID",
        type: "string",
        field: "_id",
        hidden: true,
      },
      {
        title: t("Subject"),
        type: "string",
        field: "subject._id",
        filtering: false,
        lookup: subjectMap,
      },
      {
        title: `${t("Learning Objective")} ${t("Code")}`,
        type: "string",
        field: "learningObjective.code",
        filtering: false,
      },
      {
        title: t("Level"),
        type: "string",
        field: "learningUnit.level",
        filtering: false,
      },
      {
        title: t("Learning Unit Number"),
        type: "string",
        field: "learningUnit.number",
        filtering: false,
      },
      {
        title: t("Learning Unit"),
        type: "string",
        field: "learningUnit.nameEng",
        render: (rowData) =>
          i18n.language === "zh" &&
          (rowData.learningUnit as ILearningUnit).nameChi !== null
            ? `${(rowData.learningUnit as ILearningUnit).nameChi}`
            : `${(rowData.learningUnit as ILearningUnit).nameEng}`,
        filtering: false,
      },
      {
        title: t("Learning Unit Area"),
        type: "string",
        field: "learningUnitArea.nameEng",
        render: (rowData) =>
          i18n.language === "zh" &&
          (rowData.learningUnitArea as ILearningUnitArea).nameChi !== null
            ? `${(rowData.learningUnitArea as ILearningUnitArea).nameChi}`
            : `${(rowData.learningUnitArea as ILearningUnitArea).nameEng}`,
        filtering: false,
      },
      {
        title: t("Learning Objective"),
        type: "string",
        field: "learningObjective.nameEng",
        render: (rowData) =>
          i18n.language === "zh" &&
          (rowData.learningObjective as ILearningObjective).nameChi !== null
            ? `${(rowData.learningObjective as ILearningObjective).nameChi}`
            : `${(rowData.learningObjective as ILearningObjective).nameEng}`,
        filtering: false,
      },
      {
        title: t("Question Text"),
        type: "string",
        field: "questionText",
        filtering: false,
        render: (rowData: any) => (
          <div
            style={{ fontSize: 16 }}
            dangerouslySetInnerHTML={createMarkup(rowData.questionText)}
          />
        ),
      },
      {
        title: t("Question Type"),
        type: "string",
        field: "questionType",
        render: (rowData: any) => t(rowData.questionType),
        filtering: false,
      },
      {
        title: t("Options"),
        type: "string",
        field: "questionOptions",
        filtering: false,
        render: (rowData: any) => (
          <div
            style={{ fontSize: 16 }}
            dangerouslySetInnerHTML={createMarkup(
              [...rowData.questionOptions].join(", ")
            )}
          />
        ),
      },
      {
        title: t("Correct Answer"),
        type: "string",
        field: "correctAnswer",
        filtering: false,
        render: (rowData: any) => (
          <div
            style={{ fontSize: 16 }}
            dangerouslySetInnerHTML={createMarkup(rowData.correctAnswer)}
          />
        ),
      },
      {
        title: t("Level"),
        type: "string",
        field: "level",
        filtering: false,
      },
      {
        title: t("Visible to student"),
        type: "boolean",
        field: "isActive",
        filtering: false,
        render: (rowData) => {
          return (
            <Switch
              size="sm"
              isChecked={rowData.isActive}
              onToggle={async () => {
                const currentActive = rowData.isActive;
                const questionToUpdate = {
                  ...rowData,
                  subjectId: (rowData.subject as ISubject)._id,
                  learningUnitId: (rowData.learningUnit as ILearningUnit)._id,
                  learningUnitAreaId: (
                    rowData.learningUnitArea as ILearningUnitArea
                  )._id,
                  learningObjectiveId: (
                    rowData.learningObjective as ILearningObjective
                  )._id,
                  isActive: !currentActive,
                };
                setQuestions(
                  questions.map((q) =>
                    q._id === questionToUpdate._id ? questionToUpdate : q
                  )
                );
                try {
                  const updatedQuestion = await updateQuestion(
                    questionToUpdate
                  );
                  setQuestions(
                    questions.map((q) =>
                      q._id === updatedQuestion._id ? updatedQuestion : q
                    )
                  );
                } catch (e) {
                  toast.show({
                    title: "Something went wrong",
                    status: "error",
                    description: "Cannot update question visibility",
                  });
                  const olduestion = {
                    ...questionToUpdate,
                    isActive: currentActive,
                  };
                  setQuestions(
                    questions.map((q) =>
                      q._id === olduestion._id ? olduestion : q
                    )
                  );
                }
              }}
            />
          );
        },
      },
      {
        title: t("Created By"),
        type: "string",
        field: "updatedAt",
        filtering: false,
        render: (rowData) => {
          return i18n.language === "zh" &&
            rowData.createdBy?.lastNameChi !== null &&
            rowData.createdBy?.firstNameChi !== null
            ? `${rowData.createdBy?.lastNameChi} ${rowData.createdBy?.firstNameChi}`
            : `${rowData.createdBy?.lastNameEng} ${rowData.createdBy?.firstNameEng}`;
        },
        editable: "never",
      },
      {
        title: t("Updated By"),
        type: "string",
        field: "updatedAt",
        filtering: false,
        render: (rowData) => {
          return rowData.updatedBy === undefined
            ? i18n.language === "zh" &&
              rowData.createdBy?.lastNameChi !== null &&
              rowData.createdBy?.firstNameChi !== null
              ? `${rowData.createdBy?.lastNameChi} ${rowData.createdBy?.firstNameChi}`
              : `${rowData.createdBy?.lastNameEng} ${rowData.createdBy?.firstNameEng}`
            : i18n.language === "zh" &&
              rowData.updatedBy?.lastNameChi !== null &&
              rowData.updatedBy?.firstNameChi !== null
            ? `${rowData.updatedBy?.lastNameChi} ${rowData.updatedBy?.firstNameChi}`
            : `${rowData.updatedBy?.lastNameEng} ${rowData.updatedBy?.firstNameEng}`;
        },
        editable: "never",
      },
      {
        title: t("Created Date"),
        type: "datetime",
        field: "createdAt",
        filtering: false,
      },
      {
        title: t("Updated Date"),
        type: "datetime",
        field: "updatedAt",
        filtering: false,
      },
    ]);
  }, [subjectMap, i18n.language, toast, t, questions]);

  const handleViewOnClick = (rowData: any) => {
    setSelectedQuestion(rowData);
    setModalStatus("view");
    setIsModalShown(true);
  };

  const handleAddOnClick = () => {
    setSelectedQuestion(undefined);
    setModalStatus("create");
    setIsModalShown(true);
  };

  const handleEditOnClick = (rowData: any) => {
    setSelectedQuestion(rowData);
    setModalStatus("edit");
    setIsModalShown(true);
  };

  const handleModalOnClose = () => {
    setSelectedQuestion(undefined);
    setIsModalShown(false);
  };

  const handlePreviewModalOnClose = () => {
    setIsPreviewModalShown(false);
  };

  const handleSubmitOnSuccess = (status: string, newQuestion?: IQuestion) => {
    if (status === "create") {
      if (newQuestion) {
        setQuestions([newQuestion, ...questions]);
      } else {
        refetch();
      }
      toast.show({
        title: "A new question has been created successfully",
        status: "success",
      });
    } else if (status === "edit") {
      if (newQuestion) {
        setQuestions(
          questions.map((q) => (q._id === newQuestion._id ? newQuestion : q))
        );
      } else {
        refetch();
      }
      toast.show({
        title: "The question has been updated successfully",
        status: "success",
      });
    } else if (status === "import") {
      //TODO merge to question and avoid refetch
      refetch();
      toast.show({
        title: "Questions have been imported successfully",
        status: "success",
      });
    }
  };

  const handleCsvOnImport = async (event: any) => {
    const file = event.target.files[0];
    if (file) {
      setFile(file);
      setIsPreviewModalShown(true);
    }
    event.target.value = null;
  };

  // update questions according to new page number and page size
  useEffect(() => {
    if (query) {
      refetch();
    }
  }, [query, refetch]);

  const handleFilterOnChange = (filters: any) => {
    let newQuery = { ...query };
    if (filters.length > 0) {
      filters.forEach((filter: any) => {
        let key = "";
        const field = String(filter.column.field);
        if (field) {
          if (field.includes(".")) {
            key = field.substring(0, field.indexOf("."));
          } else {
            key = field;
          }
        }
        if (key === "subject") {
          newQuery[key] = filter.value.join(",");
        } else {
          newQuery[key] = filter.value;
        }
      });
    } else {
      newQuery = {
        page: query.page,
        pageSize: query.pageSize,
        includeMeta: query.includeMeta,
        learningUnit: query.learningUnit,
        learningObjective: query.learningObjective,
        learningUnitArea: query.learningUnitArea,
        questionType: query.questionType,
      };
    }
    setQuery(newQuery);
  };

  const handleSelectOnChange = (name: string, newValue: any) => {
    setFilters({ ...filters, [name]: newValue });
    if (newValue.value === "reset") {
      const { [name]: _, ...remaining } = query;
      console.log(remaining);
      setQuery(remaining);
    } else {
      setQuery({
        ...query,
        page: 1,
        [name]: Array.isArray(newValue)
          ? newValue.map((val: any) => val.value).join(",")
          : newValue.value,
      });
    }
  };

  return (
    <div style={{ overflow: isModalShown ? "hidden" : "scroll" }}>
      <Box paddingTop={["35px", "0"]}>
        <Heading>{t("Question Management")}</Heading>
        <Center padding="10px">
          <div className={classes.stickyActionsColumn}>
            <MaterialTable
              icons={tableIcons}
              style={{ width: "100%" }}
              title={t("Questions")}
              isLoading={isLoading || isLoadingSubjects || isFetchingSubjects}
              columns={columnObject}
              components={{
                Pagination: (props) => (
                  <MTablePagination
                    {...props}
                    classes={{ root: props.classes.root }}
                    pageSize={10}
                    rowsPerPageOptions={[10, 25, 50]}
                    onPageChange={(e: any, value: number) =>
                      setQuery({ ...query, page: value + 1 })
                    }
                    onRowsPerPageChange={(event: any) => {
                      setQuery({ ...query, pageSize: event?.target.value });
                    }}
                    count={questionsData?._metadata.total ?? 0}
                    page={query.page - 1}
                  />
                ),
                Toolbar: (props) => (
                  <div className={classes.customToolbar}>
                    <Flex
                      flexDirection={["column", "column", "row", "row"]}
                      flex={1}
                      marginRight={2}
                    >
                      <Heading fontWeight={500} pl={8}>
                        {t("Questions")}
                      </Heading>
                      <HStack
                        w={["100%", "100%", "60%", "70%"]}
                        justifyContent="flex-start"
                        alignItems="center"
                        flexWrap={"wrap"}
                      >
                        <Box w={["90%", "50%", "33%", "25%"]}>
                          <Select
                            placeholder={t("Choose Subject")}
                            menuPortalTarget={document.body}
                            styles={selectStyles}
                            isMulti
                            value={filters.subject}
                            onChange={(newValue) =>
                              handleSelectOnChange("subject", newValue)
                            }
                            options={subjects?.map((subject) => ({
                              ...subject,
                              label: `${
                                i18n.language === "zh" &&
                                subject.nameChi !== null
                                  ? subject.nameChi
                                  : subject.nameEng
                              }`,
                              value: subject._id,
                            }))}
                          />
                        </Box>
                        <Box w={["90%", "50%", "33%", "25%"]}>
                          <Select
                            placeholder={t("Choose Learning Unit")}
                            menuPortalTarget={document.body}
                            styles={selectStyles}
                            isMulti
                            value={filters.learningUnit}
                            onChange={(newValue) =>
                              handleSelectOnChange("learningUnit", newValue)
                            }
                            options={learningUnits?.map((learningUnit) => ({
                              ...learningUnit,
                              label: `${learningUnit.code} - ${
                                i18n.language === "zh" &&
                                learningUnit.nameChi !== null
                                  ? learningUnit.nameChi
                                  : learningUnit.nameEng
                              }`,
                              value: learningUnit._id,
                            }))}
                          />
                        </Box>
                        <Box w={["90%", "50%", "33%", "25%"]}>
                          <Select
                            placeholder={t("Choose Learning Unit Area")}
                            menuPortalTarget={document.body}
                            styles={selectStyles}
                            isMulti
                            value={filters.learningUnitArea}
                            onChange={(newValue) =>
                              handleSelectOnChange("learningUnitArea", newValue)
                            }
                            options={learningUnitAreas?.map(
                              (learningUnitArea) => ({
                                ...learningUnitArea,
                                label: `${learningUnitArea.code} - ${
                                  i18n.language === "zh" &&
                                  learningUnitArea.nameChi !== null
                                    ? learningUnitArea.nameChi
                                    : learningUnitArea.nameEng
                                }`,
                                value: learningUnitArea._id,
                              })
                            )}
                          />
                        </Box>
                        <Box w={["90%", "50%", "33%", "25%"]}>
                          <Select
                            placeholder={t("Choose Learning Objective")}
                            menuPortalTarget={document.body}
                            styles={selectStyles}
                            isMulti
                            value={filters.learningObjective}
                            onChange={(newValue) =>
                              handleSelectOnChange(
                                "learningObjective",
                                newValue
                              )
                            }
                            options={learningObjectives?.map(
                              (learningObjective) => ({
                                ...learningObjective,
                                label: `${learningObjective.code} - ${
                                  i18n.language === "zh" &&
                                  learningObjective.nameChi !== null
                                    ? learningObjective.nameChi
                                    : learningObjective.nameEng
                                }`,
                                value: learningObjective._id,
                              })
                            )}
                          />
                        </Box>
                        <Box w={["90%", "50%", "33%", "25%"]}>
                          <Select
                            placeholder={t("Choose Question Type")}
                            menuPortalTarget={document.body}
                            styles={selectStyles}
                            isMulti
                            value={filters.questionType}
                            onChange={(newValue) =>
                              handleSelectOnChange("questionType", newValue)
                            }
                            options={[
                              {
                                label: t("Multiple Choice"),
                                value: "multipleChoice",
                              },
                              {
                                label: t("Free Text"),
                                value: "freeText",
                              },
                              {
                                label: t("Fill In The Blank"),
                                value: "fillInTheBlank",
                              },
                            ]}
                          />
                        </Box>
                        <Box w={["90%", "50%", "33%", "25%"]}>
                          <Select
                            placeholder={t("Visible to student")}
                            menuPortalTarget={document.body}
                            styles={selectStyles}
                            value={filters.isActive}
                            onChange={(newValue) =>
                              handleSelectOnChange("isActive", newValue)
                            }
                            options={[
                              {
                                label: t("Visible"),
                                value: true,
                              },
                              {
                                label: t("Not Visible"),
                                value: false,
                              },
                              {
                                label: t("Show all"),
                                value: "reset",
                              },
                            ]}
                          />
                        </Box>
                      </HStack>
                      <MTableToolbar {...props} />
                    </Flex>
                  </div>
                ),
              }}
              data={questions ?? []}
              options={{
                paging: true,
                pageSize: 10,
                filtering: true,
                pageSizeOptions: [10, 25, 50],
                search: false,
                showTitle: false,
              }}
              editable={{
                onRowDelete: async (oldData) => {
                  await deleteQuestion(oldData._id);
                  setQuestions(
                    questions.filter((question) => question._id !== oldData._id)
                  );
                  return Promise.resolve();
                },
              }}
              actions={[
                {
                  icon: () => <PageviewOutlined />,
                  tooltip: "View Details",
                  onClick: (event, rowData: any) => handleViewOnClick(rowData),
                },
                {
                  icon: () => <Edit />,
                  tooltip: "Edit Details",
                  onClick: (event, rowData) => handleEditOnClick(rowData),
                },
                {
                  icon: () =>
                    isExportingQuestion ? <Spinner /> : <FaFileExport />,
                  tooltip: "Export Questions",
                  isFreeAction: true,
                  onClick: async (event) => {
                    await exportQuestions();
                  },
                },
                {
                  icon: () => <FaFileDownload />,
                  tooltip: "Download template",
                  isFreeAction: true,
                  onClick: (event) =>
                    FileSaver.saveAs(
                      "/templates/import_question_template.xlsx",
                      "question.xlsx"
                    ),
                },
                {
                  icon: () => <FaFileUpload />,
                  tooltip: "Import from Excel",
                  isFreeAction: true,
                  onClick: (event) => ref.current.click(),
                },
                {
                  icon: () => <AddBox />,
                  tooltip: "Add Question Maunally",
                  isFreeAction: true,
                  onClick: (event) => handleAddOnClick(),
                },
              ]}
              onFilterChange={handleFilterOnChange}
            />
          </div>
        </Center>
        <input
          ref={ref}
          type="file"
          style={{ display: "none" }}
          accept=".csv, .xls, .xlsx"
          onChange={handleCsvOnImport}
        />
      </Box>
      <QuestionManagementModal
        isModalShown={isModalShown}
        status={modalStatus}
        question={selectedQuestion}
        currentRole={props.currentRole}
        onClose={handleModalOnClose}
        onEditClick={() => setModalStatus("edit")}
        onSubmitSuccess={handleSubmitOnSuccess}
      />
      {isPreviewModalShown && (
        <CsvPreviewModal
          currentRole={props.currentRole}
          file={file}
          onClose={handlePreviewModalOnClose}
          onSubmitSuccess={handleSubmitOnSuccess}
        />
      )}
    </div>
  );
};

export default withRouter(connector(QuestionManagementPage));
