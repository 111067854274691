import { FC, useState } from "react";
import {
  Button,
  CheckIcon,
  FormControl,
  HStack,
  Input,
  Modal,
  Select,
  Text,
  VStack,
  Stack,
  Alert,
  Switch,
} from "native-base";
import moment from "moment";
import { useQuery } from "react-query";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { adminCreateUser, adminUpdateUser, getSchools } from "../../../api";
import { useTranslation } from "react-i18next";
import { ISchool } from "../../../types/SchoolTypes";
import {
  checkEmailFormat,
  generateAuthErrorMessage,
} from "../../../utils/authHelper";

interface UserModalProps {
  status: string;
  onClose: () => void;
  onEditClick: () => void;
  onSubmitSuccess: (data?: any) => void;
  data: any;
}

const UserModal: FC<UserModalProps> = (props) => {
  const [account, setAccount] = useState<any>({
    email: props.data?.email ?? "",
    password: "",
    firstNameEng: props.data?.firstNameEng ?? "",
    lastNameEng: props.data?.lastNameEng ?? "",
    firstNameChi: props.data?.firstNameChi ?? "",
    lastNameChi: props.data?.lastNameChi ?? "",
    dateOfBirth: props.data?.dateOfBirth
      ? moment(props.data?.dateOfBirth).format("YYYY-MM-DD")
      : "",
    gender: props.data?.gender ?? "",
    userProfile: {
      student: {
        isActive: props.data?.userProfile?.student?.isActive ?? false,
        schoolId: props.data?.userProfile?.student?.school ?? "",
        grade: props.data?.userProfile?.student?.grade?.toString() ?? "",
        yearOfEntry: props.data?.userProfile?.student?.yearOfEntry ?? "",
        studentId: props.data?.userProfile?.student?.studentId ?? "",
        class: props.data?.userProfile?.student?.class ?? "",
        number: props.data?.userProfile?.student?.number ?? "",
      },
      teacher: {
        isActive: props.data?.userProfile?.teacher?.isActive ?? false,
        schoolId: props.data?.userProfile?.teacher?.school ?? "",
      },
      systemAdmin: {
        isActive: props.data?.userProfile?.systemAdmin?.isActive ?? false,
      },
      contentProvider: {
        isActive: props.data?.userProfile?.contentProvider?.isActive ?? false,
      },
    },
  });
  const [invalidInputs, setInvalidInputs] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isPasswordShown, setIsPasswordShown] = useState<boolean>(false);
  const [emptyInputs, setEmptyInputs] = useState<string[]>([]);
  const [error, setError] = useState<string | undefined>("");

  const { t } = useTranslation();
  const { data: schools } = useQuery("schools", getSchools, {});

  const requiredFields = ["email", "lastNameEng", "firstNameEng", "password"];

  const handleInputOnChange = (name: string, value: any) => {
    setAccount({ ...account, [name]: value });
  };

  const handleRegister = async () => {
    // check if inputs are empty first
    let emptyInputsTmp: string[] = [];
    Object.entries(account).forEach(([key, value]) => {
      if (!value && requiredFields.includes(key)) {
        emptyInputsTmp.push(key);
      }
    });

    // Check the school ID of the student
    if (
      account?.userProfile?.student?.isActive &&
      account?.userProfile?.student?.schoolId === ""
    ) {
      emptyInputsTmp.push("studentSchoolId");
    }

    // Check the school ID of the teacher
    if (
      account?.userProfile?.teacher?.isActive &&
      account?.userProfile?.teacher?.schoolId === ""
    ) {
      emptyInputsTmp.push("teacherSchoolId");
    }

    // Edit mode allow update without password
    if (props.status === "edit" && !account.password) {
      emptyInputsTmp = emptyInputsTmp.filter((item) => item !== "password");
    }

    // validate inputs
    let invalidInputsTmp: string[] = [];
    let isDateValid = moment(account.dateOfBirth, "YYYY-MM-DD", true).isValid();
    if (account.dateOfBirth === "") {
      isDateValid = true;
    }
    if (!isDateValid && account.dateOfBirth !== "") {
      invalidInputsTmp.push("dateOfBirth");
    }
    if (!checkEmailFormat(account.email)) {
      invalidInputsTmp.push("email");
    }

    setEmptyInputs(emptyInputsTmp);
    setInvalidInputs(invalidInputsTmp);

    if (emptyInputsTmp.length === 0 && invalidInputsTmp.length === 0) {
      if (account.password === "") {
        delete account.password;
      }

      try {
        setIsLoading(true);
        switch (props.status) {
          case "edit":
            await adminUpdateUser(props.data._id, account)
              .then((res) => {
                props.onSubmitSuccess(res);
                setIsLoading(false);
              })
              .catch((e) => {
                setError(e.response.data.message);
                setTimeout(() => {
                  setError(undefined);
                }, 5000);
                setIsLoading(false);
              });
            break;

          case "create":
          default:
            await adminCreateUser(account)
              .then((res) => {
                props.onSubmitSuccess(res);
                setIsLoading(false);
              })
              .catch((e: any) => {
                setError(e.response.data.message);
                setTimeout(() => {
                  setError(undefined);
                }, 5000);
                setIsLoading(false);
              });
            break;
        }
      } catch (error: any) {
        const errorMessage = generateAuthErrorMessage(error.code);
        setError(errorMessage);
        setIsLoading(false);
      }
    }
  };

  return (
    <Modal
      defaultIsOpen
      onClose={props.onClose}
      size="full"
      closeOnOverlayClick={false}
      _backdrop={{ height: "100%" }}
    >
      <Modal.Content height="100%" maxHeight="100%">
        <Modal.CloseButton />
        <Modal.Header background="primary.500" textTransform="capitalize">
          <HStack>
            {props.status === "create" && t("Create New User")}
            {props.status === "edit" && t("Edit User")}
          </HStack>
        </Modal.Header>
        <Modal.Body minHeight="md" p="3">
          <>
            {props.data?._id && (
              <Text bold>
                {t("User")} ID: <Text>{props.data?._id}</Text>
              </Text>
            )}

            <Stack space={3} w="100%">
              <HStack variant="responsive">
                <Input
                  variant="underlined"
                  w="100%"
                  placeholder={`${t("Last Name")} (${t("Eng")}) *`}
                  onChangeText={(value: string) =>
                    handleInputOnChange("lastNameEng", value)
                  }
                  value={account.lastNameEng}
                  isInvalid={emptyInputs.includes("lastNameEng")}
                />
              </HStack>

              <Input
                variant="underlined"
                w="100%"
                placeholder={`${t("First Name")} (${t("Eng")}) *`}
                onChangeText={(value: string) =>
                  handleInputOnChange("firstNameEng", value)
                }
                value={account.firstNameEng}
                marginRight="2"
                isInvalid={emptyInputs.includes("firstNameEng")}
              />

              <HStack variant="responsive">
                <Input
                  variant="underlined"
                  w="100%"
                  placeholder={`${t("Last Name")} (${t("Chi")})`}
                  onChangeText={(value: string) =>
                    handleInputOnChange("lastNameChi", value)
                  }
                  value={account.lastNameChi}
                  isInvalid={emptyInputs.includes("lastNameChi")}
                />
                <Input
                  variant="underlined"
                  w="100%"
                  placeholder={`${t("First Name")} (${t("Chi")})`}
                  onChangeText={(value: string) =>
                    handleInputOnChange("firstNameChi", value)
                  }
                  value={account.firstNameChi}
                  marginRight="2"
                  isInvalid={emptyInputs.includes("firstNameChi")}
                />
              </HStack>

              <Text fontWeight={"100"}>{t("Date of Birth")}</Text>
              <DayPickerInput
                placeholder="YYYY-MM-DD"
                format="YYYY-MM-DD"
                value={
                  account.dateOfBirth
                    ? new Date(account.dateOfBirth)
                    : undefined
                }
                onDayChange={(value) =>
                  handleInputOnChange(
                    "dateOfBirth",
                    moment(value).format("YYYY-MM-DD")
                  )
                }
              />

              <HStack variant="responsive">
                <FormControl
                  width={["100%", "50%"]}
                  isInvalid={emptyInputs.includes("gender")}
                >
                  <Select
                    placeholder={`${t("Gender")}`}
                    _selectedItem={{
                      bg: "teal.600",
                      endIcon: <CheckIcon size="5" />,
                    }}
                    mt={[2, 0]}
                    mr={0}
                    selectedValue={account.gender}
                    onValueChange={(value: string) =>
                      handleInputOnChange("gender", value)
                    }
                  >
                    <Select.Item label="" value="" />
                    <Select.Item label={t("Female")} value="F" />
                    <Select.Item label={t("Male")} value="M" />
                  </Select>
                </FormControl>
              </HStack>

              <HStack alignItems={"center"} space={4}>
                <Text fontWeight={"500"}>{t("Student Role Is Active")}</Text>
                <Switch
                  size="sm"
                  isChecked={account?.userProfile?.student?.isActive}
                  onToggle={() =>
                    setAccount({
                      ...account,
                      userProfile: {
                        ...account.userProfile,
                        student: {
                          ...account.userProfile.student,
                          isActive: !account?.userProfile?.student?.isActive,
                        },
                      },
                    })
                  }
                />
              </HStack>

              {account?.userProfile?.student?.isActive && (
                <VStack>
                  <HStack
                    mt={[0, 2]}
                    justifyContent="space-between"
                    variant="responsive"
                  >
                    {schools && (
                      <FormControl
                        width={["100%", "30%"]}
                        isRequired
                        isInvalid={emptyInputs.includes("studentSchoolId")}
                      >
                        <Select
                          placeholder={`${t("School")} *`}
                          _selectedItem={{
                            bg: "teal.600",
                            endIcon: <CheckIcon size="5" />,
                          }}
                          mb={[1, 0]}
                          mr={[0, 2]}
                          selectedValue={
                            account?.userProfile?.student?.schoolId
                          }
                          onValueChange={(value: string) =>
                            setAccount({
                              ...account,
                              userProfile: {
                                ...account.userProfile,
                                student: {
                                  ...account.userProfile.student,
                                  schoolId: value,
                                },
                              },
                            })
                          }
                        >
                          <Select.Item label="" value="" />
                          {schools?.map((school: ISchool) => (
                            <Select.Item
                              key={school?._id}
                              label={school?.nameEng}
                              value={school?._id}
                            />
                          ))}
                        </Select>
                      </FormControl>
                    )}
                    <FormControl
                      width={["100%", "30%"]}
                      isInvalid={emptyInputs.includes("grade")}
                    >
                      <Select
                        placeholder={t("Grade")}
                        _selectedItem={{
                          bg: "teal.600",
                          endIcon: <CheckIcon size="5" />,
                        }}
                        my={[2, 0]}
                        mx={[0, 2]}
                        selectedValue={account?.userProfile?.student?.grade}
                        onValueChange={(value: string) =>
                          setAccount({
                            ...account,
                            userProfile: {
                              ...account.userProfile,
                              student: {
                                ...account.userProfile.student,
                                grade: value,
                              },
                            },
                          })
                        }
                      >
                        <Select.Item label="" value="" />
                        {Array(6)
                          .fill(0)
                          .map((map, i) => i)
                          .map((grade) => (
                            <Select.Item
                              key={grade + 1}
                              label={String(grade + 1)}
                              value={String(grade + 1)}
                            />
                          ))}
                      </Select>
                    </FormControl>
                    <Input
                      ml={[0, 2]}
                      width="100%"
                      variant="underlined"
                      placeholder={t("Year of Entry")}
                      onChangeText={(value: string) =>
                        setAccount({
                          ...account,
                          userProfile: {
                            ...account.userProfile,
                            student: {
                              ...account.userProfile.student,
                              yearOfEntry: value,
                            },
                          },
                        })
                      }
                      value={
                        account?.userProfile?.student?.yearOfEntry === 0
                          ? ""
                          : String(account?.userProfile?.student?.yearOfEntry)
                      }
                      isInvalid={emptyInputs.includes("yearOfEntry")}
                    />
                  </HStack>

                  <HStack
                    mt={[0, 2]}
                    justifyContent="space-between"
                    variant="responsive"
                  >
                    <Input
                      ml={[0, 2]}
                      width="100%"
                      variant="underlined"
                      placeholder={`${t("Student")} ID`}
                      onChangeText={(value: string) =>
                        setAccount({
                          ...account,
                          userProfile: {
                            ...account.userProfile,
                            student: {
                              ...account.userProfile.student,
                              studentId: value,
                            },
                          },
                        })
                      }
                      value={
                        account?.userProfile?.student?.studentId === 0
                          ? ""
                          : String(account?.userProfile?.student?.studentId)
                      }
                      isInvalid={emptyInputs.includes("studentId")}
                    />

                    <Input
                      ml={[0, 2]}
                      width="100%"
                      variant="underlined"
                      placeholder={t("Class")}
                      onChangeText={(value: string) =>
                        setAccount({
                          ...account,
                          userProfile: {
                            ...account.userProfile,
                            student: {
                              ...account.userProfile.student,
                              class: value,
                            },
                          },
                        })
                      }
                      value={
                        account?.userProfile?.student?.class === 0
                          ? ""
                          : String(account?.userProfile?.student?.class)
                      }
                      isInvalid={emptyInputs.includes("class")}
                    />

                    <Input
                      ml={[0, 2]}
                      width="100%"
                      variant="underlined"
                      placeholder={t("Student Number")}
                      onChangeText={(value: string) =>
                        setAccount({
                          ...account,
                          userProfile: {
                            ...account.userProfile,
                            student: {
                              ...account.userProfile.student,
                              number: value,
                            },
                          },
                        })
                      }
                      value={
                        account?.userProfile?.student?.number === 0
                          ? ""
                          : String(account?.userProfile?.student?.number)
                      }
                      isInvalid={emptyInputs.includes("number")}
                    />
                  </HStack>
                </VStack>
              )}

              <HStack alignItems={"center"} space={4}>
                <Text fontWeight={"500"}>{t("Teacher Role Is Active")}</Text>
                <Switch
                  size="sm"
                  isChecked={account?.userProfile?.teacher?.isActive}
                  onToggle={() =>
                    setAccount({
                      ...account,
                      userProfile: {
                        ...account.userProfile,
                        teacher: {
                          ...account.userProfile.teacher,
                          isActive: !account?.userProfile?.teacher?.isActive,
                        },
                      },
                    })
                  }
                />
              </HStack>

              {account?.userProfile?.teacher?.isActive && (
                <HStack
                  mt={[0, 2]}
                  justifyContent="space-between"
                  variant="responsive"
                >
                  {schools && (
                    <FormControl
                      width={["100%", "30%"]}
                      isRequired
                      isInvalid={emptyInputs.includes("teacherSchoolId")}
                    >
                      <Select
                        placeholder={`${t("School")} *`}
                        _selectedItem={{
                          bg: "teal.600",
                          endIcon: <CheckIcon size="5" />,
                        }}
                        mb={[1, 0]}
                        mr={[0, 2]}
                        selectedValue={account?.userProfile?.teacher?.schoolId}
                        onValueChange={(value: string) =>
                          setAccount({
                            ...account,
                            userProfile: {
                              ...account.userProfile,
                              teacher: {
                                ...account.userProfile.teacher,
                                schoolId: value,
                              },
                            },
                          })
                        }
                      >
                        <Select.Item label="" value="" />
                        {schools?.map((school: ISchool) => (
                          <Select.Item
                            key={school?._id}
                            label={school?.nameEng}
                            value={school?._id}
                          />
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </HStack>
              )}

              <HStack alignItems={"center"} space={4}>
                <Text fontWeight={"500"}>
                  {t("System Admin Role Is Active")}
                </Text>
                <Switch
                  size="sm"
                  isChecked={account?.userProfile?.systemAdmin?.isActive}
                  onToggle={() =>
                    setAccount({
                      ...account,
                      userProfile: {
                        ...account.userProfile,
                        systemAdmin: {
                          ...account.userProfile.systemAdmin,
                          isActive:
                            !account?.userProfile?.systemAdmin?.isActive,
                        },
                      },
                    })
                  }
                />
              </HStack>

              <HStack alignItems={"center"} space={4}>
                <Text fontWeight={"500"}>
                  {t("Content Provider Role Is Active")}
                </Text>
                <Switch
                  size="sm"
                  isChecked={account?.userProfile?.contentProvider?.isActive}
                  onToggle={() =>
                    setAccount({
                      ...account,
                      userProfile: {
                        ...account.userProfile,
                        contentProvider: {
                          ...account.userProfile.contentProvider,
                          isActive:
                            !account?.userProfile?.contentProvider?.isActive,
                        },
                      },
                    })
                  }
                />
              </HStack>

              <Input
                pt={account.role ? "2" : "0"}
                isRequired
                isDisabled={props.status === "edit" ? true : false}
                variant="underlined"
                keyboardType="email-address"
                placeholder={`${t("Email")} *`}
                onChangeText={(value: string) =>
                  handleInputOnChange("email", value)
                }
                value={account.email}
                isInvalid={
                  emptyInputs.includes("email") ||
                  invalidInputs.includes("email")
                }
              />

              <Input
                isRequired={props.status === "create" ? true : false}
                type={isPasswordShown ? "text" : "password"}
                variant="underlined"
                w="100%"
                placeholder={`${t("Password")} ${
                  props.status === "create" ? "*" : ""
                }`}
                onChangeText={(value: string) =>
                  handleInputOnChange("password", value)
                }
                value={account.password}
                isInvalid={emptyInputs.includes("password")}
                InputRightElement={
                  <Button
                    colorScheme="light"
                    variant="ghost"
                    size="xs"
                    m="1"
                    onPress={() => setIsPasswordShown(!isPasswordShown)}
                  >
                    {isPasswordShown ? t("Hide") : t("Show")}
                  </Button>
                }
              />
            </Stack>

            {error && (
              <Alert w="100%" status="error">
                <VStack space={2} flexShrink={1} w="100%">
                  <HStack
                    flexShrink={1}
                    space={2}
                    justifyContent="space-between"
                  >
                    <HStack
                      w="full"
                      space={2}
                      flexShrink={1}
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <HStack space={2} flexShrink={1} alignItems="center">
                        <Alert.Icon />
                        <Text fontSize="sm" color="coolGray.800">
                          {error}
                        </Text>
                      </HStack>
                    </HStack>
                  </HStack>
                </VStack>
              </Alert>
            )}
          </>
        </Modal.Body>
        <Modal.Footer alignItems="center" justifyContent="center">
          <Button
            w="full"
            variant="subtle"
            onPress={handleRegister}
            isLoading={isLoading}
          >
            {props.status === "create" ? t("Create New User") : t("Edit User")}
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
};

export default UserModal;
