import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../redux/reducers';
import { Heading, Center, useToast, ScrollView } from 'native-base';
import { tableIcons } from '../MaterialTableIcons';
import MaterialTable, { Column } from 'material-table';
import { useQuery } from 'react-query';
import {
  getLearningUnits,
  getLearningObjectives,
  deleteLearningObjective,
  addLearningObjective,
  updateLearningObjective,
  getSubjects,
  getLearningUnitAreas,
  getLearningObjectivesIsPublic,
  getSubjectsIsPublic,
  getLearningUnitAreasIsPublic,
  getLearningUnitsIsPublic,
} from '../api';
import { ILearningObjective } from '../types/LearningObjectiveType';
import { useTranslation } from 'react-i18next';

const mapStateToProps = (state: RootState) => ({
  userObj: state.auth.user,
  currentRole: state.auth.currentRole,
});

const connector = connect(mapStateToProps);
type PropsType = ConnectedProps<typeof connector> & RouteComponentProps;

const LearningObjectiveManagementPage: React.FC<PropsType> = (
  props
) => {
  const toast = useToast();
  const { t, i18n } = useTranslation();

  const publicDataRole = ['systemAdmin', 'contentProvider'];

  const {
    data: learningObjectives,
    isLoading,
    isFetching,
    refetch,
  } = useQuery('learningObjectives', () => (!publicDataRole.includes(props.currentRole || '')  ? getLearningObjectives() : getLearningObjectivesIsPublic()), {
    refetchOnWindowFocus: false,
    onError: () => {
      toast.show({
        title: 'Something went wrong',
        status: 'error',
        description: 'Cannot fetch learning objective',
      });
    },
  });

  const {
    data: subjects,
    isLoading: isLoadingSubjects,
    refetch: refetchSubjects,
  } = useQuery('subject', () => (!publicDataRole.includes(props.currentRole || '')  ? getSubjects() : getSubjectsIsPublic()));

  const {
    data: learningUnitAreas,
    isLoading: isLoadingLearningUnitAreas,
    refetch: refetchLearningUnitAreas,
  } = useQuery('learningUnitArea', () => (!publicDataRole.includes(props.currentRole || '')  ? getLearningUnitAreas() : getLearningUnitAreasIsPublic()));


  const { data: learningUnits } = useQuery('learningUnit', () =>
    (!publicDataRole.includes(props.currentRole || '')  ? getLearningUnits() : getLearningUnitsIsPublic())
  );
  const [areaMap, setAreaMap] = useState<{ [key: string]: string }>();
  const [subjectMap, setSubjectMap] = useState<{ [key: string]: string }>();
  const [unitMap, setUnitMap] = useState<{ [key: string]: string }>();

  useEffect(() => {
    const _unitMap = learningUnits?.reduce<{ [key: string]: string }>(
      (map, obj) => {
        return { ...map, [obj._id]: i18n.language === 'zh' && obj.nameChi !== null ? obj.nameChi : obj.nameEng }
      },
      {}
    );
    setUnitMap(_unitMap);
  }, [learningUnits, i18n]);

  useEffect(() => {
    const _areaMap = learningUnitAreas?.reduce<{ [key: string]: string }>(
      (map, obj) => {
        return { ...map, [obj._id]: i18n.language === 'zh' && obj.nameChi !== null ? obj.nameChi : obj.nameEng }
      },
      {}
    );
    setAreaMap(_areaMap);
  }, [learningUnitAreas, i18n]);

  useEffect(() => {
    const _subjectMap = subjects?.reduce<{ [key: string]: string }>(
      (map, obj) => {
        return { ...map, [obj._id]: i18n.language === 'zh' && obj.nameChi !== null ? obj.nameChi : obj.nameEng }
      },
      {}
    );
    setSubjectMap(_subjectMap);
  }, [subjects, i18n]);

  useEffect(() => {
    setColumnObject([
      {
        title: 'ID',
        type: 'string',
        field: '_id',
        hidden: true,
      },
      {
        title: `${t('Name')}  (${t('Eng')})`,
        type: 'string',
        field: 'nameEng',
      },
      {
        title: `${t('Name')}  (${t('Chi')})`,
        type: 'string',
        field: 'nameChi',
      },
      {
        title: t('Subject'),
        type: 'string',
        field: 'learningUnit.subject._id',
        lookup: subjectMap,
        editable: 'never',
      },
      {
        title: t('Code'),
        type: 'string',
        field: 'code',
      },
      {
        title: t('Level'),
        type: 'string',
        field: 'learningUnit.level',
        editable: 'never',
      },
      {
        title: t('Learning Unit'),
        type: 'string',
        lookup: unitMap,
        field: 'learningUnit._id',
      },
      {
        title: t('Learning Unit Area'),
        type: 'string',
        field: 'learningUnit.area._id',
        lookup: areaMap,
        editable: 'never',
      },
      {
        title: t('Number'),
        type: 'string',
        field: 'number',
      },
      {
        title: t('Created By'),
        type: 'string',
        field: 'updatedAt',
        render: (rowData) => {
          return (i18n.language === 'zh' && rowData.createdBy?.lastNameChi !== null && rowData.createdBy?.firstNameChi !== null ? `${rowData.createdBy?.lastNameChi} ${rowData.createdBy?.firstNameChi}` : `${rowData.createdBy?.lastNameEng} ${rowData.createdBy?.firstNameEng}`);
        },
        editable: 'never',
      },
      {
        title: t('Updated By'),
        type: 'string',
        field: 'updatedAt',
        render: (rowData) => {
          return (rowData.updatedBy === undefined ? 
            (i18n.language === 'zh' && rowData.createdBy?.lastNameChi !== null && rowData.createdBy?.firstNameChi !== null ? `${rowData.createdBy?.lastNameChi} ${rowData.createdBy?.firstNameChi}` : `${rowData.createdBy?.lastNameEng} ${rowData.createdBy?.firstNameEng}`) : 
            (i18n.language === 'zh' && rowData.updatedBy?.lastNameChi !== null && rowData.updatedBy?.firstNameChi !== null ? `${rowData.updatedBy?.lastNameChi} ${rowData.updatedBy?.firstNameChi}` : `${rowData.updatedBy?.lastNameEng} ${rowData.updatedBy?.firstNameEng}`));
        },
        editable: 'never',
      },
      {
        title: t('Created Date'),
        type: 'datetime',
        field: 'createdAt',
        editable: 'never',
      },
      {
        title: t('Updated Date'),
        type: 'datetime',
        field: 'updatedAt',
        editable: 'never',
      },
    ]);
  }, [unitMap, areaMap, subjectMap]);

  //Need to define the columns in state, so that when filter is applied, and the data is refetched, the filter is kept
  //https://github.com/mbrn/material-table/issues/491#issuecomment-541011677
  const [columnObject, setColumnObject] = useState<
    Column<ILearningObjective>[]
  >([]);

  return (
    <ScrollView paddingTop={["35px", '0']}>
      <Heading>{t('Objective Management')}</Heading>
      <Center padding="10px">
        <MaterialTable
          icons={tableIcons}
          style={{ width: '100%' }}
          title={t('Learning Objective')}
          isLoading={isLoading || isFetching}
          editable={{
            onRowAdd: (props.currentRole !== 'contentProvider') ? async (newData) => {
              const newLearningObjective = {
                ...newData,
                learningUnitId: newData.learningUnit._id,
                isPublic: (props.currentRole === 'systemAdmin'),
              };
              await addLearningObjective(newLearningObjective);
              refetch();
            } : undefined,
            onRowUpdate: (props.currentRole !== 'contentProvider') ? async (newData, oldData) => {
              const newLearningObjective = {
                ...newData,
                learningUnitId: newData.learningUnit._id,
              };
              await updateLearningObjective(newLearningObjective);
              refetch();
            } : undefined,
            onRowDelete:  (props.currentRole !== 'contentProvider') ? async (oldData) => {
              await deleteLearningObjective(oldData._id);
              refetch();
            } : undefined,
          }}
          columns={columnObject}
          data={learningObjectives ?? []}
          options={{
            pageSize: 10,
            pageSizeOptions: [10, 25, 50],
            filtering: true,
            addRowPosition: 'first',
          }}
        />
      </Center>
    </ScrollView>
  );
};

export default withRouter(connector(LearningObjectiveManagementPage));
