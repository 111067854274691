import {
  Alert,
  Box,
  Button,
  Center,
  CheckIcon,
  Flex,
  FormControl,
  Heading,
  HStack,
  Input,
  Select,
  Stack,
  Text,
  VStack,
  Modal,
} from 'native-base';
import { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import 'react-day-picker/lib/style.css';
import 'moment/locale/it';
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  signInAnonymously,
  signOut,
} from 'firebase/auth';
import {
  auth,
  checkEmailFormat,
  generateAuthErrorMessage,
} from '../utils/authHelper';
import { useMutation, useQuery } from 'react-query';
import { createUserObj, getSchools } from '../api';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { ISchool } from '../types/SchoolTypes';
import ChangeLanguageButton from '../components/ChangeLanguageButton';
import BackButton from '../components/BackButton';
import login_island from '../images/login_island.png';

const RegisterPage: React.FC<RouteComponentProps> = (props) => {
  const { t } = useTranslation('translation');

  const [account, setAccount] = useState<any>({
    email: '',
    password: '',
    firstNameEng: '',
    lastNameEng: '',
    firstNameChi: '',
    lastNameChi: '',
    dateOfBirth: '',
    gender: '',
    role: '',
    schoolId: '',
    grade: '',
    yearOfEntry: '',
  });
  const [emptyInputs, setEmptyInputs] = useState<string[]>([]);
  const [invalidInputs, setInvalidInputs] = useState<string[]>([]);
  const [error, setError] = useState<string>('');
  const [isPasswordShown, setIsPasswordShown] = useState<boolean>(false);
  const [isModalShown, setIsModalShown] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isEnabled, setIsEnabled] = useState<boolean>(false);

  useEffect(() => {
    async function guestSignIn() {
      try {
        await signInAnonymously(auth);
        setIsEnabled(true);
      } catch (err) {
        console.log(err);
      }
    }
    guestSignIn();
  }, []);

  const mutation = useMutation(createUserObj, {
    onSettled: () => {
      setIsLoading(false);
    },
    onSuccess: () => {
      setIsModalShown(true);
    },
    onError: () => {
      setError('Cannot create user');
    },
  });

  const handleInputOnChange = (name: string, value: any) => {
    setAccount({ ...account, [name]: value });
  };

  const handleRegister = async () => {
    // check if inputs are empty first
    let emptyInputsTmp: string[] = [];
    Object.entries(account).forEach(([key, value]) => {
      if ((key === 'email' ||
        key === 'password' ||
        key === 'firstNameEng' ||
        key === 'lastNameEng' ||
        key === 'role'
      ) &&
        !value) {
        emptyInputsTmp.push(key);
      }
    });

    // validate inputs
    let invalidInputsTmp: string[] = [];
    if (account.dateOfBirth !== "") {
      const isDateValid = moment(
        account.dateOfBirth,
        'YYYY-MM-DD',
        true
      ).isValid();
      if (!isDateValid) {
        invalidInputsTmp.push('dateOfBirth');
      }
    }
    if (!checkEmailFormat(account.email)) {
      invalidInputsTmp.push('email');
    }

    if (account.role === 'contentProvider') {
      emptyInputsTmp.splice(emptyInputsTmp.indexOf('grade'), 1);
    }

    setEmptyInputs(emptyInputsTmp);
    setInvalidInputs(invalidInputsTmp);

    // firebase auth
    if (emptyInputsTmp.length === 0 && invalidInputsTmp.length === 0) {
      let formattedAccount = { ...account };
      if (account.role === 'student') {
        formattedAccount = {
          ...account,
          userProfile: {
            student: {
              schoolId: null,
              grade: Number(account.grade),
              isActive: true
            },
          },
        };
      }  else if (account.role === 'contentProvider') {
        formattedAccount = {
          ...account,
          userProfile: {
            contentProvider: {
              isActive: true
            },
          },
        };
      }
      try {
        setIsLoading(true);
        await signOut(auth);
        await createUserWithEmailAndPassword(
          auth,
          formattedAccount.email,
          formattedAccount.password
        );
        await sendEmailVerification(auth.currentUser!);
        // create userObj at db
        mutation.mutate(formattedAccount);
      } catch (error: any) {
        const errorMessage = generateAuthErrorMessage(error.code);
        setError(errorMessage);
        setIsLoading(false);
      }
    }
  };

  return (
    <div
      style={{
        backgroundImage: `url(${login_island})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >

      <Box
        bg="#FFF"
        p={[5, 5, 10]}
        rounded="2xl"
        height={['90%', '80%']}
        width={['90%', '80%']}
        justifyContent="center"
        overflow={'scroll'}
        paddingTop={['70px', '0px']}
      >

        <div style={{
          position: 'absolute',
          top: 20,
          left: 20,
          zIndex: 100,
        }}>
          <HStack space={5}>
            <BackButton />
            <ChangeLanguageButton />
          </HStack>
        </div>
        
          <>
            <Flex
              flexDirection="column"
              justifyContent="space-between"
              alignItems="center"
            >
              <Heading size={['lg', 'lg', 'xl']} textAlign="center">
                Welcome to Edventures!
              </Heading>
              <Text fontSize="lg" pb={3}>
                {t('Register an account')}
              </Text>
              <Box
                p={[5, 5, 8]}
                w="100%"
                bg="#F8F8F8"
                rounded="2xl"
                shadow={3}
                display="flex"
                justifyContent="center"
              >
                <Stack space={3} w="100%">
                  <HStack variant='responsive'>

                    <Input
                      variant="underlined"
                      w="100%"
                      placeholder={`${t('Last Name')} (${t('Eng')}) *`}
                      onChangeText={(value: string) =>
                        handleInputOnChange('lastNameEng', value)
                      }
                      value={account.lastNameEng}
                      isInvalid={emptyInputs.includes('lastNameEng')}
                    />
                  </HStack>

                  <Input
                    variant="underlined"
                    w="100%"
                    placeholder={`${t('First Name')} (${t('Eng')}) *`}
                    onChangeText={(value: string) =>
                      handleInputOnChange('firstNameEng', value)
                    }
                    value={account.firstNameEng}
                    marginRight="2"
                    isInvalid={emptyInputs.includes('firstNameEng')}
                  />

                  <HStack variant='responsive'>
                    <Input
                      variant="underlined"
                      w="100%"
                      placeholder={`${t('Last Name')} (${t('Chi')})`}
                      onChangeText={(value: string) =>
                        handleInputOnChange('lastNameChi', value)
                      }
                      value={account.lastNameChi}
                      isInvalid={emptyInputs.includes('lastNameChi')}
                    />
                    <Input
                      variant="underlined"
                      w="100%"
                      placeholder={`${t('First Name')} (${t('Chi')})`}
                      onChangeText={(value: string) =>
                        handleInputOnChange('firstNameChi', value)
                      }
                      value={account.firstNameChi}
                      marginRight="2"
                      isInvalid={emptyInputs.includes('firstNameChi')}
                    />

                  </HStack>

                  <HStack
                    justifyContent="space-between"
                    variant='responsive'
                  >
                    <Input
                      mr={2}
                      width="100%"
                      variant="underlined"
                      placeholder={`${t('Date of Birth')}`}
                      onChangeText={(value: string) =>
                        handleInputOnChange('dateOfBirth', value)
                      }
                      value={account.dateOfBirth}
                      paddingY="0"
                      isInvalid={
                        emptyInputs.includes('dateOfBirth') ||
                        invalidInputs.includes('dateOfBirth')
                      }
                    />

                    <FormControl
                      isRequired
                      width={['100%', '30%']}
                      isInvalid={emptyInputs.includes('role')}
                    >
                      <Select
                        _selectedItem={{
                          bg: 'teal.600',
                          endIcon: <CheckIcon size="5" />,
                        }}
                        mx={[0, 2]}
                        mt={[3, 0]}
                        selectedValue={account.role}
                        onValueChange={(value: string) =>
                          handleInputOnChange('role', value)
                        }
                        placeholder={`${t('Role')} *`}
                      >
                        <Select.Item label="" value="" />
                        <Select.Item label={t("Student")} value="student" />
                        <Select.Item label={t("Content Provider")} value="contentProvider" />
                      </Select>
                    </FormControl>
                    <FormControl
                      width={['100%', '30%']}
                      isRequired
                      isInvalid={emptyInputs.includes('gender')}
                    >
                      <Select
                        placeholder={`${t('Gender')}`}
                        _selectedItem={{
                          bg: 'teal.600',
                          endIcon: <CheckIcon size="5" />,
                        }}
                        mt={[2, 0]}
                        mr={0}
                        selectedValue={account.gender}
                        onValueChange={(value: string) =>
                          handleInputOnChange('gender', value)
                        }
                      >
                        <Select.Item label="" value="" />
                        <Select.Item label={t("Female")} value="F" />
                        <Select.Item label={t("Male")} value="M" />
                      </Select>
                    </FormControl>
                  </HStack>

                  {account.role === 'student' && (
                    <>
                      <FormControl
                        width={'100%'}
                        isRequired
                        isInvalid={emptyInputs.includes('grade')}
                      >
                        <Select
                          placeholder={t('Grade')}
                          _selectedItem={{
                            bg: 'teal.600',
                            endIcon: <CheckIcon size="5" />,
                          }}
                          my={[2, 0]}
                          mx={[0, 2]}
                          selectedValue={account.grade}
                          onValueChange={(value: string) =>
                            handleInputOnChange('grade', value)
                          }
                        >
                          <Select.Item label="" value="" />
                          {Array(6)
                            .fill(0)
                            .map((map, i) => i)
                            .map((grade) => (
                              <Select.Item
                                key={grade + 1}
                                label={String(grade + 1)}
                                value={String(grade + 1)}
                              />
                            ))}
                        </Select>
                      </FormControl>
                    </>
                  )}

                  <Input
                    pt={account.role ? '2' : '0'}
                    isRequired
                    variant="underlined"
                    keyboardType='email-address'
                    placeholder={`${t("Email")} *`}
                    onChangeText={(value: string) =>
                      handleInputOnChange('email', value)
                    }
                    value={account.email}
                    isInvalid={
                      emptyInputs.includes('email') ||
                      invalidInputs.includes('email')
                    }
                  />
                  <Input
                    isRequired
                    type={isPasswordShown ? 'text' : 'password'}
                    variant="underlined"
                    w="100%"
                    placeholder={`${t("Password")} *`}
                    onChangeText={(value: string) =>
                      handleInputOnChange('password', value)
                    }
                    value={account.password}
                    isInvalid={emptyInputs.includes('password')}
                    InputRightElement={
                      <Button
                        colorScheme="light"
                        variant="ghost"
                        size="xs"
                        m="1"
                        onPress={() => setIsPasswordShown(!isPasswordShown)}
                      >
                        {isPasswordShown ? t('Hide') : t('Show')}
                      </Button>
                    }
                  />

                  {error && (
                    <Alert w="100%" status="error">
                      <VStack space={2} flexShrink={1} w="100%">
                        <HStack
                          flexShrink={1}
                          space={2}
                          justifyContent="space-between"
                        >
                          <HStack space={2} flexShrink={1}>
                            <Alert.Icon mt="1" />
                            <Text fontSize="sm" color="coolGray.800">
                              {error}
                            </Text>
                          </HStack>
                        </HStack>
                      </VStack>
                    </Alert>
                  )}
                  <Button
                    size={['sm', 'sm', 'md']}
                    width="100%"
                    variant="solid"
                    borderRadius="full"
                    onPress={handleRegister}
                    isLoading={isLoading}
                  >
                    {t('Register')}
                  </Button>
                  <Button
                    size={['sm', 'sm', 'md']}
                    width="full"
                    variant="outline"
                    borderRadius="full"
                    onPress={() => props.history.push('/login')}
                  >
                    {t('Back to Login')}
                  </Button>
                </Stack>
              </Box>
            </Flex>
          </>
      </Box>

      {/* Modal */}
      <Modal isOpen={isModalShown} onClose={() => setIsModalShown(false)}>
        <Modal.Content maxWidth="md">
          <Modal.Header background="primary.500">
            {`${t("Sign up successfully")}!`}
          </Modal.Header>
          <Modal.Body textAlign="center">
            <Text fontSize="md">
              {t("A verification email has been sent to your mailbox. Please check and verify your account.")}
            </Text>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="ghost"
              onPress={() => {
                setIsModalShown(false);
                props.history.push('/login');
              }}
            >
              {t('Back to Login')}
            </Button>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
    </div>
  );
};

export default withRouter(RegisterPage);
