import { Box, Center } from 'native-base';
import { useTranslation } from 'react-i18next';
import MaterialTable from '@material-table/core';
import { tableIcons } from '../MaterialTableIcons';
import { useEffect, useState } from 'react';
import { IQuiz } from '../types/QuizTypes';
import { IUser } from '../types/AuthTypes';
import { ILearningUnit } from '../types/LearningUnitType';
import { ILearningUnitArea } from '../types/LearningUnitAreaType';
import { ILearningObjective } from '../types/LearningObjectiveType';
import { ExportCsv } from '@material-table/exporters';
import dayjs from 'dayjs';
import { useQuery } from 'react-query';
import { getLearningUnitAreas } from '../api';

interface TeacherSummaryHighestLearningUnitAreaTableProps {
  data: IQuiz[] | undefined;
}

interface IHighestLevel {
    learningUnit: ILearningUnit;
    learningUnitArea: ILearningUnitArea;
    learningObjective: ILearningObjective;
}

interface IHighestLearningUnitAreaSummaryTable {
    student: IUser;
    highestLevel: IHighestLevel[];
}
  
const TeacherSummaryHighestLearningUnitAreaTable: React.FC<TeacherSummaryHighestLearningUnitAreaTableProps> = (props) => {
  const { t, i18n } = useTranslation();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [highestLevel, setHighestLevel] = useState<IHighestLearningUnitAreaSummaryTable[]>([]);
  const [displayedArea, setDisplayedArea] = useState<ILearningUnitArea[]>([]);
  const [cols, setCols] = useState<any[]>([]);

  const {
    data: learningUnitAreas,
  } = useQuery('learningUnitArea', () => getLearningUnitAreas(), {
    onSuccess: (data) => {
      setDisplayedArea(data);
    },
  });

  useEffect(() => {
    setIsLoading(true);
    let temp: IHighestLearningUnitAreaSummaryTable[] = [];
    props.data?.forEach((quiz) => {
      if (!temp.some(data => data.student._id === quiz?.user?._id)) {
        temp = [
          ...temp,
          {
            student: quiz?.user,
            highestLevel: [{
              learningUnit: quiz?.learningUnit,
              learningUnitArea: quiz?.learningUnitArea,
              learningObjective: quiz?.learningObjective,
            }]
          }
        ]
      } else {
        let target = temp.find((data) => data.student._id === quiz?.user?._id);
        if (target !== undefined) {
          if (!target.highestLevel.some(data => data.learningUnitArea._id === quiz?.learningUnitArea._id)) {
            target.highestLevel = [
              ...target.highestLevel,
              {
                learningUnit: quiz?.learningUnit,
                learningUnitArea: quiz?.learningUnitArea,
                learningObjective: quiz?.learningObjective,
              }
            ]
          } else {
            let area = target.highestLevel.find(data => data.learningUnitArea._id === quiz?.learningUnitArea._id);
            if (area !== undefined) {
              if (area.learningUnit.level < quiz?.learningUnit.level) {
                area.learningUnit = quiz?.learningUnit;
                area.learningObjective = quiz?.learningObjective;
              } else if (area.learningUnit.level === quiz?.learningUnit.level && 
                area.learningUnit.number < quiz?.learningUnit.number) {
                  area.learningUnit = quiz?.learningUnit;
                  area.learningObjective = quiz?.learningObjective;
              } else if (area.learningUnit.level === quiz?.learningUnit.level && 
                area.learningUnit.number === quiz?.learningUnit.number && 
                area.learningObjective.number < quiz?.learningObjective.number) {
                  area.learningUnit = quiz?.learningUnit;
                  area.learningObjective = quiz?.learningObjective;
              }
            }
          }
        }
      }
    })

    // Sort by class and number 
    temp.sort((a: any, b: any) => {
      let classA = a?.student?.userProfile?.student?.class;
      let classB = b?.student?.userProfile?.student?.class;
      if (classA < classB){
        return -1;
      } else if (classA > classB){
        return 1;
      } else {
        let numA = a?.student?.userProfile?.student?.number;
        let numB = b?.student?.userProfile?.student?.number;
        if (numA < numB) {
          return -1;
        } else if (numA > numB) {
          return 1;
        }
      }
      return 0;
    })

    setHighestLevel(temp);
    setIsLoading(false);
  }, [props]);

  useEffect(() => {
    setIsLoading(true);
    let cols: any = [
      {
        title: t('Class'),
        type: 'string',
        field: 'student.userProfile.student.class',
      },
      {
        title: t('Student Number'),
        type: 'numeric',
        field: 'student.userProfile.student.number',
        cellStyle: {
          textAlign: "left"
        }
      },
      {
        title: `${t('Last Name')} (${t('Eng')})`,
        type: 'string',
        editPlaceholder: `${t('Last Name')}  (${t('Eng')}) *`,
        field: 'student.lastNameEng',
      },
      {
        title: `${t('First Name')} (${t('Eng')})`,
        type: 'string',
        editPlaceholder: `${t('First Name')}  (${t('Eng')}) *`,
        field: 'student.firstNameEng',
      },
      {
        title: `${t('Last Name')} (${t('Chi')})`,
        type: 'string',
        field: 'student.lastNameChi',
      },
      {
        title: `${t('First Name')} (${t('Chi')})`,
        type: 'string',
        field: 'student.firstNameChi',
      },
    ];
    displayedArea.forEach((area) => {
      cols.push({
        title: i18n.language === 'zh' && area.nameChi !== null ? area.nameChi : area.nameEng,
        type: 'string',
        field: 'highestLevel',
        render: (rowData: IHighestLearningUnitAreaSummaryTable) => {
          return rowData?.highestLevel?.find((data) => data?.learningUnitArea?._id === area?._id)?.learningObjective?.code ?? "";
        },
        customFilterAndSearch: (term: string, rowData: IHighestLearningUnitAreaSummaryTable) => {
          return ((rowData.highestLevel.find((data) => data.learningUnitArea._id === area._id)?.learningObjective?.code ?? "").toLowerCase().search(term.toLowerCase()) !== -1)
        }
      })
    })
    setCols(cols);
    setIsLoading(false);
  }, [displayedArea])

  return (
    <Box>
      <Center padding="10px">
        <MaterialTable
          style={{ width: '100%' }}
          title={t('Highest number of levels')}
          icons={tableIcons}
          isLoading={isLoading}
          columns={cols}
          data={highestLevel ?? []}
          options={{
            pageSize: 10,
            pageSizeOptions: [10, 25, 50],
            filtering: true,
            exportAllData: true,
            exportMenu: [
              {
                label: t('Export Summary'),
                exportFunc: (cols: any, data: any) => {
                  for (const [i, value] of cols.entries()) {
                    if (i >= 6) {
                      value.field = value.title;
                    }
                  }
                  data.forEach((i: any) => {
                    displayedArea.forEach((area) => {
                      i[(i18n.language === 'zh' && area.nameChi !== null ? area.nameChi : area.nameEng)] = 
                        i.highestLevel.find((level: any) => level.learningUnitArea._id === area._id)?.learningObjective.code ?? "";
                    })
                    return i;
                  })
                  return ExportCsv(cols, data, `Highest number of levels Summary Export ${dayjs().format('YYYYMMDDHHmmss')}`);
                },
              },
            ],
          }}
        />
      </Center>
    </Box>
  )
}

export default TeacherSummaryHighestLearningUnitAreaTable;
