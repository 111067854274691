import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import '../reactquill.css'; // override the snow css class to fix a ui bug
import ImageResize from 'quill-image-resize-module-react';

Quill.register('modules/imageResize', ImageResize);

const modules = {
  imageResize: {
    parchment: Quill.import('parchment'),
    modules: ['Resize', 'DisplaySize'],
  },
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
    ],
    ['link', 'image'],
  ],
};
interface RichTextEditorProps {
  value: string;
  onChange: Function;
}

const RichTextEditor: React.FC<RichTextEditorProps> = (props) => {
  return (
    <ReactQuill
      style={{ width: '100%' }}
      theme="snow"
      className="ql-container"
      value={props.value}
      onChange={(value: string) => props.onChange(value)}
      modules={modules}
    />
  );
};

export default RichTextEditor;
