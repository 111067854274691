import {
  Center,
  Flex,
  Heading,
  Spinner,
  Text,
  useToast,
  useTheme,
  Button,
  VStack,
} from 'native-base';
import { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, useLocation, withRouter } from 'react-router';
import { getQuizLearningUnitsByStudentAndSubject, getSubjectById } from '../api';
import { RootState } from '../redux/reducers';
import QuizProgressButton from '../components/QuizProgressButton';
import { ISubject } from '../types/SubjectTypes';
import { IQuizLearningUnit } from '../types/QuizTypes';
import ReactFlow, {
  Elements,
  Handle,
  Position,
  Controls,
  useZoomPanHelper,
  useStoreState,
  useStore,
  ArrowHeadType,
} from 'react-flow-renderer';
import water_map from '../images/water_map.png';
import island_1 from '../images/islands/island_1_with_objects.png';
import island_2 from '../images/islands/island_2_with_objects.png';
import island_3 from '../images/islands/island_3_with_objects.png';
import island_4 from '../images/islands/island_4_with_objects.png';
import island_5 from '../images/islands/island_5_with_objects.png';
import island_6 from '../images/islands/island_6_with_objects.png';
import island_7 from '../images/islands/island_7_with_objects.png';
import island_8 from '../images/islands/island_8_with_objects.png';
import { useQueryParams } from '../utils/queryHelper';
import { useTranslation } from 'react-i18next';
import dash from '../images/dash.png'

const islands = [
  island_1,
  island_1,
  island_2,
  island_3,
  island_4,
  island_5,
  island_6,
  island_7,
  island_8,
];

const xDelta = 400;
const yDelta = 400;

const mapStateToProps = (state: RootState) => ({
  userObj: state.auth.user,
});

const connector = connect(mapStateToProps);
type PropsType = ConnectedProps<typeof connector> & RouteComponentProps;

const QuizLearningUnitPage: React.FC<PropsType> = (props) => {

  const { fitView } = useZoomPanHelper();

  const toast = useToast();
  const query = useQueryParams();
  const { t, i18n } = useTranslation()

  const subjectId = props.location.pathname.split('/')[2];

  const { colors } = useTheme();
  const [selectedLevel, setSelectedLevel] = useState<number | undefined>();

  const [availableLevels, setAvailableLevels] = useState<number[]>([])
  const [flowElements, setFlowElements] = useState<Elements<any>>([]);

  const { data: subject, isLoading: isSubjectLoading } = useQuery(['subject', subjectId], () => getSubjectById(subjectId));

  const { data: learningUnits, isLoading: isLearningUnitLoading } = useQuery(['quizLearningUnits', subjectId], () => getQuizLearningUnitsByStudentAndSubject(subjectId));

  useEffect(() => {
    const queryLevel = query.get('level');
    if (queryLevel !== null && !isNaN(Number(queryLevel))) {
      setSelectedLevel(Number(queryLevel))
    }
  }, [])

  useEffect(() => {
    if (learningUnits) {
      setAvailableLevels(learningUnits.map(learningUnit => learningUnit.level).filter((value, index, self) => self.indexOf(value) === index))
    }
  }, [learningUnits])



  useEffect(() => {
    if (selectedLevel !== undefined) {
      placeNode(selectedLevel)
      props.history.push(
        `/subjects/${subjectId}/learning-unit?level=${selectedLevel}`
      );
    } else {
      placeNode()
      props.history.push(
        `/subjects/${subjectId}/learning-unit`
      );
    }
  }, [learningUnits, selectedLevel]);

  const fitViewPadding = 0.4;

  useEffect(() => {
    if (flowElements.length > 0) {
      setTimeout(() => {
        fitView({ padding: fitViewPadding })
      }, 50)
    }
  }, [flowElements])



  const findIfOverlap = (x: number, y: number, _flowElements: Elements) => {
    return _flowElements
      .filter(
        (e: any) => e.data && e.data.type === 'learningUnit' && e.position
      )
      .filter((e: any) => x - e.position.x < 300 && y - e.position.y < 100)
      .length > 0
      ? true
      : false;
  };

  const positionAdjustments = [
    { x: 50, y: 200 },
    { x: 100, y: 100 },
    { x: 30, y: 150 },
    { x: 50, y: 270 },
    { x: 70, y: 195 },
    { x: 90, y: 230 },
  ];

  let y = 0;
  const placeNode = (level?: number) => {
    setFlowElements([])
    if (learningUnits && learningUnits.length > 0) {

      let _flowElements: Elements<any> = [];


      //Generate successor based on prerequisites
      const learningUnitWithSuccessors = learningUnits.map(
        (learningUnit, index, arr) => {
          const successors: IQuizLearningUnit[] = [];
          const otherLus = arr.filter((a) => a._id !== learningUnit._id)
          otherLus.forEach((otherLu, i) => {
            const found = otherLu.prerequisites.find(
              (p) => p._id === learningUnit._id
            );
            if (found) {
              successors.push(otherLu);
            }
          });

          return {
            ...learningUnit,
            successors: successors,
          };
        }
      );
      let maxY = 0
      let uniqueLevels = learningUnitWithSuccessors.map(x => x.level).filter((value, index, self) => self.indexOf(value) === index)
      if (level !== undefined) {
        uniqueLevels = uniqueLevels.filter(x => x === level)
      }
      uniqueLevels.forEach(selectedLevel => {
        let xInitial = 0;
        let yInitial = maxY + yDelta;
        let x = xInitial;


        const leveledLearningUnits = learningUnitWithSuccessors.filter(
          (x) => x.level === selectedLevel
        );



        //Algorithm to generate node
        const learningUnitWithSuccessorsMutable = [...leveledLearningUnits]; //first to clone mutatble array
        let i = 0;
        while (learningUnitWithSuccessorsMutable.length > 0) {
          let learningUnit = learningUnitWithSuccessorsMutable[0]; //always place the first element

          const prerequisites = learningUnit.prerequisites.filter(
            (p) => p.level === selectedLevel
          );
          if (prerequisites.length === 0) {
            //The very first node with no prerequisites
            x = x + xDelta;
            y = yInitial;
          } else {
            const prerequisitesIds = prerequisites.map((p) => p._id);

            //Get prerequisites to see if they are already placed
            const placedPrerequisties = _flowElements.filter((e) =>
              prerequisitesIds.includes(e.id)
            );

            if (placedPrerequisties.length === prerequisitesIds.length) {
              //All prerequisites are placed
              const placedXs = placedPrerequisties.map(
                (p) => (p as any).position.x
              ) as number[];
              const placedYs = placedPrerequisties.map(
                (p) => (p as any).position.y
              ) as number[];
              x = placedXs.reduce((a, b) => a + b) / placedPrerequisties.length;
              y = Math.max(...placedYs) + yDelta;

              while (findIfOverlap(x, y, _flowElements)) {
                x += xDelta;
              }
            } else {
              //If not all prerequisites are placed, shift the prerequisite to the first and place it next
              const notPlacedPrerequisties = prerequisitesIds.filter(
                (pId) => !_flowElements.map((e) => e.id).includes(pId)
              );
              const prerequisitesIndex = learningUnitWithSuccessorsMutable
                .map((e) => e._id)
                .indexOf(notPlacedPrerequisties[0]);
              const picked = learningUnitWithSuccessorsMutable.splice(
                prerequisitesIndex,
                1
              )[0];
              learningUnitWithSuccessorsMutable.unshift(picked);
              continue;
            }
          }

          //Place node
          x = x + positionAdjustments[i % positionAdjustments.length].x;
          y = y + positionAdjustments[i % positionAdjustments.length].y;
          while (findIfOverlap(x, y, _flowElements)) {
            x += 200;
          }
          _flowElements.push({
            id: learningUnit._id,
            type: 'special',
            position: {
              x: x,
              y: y,
            },
            data: { ...learningUnit, type: 'learningUnit' },
          });
          if (y > maxY) {
            maxY = y
          }
          i = i + 1;

          learningUnitWithSuccessorsMutable.shift();

          if (learningUnit.successors.length > 0) {
            //Place edge between current node and successors
            const successorIndex = learningUnit.successors
              .map((s) => {
                return learningUnitWithSuccessorsMutable
                  .map((e) => e._id)
                  .indexOf(s._id);
              })
              .filter((x: any) => x !== -1)
              .reverse();
            //Also shift the successor to the very first place so it place it next
            successorIndex.reverse().forEach((sIndex) => {
              const picked = learningUnitWithSuccessorsMutable.splice(
                sIndex,
                1
              )[0];
              learningUnitWithSuccessorsMutable.unshift(picked);
            })
          }
        }
      })


      learningUnitWithSuccessors.forEach(learningUnit => {

        if (learningUnit.successors.length > 0) {
          //Place edge between current node and successors
          learningUnit.successors
            .forEach((s) => {
              //Place edge
              _flowElements.push({
                id: `${learningUnit._id}-${s._id}`,
                source: learningUnit._id,
                target: s._id,
                animated: true,
                arrowHeadType: ArrowHeadType.ArrowClosed,
                style: { stroke: '#388fe0', strokeWidth: 6, strokeDasharray: 6 },
              });
            })
        }
      })

      setFlowElements(_flowElements);
    }
  }




  const CustomNodeComponent = ({ data }: any) => {
    return (
      <>
        <div style={{ padding: 0 }} className="nodrag">
          {data.type === 'learningUnit' && (
            <Handle
              type="target"
              position={Position.Top}
              style={{
                borderRadius: 10,
                marginBottom: 0,
                backgroundColor: 'transparent',
              }}
            />
          )}
          <div
            style={
              data.type === 'learningUnit'
                ? {
                  backgroundImage: `url(${islands[data.number]})`,
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                  backgroundPositionX: 'center',
                  height: 300,
                  width: 350,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }
                : {}
            }
          >
            <>
              {data.type === 'learningUnit' && (
                <QuizProgressButton
                  type="learningUnit"
                  key={data._id}
                  {...data}
                  onPress={() => {
                    props.history.push(
                      `/subjects/${subjectId}/learning-unit/${data._id}/learning-objective`,
                      data,
                    );
                  }}
                />
              )}
            </>
          </div>
          {data.type === 'learningUnit' && (
            <Handle
              type="source"
              position={Position.Bottom}
              id="b"
              style={{
                borderRadius: 10,
                marginTop: 0,
                backgroundColor: 'transparent',
              }}
            />
          )}
        </div>
      </>
    );
  };

  return (
    <>
      <div
        style={{
          width: '100vw',
          height: '100vh',
          backgroundImage: `url(${water_map})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: '100% 100%',
        }}
      >
        <Center w="full" h="full">
          {isLearningUnitLoading ? (
            <Spinner size="lg" />
          ) : (
            <>
              {!isSubjectLoading && subject !== undefined &&
                <Heading
                  size="md"
                  textAlign="center"
                  style={{ position: 'absolute', top: 20 }}
                >
                  {i18n.language === 'zh' && subject.nameChi !== '' ? subject.nameChi : subject.nameEng}
                </Heading>
              }
              {learningUnits && learningUnits.length === 0 ? (
                <Center>
                  <Flex justifyContent="center" alignItems="center">
                    <Text>{t('No learning units')}</Text>
                  </Flex>
                </Center>
              ) : (
                <>
                  {selectedLevel &&
                    <Heading
                      size="md"
                      textAlign="center"
                      bgColor={'white'}
                      style={{ position: 'absolute', top: 50 }}
                    >
                      {i18n.language === 'zh' ? `${selectedLevel}年級` : `Primary ${selectedLevel}`}
                    </Heading>
                  }
                  {/* <div style={{ width: '100vw', height: '100vh', backgroundImage: `url(${water_map})`, backgroundRepeat: 'no-repeat', backgroundSize: '100% 100%', }} > */}
                  <div style={{ width: '100vw', height: '100vh' }}>
                    <VStack
                      space={2}
                      style={{
                        position: 'absolute',
                        left: 20,
                        top: 100,
                        zIndex: 1000,
                      }}>
                      <Button
                        onPress={() => {
                          if (selectedLevel === undefined) {
                            fitView({ padding: fitViewPadding })
                          }
                          setSelectedLevel(undefined)
                        }}
                      >
                        {i18n.language === 'zh' ? `顯示全部` : `Show All`}
                      </Button>
                      {availableLevels.map(level => (
                        <Button
                          key={level}
                          onPress={() => {
                            if (selectedLevel === level) {
                              fitView({ padding: fitViewPadding })
                            }
                            setSelectedLevel(level)
                          }}
                        >
                          {i18n.language === 'zh' ? `${level}年級` : `Primary ${level}`}
                        </Button>
                      ))}
                    </VStack>
                    {
                      flowElements &&
                      <ReactFlow elements={flowElements}
                        minZoom={0}
                        maxZoom={3}
                        nodeTypes={{ special: CustomNodeComponent }}
                        arrowHeadColor={'#388fe0'}
                      >
                        <Controls
                          style={{
                            bottom: 100,
                          }}
                        />
                      </ReactFlow>
                    }
                  </div >
                </>
              )}
            </>
          )}
        </Center >
      </div >
    </>
  );
};

export default withRouter(connector(QuizLearningUnitPage));
