import axios, { Axios, AxiosError, AxiosResponse } from "axios";
import queryString from "query-string";

// For use together with useInfiniteQuery, https://www.sitepoint.com/react-query-fetch-manage-data/
export const parseLinkHeader = (linkHeader: string) => {
  const linkHeadersArray = linkHeader
    .split(", ")
    .map((header) => header.split("; "));
  const linkHeadersMap = linkHeadersArray.map((header) => {
    const thisHeaderRel = header[1].replace(/"/g, "").replace("rel=", "");
    const thisHeaderUrl = header[0].slice(1, -1);
    return [thisHeaderRel, thisHeaderUrl];
  });
  return Object.fromEntries(linkHeadersMap);
};

export const getPageParam = (
  param: "next" | "prev" | "first" | "last",
  lastPage: AxiosResponse<any, any> | undefined
) => {
  if (lastPage) {
    // The following code block is specific to json-server api
    if (lastPage.headers.link === undefined) {
      return undefined;
    }
    const nextPageUrl = parseLinkHeader(lastPage.headers.link)[param];
    if (nextPageUrl) {
      const queryStringSearch = nextPageUrl.substring(
        nextPageUrl.indexOf("?"),
        nextPageUrl.length
      );
      const parsed = queryString.parse(queryStringSearch);
      return parsed["page"];
    } else {
      return undefined;
    }
  } else {
    return undefined;
  }
};
