import React, { useEffect, useState } from 'react';
import { useToast, Button, Modal } from 'native-base';
import { tableIcons } from '../MaterialTableIcons';
import MaterialTable, { Column } from 'material-table';
import { useMutation, useQueries } from 'react-query';
import {
  bulkCreateQuestion,
  getLearningObjectives,
  getLearningUnits,
  getSubjects,
} from '../api';
import { Edit } from '@material-ui/icons';
import QuestionManagementModal from '../components/QuestionManagementModal';
import { INewQuestion, IQuestion } from '../types/QuestionTypes';
import { createMarkup } from '../utils/htmlHelper';
import * as XLSX from 'xlsx';
import { useTranslation } from 'react-i18next';
interface CsvPreviewModalProps {
  currentRole: string | null;
  file: File | undefined;
  onClose: () => void;
  onSubmitSuccess: (status: string) => void;
}

const CsvPreviewModal: React.FC<CsvPreviewModalProps> = (props) => {
  const toast = useToast();
  const { t, i18n } = useTranslation('translation');

  const [questions, setQuestions] = useState<any[]>([]);
  const [columnObject, setColumnObject] = useState<Column<any>[]>([]);
  const [selectedQuestion, setSelectedQuestion] = useState<any>();
  const [isModalShown, setIsModalShown] = useState<boolean>(false);
  const [modalStatus, setModalStatus] = useState<string>('');
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [
    { data: subjects, isFetched: isSubjectsFetched },
    { data: learningUnits, isFetched: isLearningUnitsFetched },
    { data: learningObjectives, isFetched: isLearningObjectivesFetched },
  ] = useQueries([
    {
      queryKey: ['subjects'],
      queryFn: getSubjects,
      refetchOnWindowFocus: false,
    },
    {
      queryKey: ['learningUnits'],
      queryFn: () => getLearningUnits(),
      refetchOnWindowFocus: false,
    },
    {
      queryKey: ['learningObjectives'],
      queryFn: () => getLearningObjectives(),
      refetchOnWindowFocus: false,
    },
  ]);

  const importMutation = useMutation(bulkCreateQuestion, {
    onSuccess: (data) => {
      props.onSubmitSuccess('import');
      props.onClose();
    },
    onError: (error: any) => {
      toast.show({
        title: 'Failed to import questions',
        status: 'error',
        description: JSON.stringify(error.response),
      });
    },
  });

  useEffect(() => {
    setIsLoading(true);
    setColumnObject([
      {
        title: t('Subject'),
        type: 'string',
        field: i18n.language === 'zh' ? 'subject.nameChi' : 'subject.nameEng',
      },
      {
        title: t('Code'),
        type: 'string',
        field: 'learningUnit.code',
      },
      {
        title: t('Level'),
        type: 'string',
        field: 'learningUnit.level',
      },
      {
        title: t('Learning Unit Number'),
        type: 'string',
        field: 'learningUnit.number',
      },
      {
        title: t('Learning Unit'),
        type: 'string',
        field: 'learningUnit.nameEng',
      },
      {
        title: t('Learning Unit Area'),
        type: 'string',
        field: 'learningUnit.area._id',
        lookup: learningUnits?.reduce((map, obj) => {
          if (obj.area) {
            return {
              ...map,
              [obj.area._id]:
                i18n.language === 'zh' && obj.area.nameChi !== null
                  ? obj.area.nameChi
                  : obj.area.nameEng,
            };
          } else {
            return map;
          }
        }, {}),
      },
      {
        title: t('Learning Objective'),
        type: 'string',
        field: 'learningObjective.nameEng',
      },
      {
        title: t('Question Text'),
        type: 'string',
        field: 'questionText',
        render: (rowData: any) => (
          <div
            style={{ fontSize: 16 }}
            dangerouslySetInnerHTML={createMarkup(rowData.questionText)}
          />
        ),
      },
      {
        title: t('Question Type'),
        type: 'string',
        field: 'questionType',
      },
      {
        title: t('Options'),
        type: 'string',
        field: 'questionOptions',
        render: (rowData: any) => (
          <div
            style={{ fontSize: 16 }}
            dangerouslySetInnerHTML={createMarkup(
              [...rowData.questionOptions].join(', ')
            )}
          />
        ),
      },
      {
        title: t('Correct Answer'),
        type: 'string',
        field: 'correctAnswer',
        render: (rowData: any) => (
          <div
            style={{ fontSize: 16 }}
            dangerouslySetInnerHTML={createMarkup(rowData.correctAnswer)}
          />
        ),
      },
      {
        title: t('Level'),
        type: 'string',
        field: 'level',
      },
      {
        title: t('Visible to student'),
        type: 'boolean',
        field: 'isActive',
      },
    ]);
  }, [subjects, learningUnits, learningObjectives]);

  const getType = (typeNumber: number) => {
    switch (typeNumber) {
      case 0:
        return 'multipleChoice';
      case 1:
        return 'freeText';
      case 2:
        return 'fillInTheBlank';
      default:
        return '';
    }
  };

  const getLevel = (levelCode: string) => {
    switch (levelCode) {
      case 'B':
        return 'easy';
      case 'M':
        return 'middle';
      case 'H':
        return 'high';
      default:
        return '';
    }
  };

  const parseFile = () => {
    return new Promise<any[]>((resolve) => {
      const reader = new FileReader();
      if (props.file) {
        reader.readAsArrayBuffer(props.file);
        reader.onload = async (e: any) => {
          const bufferArray = e.target.result;
          const sheets = XLSX.read(bufferArray, { type: 'buffer' });
          const questionWs = sheets.Sheets[sheets.SheetNames[0]];
          const answerWs = sheets.Sheets[sheets.SheetNames[1]];
          const questions = XLSX.utils.sheet_to_json(questionWs, {
            defval: '',
          });
          const answers = XLSX.utils.sheet_to_json(answerWs, { defval: '' });

          const filteredQuestion = questions.filter((question: any) => {
            return (
              question.title !== 'NULL' &&
              question.title !== '' &&
              question.questionType !== 'NULL' &&
              question.questionType !== '' &&
              question.subject_name_eng !== 'NULL' &&
              question.subject_name_eng !== '' &&
              question.learning_unit_code !== 'NULL' &&
              question.learning_unit_code !== '' &&
              question.learning_objective_name_eng !== 'NULL' &&
              question.learning_objective_name_eng !== ''
            );
          });
          const formattedQuestions = filteredQuestion.map(
            (question: any, index) => {
              let options: any = [];
              let correctAnswer: any = '';
              if (question.type === 0) {
                options = answers.filter(
                  (answer: any) => answer.question_id === question.id
                );
                correctAnswer = options.find(
                  (opt: any) => opt.answer_marks > 0
                );
              } else {
                correctAnswer = answers.find(
                  (answer: any) => answer.question_id === question.id
                );
              }

              return {
                questionOptions: options?.map((option: any) =>
                  option.answer_text.toString()
                    ? option.answer_text.toString()
                    : option.answer_image
                ),
                correctAnswer: correctAnswer?.answer_text.toString()
                  ? correctAnswer?.answer_text
                  : correctAnswer?.answer_image,
                deleted: false,
                questionText: question.title,
                questionType: getType(question.type),
                subject: {
                  ...subjects?.find(
                    (subject) => subject.nameEng === question.subject_name_eng
                  ),
                },
                learningUnit: {
                  ...learningUnits?.find(
                    (unit) => unit.code === question.learning_unit_code
                  ),
                },
                learningObjective: {
                  ...learningObjectives?.find(
                    (objective) =>
                      objective.code === question.learning_objective_code
                  ),
                },
                level: getLevel(question.level_code),
                isActive: question.visible_to_student,
                isPublic: (props.currentRole === 'contentProvider'),
              };
            }
          );
          resolve(formattedQuestions);
        };
      } else {
        resolve([]);
      }
    });
  };

  useEffect(() => {
    async function parseFilePromise() {
      if (
        isSubjectsFetched &&
        isLearningUnitsFetched &&
        isLearningObjectivesFetched
      ) {
        const formattedQuestions = await parseFile();
        setQuestions(formattedQuestions);
        setIsLoading(false);
      }
    }
    parseFilePromise();
  }, [isSubjectsFetched, isLearningUnitsFetched, isLearningObjectivesFetched]);

  const handleEditOnClick = (rowData: any) => {
    setSelectedIndex(rowData.tableData.id);
    // need to reformat rowData to match the format in question management modal
    const formattedData = {
      ...rowData,
      subjectId: rowData.subject._id,
      learningUnitId: rowData.learningUnit._id,
      learningObjectiveId: rowData.learningObjective._id,
    };
    setSelectedQuestion(formattedData);
    setModalStatus('edit csv');
    setIsModalShown(true);
  };

  const handleModalOnClose = () => {
    setSelectedIndex(-1);
    setSelectedQuestion(undefined);
    setIsModalShown(false);
  };

  const handleQuestionOnEdit = (newQuestion: any) => {
    // since question management modal only use ids
    // need to retrieve the obj using the ids to display in preview modal
    const formattedQuestion = {
      ...newQuestion,
      subject: {
        ...subjects?.find((subject) => subject._id === newQuestion.subjectId),
      },
      learningUnit: {
        ...learningUnits?.find(
          (unit) => unit._id === newQuestion.learningUnitId
        ),
      },
      learningObjective: {
        ...learningObjectives?.find(
          (objective) => objective._id === newQuestion.learningObjectiveId
        ),
      },
    };

    const newQuestions = [...questions];
    newQuestions[selectedIndex] = formattedQuestion;
    setQuestions(newQuestions);
  };

  const handleQuestionOnDelete = (index: number) => {
    const newQuestions = [...questions];
    newQuestions.splice(index, 1);
    setQuestions(newQuestions);
  };

  const handleSubmit = () => {
    const questionsRequest = [...questions].map(
      ({ subject, learningUnit, learningObjective, ...rest }) => ({
        ...rest,
        subjectId: subject._id,
        learningUnitId: learningUnit._id,
        learningObjectiveId: learningObjective._id,
        learningUnitAreaId: learningUnit.area ? learningUnit.area._id : null,
      })
    );
    importMutation.mutate(questionsRequest);
  };

  return (
    <>
      <Modal
        isOpen
        onClose={props.onClose}
        size="full"
        closeOnOverlayClick={false}
        _backdrop={{ height: '100%' }}
      >
        <Modal.Content height="100%" maxHeight="100%">
          <Modal.CloseButton />
          <Modal.Header background="primary.500" textTransform="capitalize">
            {t('Preview')}
          </Modal.Header>
          <Modal.Body minHeight="md">
            <MaterialTable
              icons={tableIcons}
              style={{ width: '100%' }}
              title={t('Questions to be Imported')}
              isLoading={importMutation.isLoading || isLoading}
              columns={columnObject}
              data={questions}
              options={{
                pageSize: 10,
                pageSizeOptions: [10, 25, 50],
                filtering: true,
              }}
              actions={[
                {
                  icon: () => <Edit />,
                  tooltip: 'Edit Details',
                  onClick: (event, rowData) => handleEditOnClick(rowData),
                },
              ]}
              editable={{
                onRowDelete: async (oldData) => {
                  handleQuestionOnDelete(oldData.tableData.id);
                },
              }}
            />
          </Modal.Body>
          <Modal.Footer alignItems="center" justifyContent="center">
            <Button
              w="full"
              variant="subtle"
              onPress={handleSubmit}
              isLoading={importMutation.isLoading}
            >
              {t('Submit')}
            </Button>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
      <QuestionManagementModal
        isModalShown={isModalShown}
        status={modalStatus}
        question={selectedQuestion}
        currentRole={props.currentRole}
        onClose={handleModalOnClose}
        onEditClick={() => setModalStatus('edit csv')}
        onEditCsv={handleQuestionOnEdit}
      />
    </>
  );
};

export default CsvPreviewModal;
