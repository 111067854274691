import { Box, Center } from 'native-base';
import { useTranslation } from 'react-i18next';
import MaterialTable from '@material-table/core';
import { tableIcons } from '../MaterialTableIcons';
import { useEffect, useState } from 'react';
import { IQuiz } from '../types/QuizTypes';
import { IUser } from '../types/AuthTypes';
import { ExportCsv } from '@material-table/exporters';
import dayjs from 'dayjs';

interface TeacherSummaryClassTableProps {
  data: IQuiz[] | undefined;
}

interface IClassSummaryTable {
  class: string;
  numOfStudent: number;
}
  
const TeacherSummaryClassTable: React.FC<TeacherSummaryClassTableProps> = (props) => {
  const { t, i18n } = useTranslation();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [classData, setClassData] = useState<IClassSummaryTable[]>([]);

  useEffect(() => {
    setIsLoading(true);
    let students: IUser[] = [];
    let result: IClassSummaryTable[] = [];
    props.data?.forEach((quiz) => {
      if (!students.some(student => student._id === quiz?.user?._id)) {
        students = [...students, quiz?.user]
      }
    })

    students.forEach((student) => {
      if (!result.some((data) => data.class === student?.userProfile?.student?.class)) {
        result = [...result, {class: student?.userProfile?.student?.class ?? "", numOfStudent: 1}]
      } else {
        let target = result.find((data) => data.class === student?.userProfile?.student?.class);
        if (target !== undefined) {
          target.numOfStudent++;
        }
      }
    })

    setClassData(result);
    setIsLoading(false);
  }, [props]);

  return (
    <Box>
      <Center padding="10px">
        <MaterialTable
          style={{ width: '100%' }}
          title={t('Class')}
          icons={tableIcons}
          isLoading={isLoading}
          columns={[
            {
              title: t('Class'),
              type: 'string',
              field: 'class',
            },
            {
              title: t('Number of Students'),
              type: 'string',
              field: 'numOfStudent',
            },
          ]}
          data={classData ?? []}
          options={{
            pageSize: 10,
            pageSizeOptions: [10, 25, 50],
            filtering: true,
            exportAllData: true,
            exportMenu: [
              {
                label: t('Export Summary'),
                exportFunc: (cols, data) => {
                  return ExportCsv(cols, data, `Class Summary Export ${dayjs().format('YYYYMMDDHHmmss')}`);
                },
              },
            ],
          }}
        />
      </Center>
    </Box>
  )
}

export default TeacherSummaryClassTable;
