import { useEffect, useState } from 'react';
import { Center, useToast, Text, Heading, Spinner, Pressable, Box, Flex, VStack } from 'native-base';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useHistory } from "react-router-dom";
import { ILearningMaterial } from '../../types/LearningMaterialType';

import { getLearningMaterialsByStudentRole } from '../../api/LearningMaterial';
import brown_map from '../../images/brown_map.png'
import choose_subject_background from '../../images/choose_subject_background.png'

import LearningMaterialModel from './LearningMaterialModel';
import Select from 'react-select';
import { getSubjectsByStudentRole, getLearningUnitsByStudentRole, getLearningObjectivesByStudentRole } from '../../api';
import qs from "qs";

const selectStyles = {
  container: (base: any, state: any) => ({
    ...base,
    margin: '0 0.5rem',
    width: '100%',
    zIndex: '999',
  }),
};

const LearningMaterialPage: React.FC = (props) => {
  const toast = useToast();
  const { t, i18n } = useTranslation()
  const history = useHistory()

  const [selectedLearningMaterial, setSelectedLearningMaterial] = useState<ILearningMaterial>();
  const [isModalShown, setIsModalShown] = useState<boolean>(false);
  const [filterSubjects, setFilterSubjects] = useState<any>([])
  const [filterLearningUnits, setFilterLearningUnits] = useState<any>([])
  const [filterLearningObjectives, setFilterLearningObjectives] = useState<any>([])
  const [learningMaterialsDisplay, setLearningMaterialsDisplay] = useState<ILearningMaterial[]>([]);
  const [queryLearningObjective, setQueryLearningObjective] = useState<string | undefined>();

  const { data: learningMaterials, isLoading, isFetching } = useQuery('learningMaterials', () => getLearningMaterialsByStudentRole(), {
    refetchOnWindowFocus: false,
    onError: () => {
      toast.show({
        title: 'Something went wrong',
        status: 'error',
        description: 'Cannot fetch learning materials',
      });
    },
  });

  useEffect(() => {
    if (learningMaterials) {
      let filtered = learningMaterials
      if (filterSubjects.length > 0) {
        const filterSubjectIds = filterSubjects.map((x: any) => x._id)
        filtered = filtered.filter(learningMaterial => {
          return filterSubjectIds.includes(learningMaterial.learningObjective.learningUnit.subject._id)
        })
      }
      if (filterLearningUnits.length > 0) {
        const filterLearningUnitIds = filterLearningUnits.map((x: any) => x._id)
        filtered = filtered.filter(learningMaterial => {
          return filterLearningUnitIds.includes(learningMaterial.learningObjective.learningUnit._id)
        })
      }
      if (filterLearningObjectives.length > 0) {
        const filterLearningObjectiveIds = filterLearningObjectives.map((x: any) => x._id)
        filtered = filtered.filter(learningMaterial => {
          return filterLearningObjectiveIds.includes(learningMaterial.learningObjective._id)
        })
      }
      console.log(filtered)
      setLearningMaterialsDisplay(filtered)
      console.log(filterLearningObjectives)
    }
  }, [learningMaterials, filterLearningUnits, filterSubjects, filterLearningObjectives])



  const handleModalOnClose = () => {
    setSelectedLearningMaterial(undefined);
    setIsModalShown(false);
  };

  const onLearningMaterialPress = (learningMaterial: ILearningMaterial) => {
    setIsModalShown(true);
    setSelectedLearningMaterial(learningMaterial);
  }

  const { data: subjects } = useQuery('subjects', () => getSubjectsByStudentRole(), {
    refetchOnWindowFocus: false,
  });

  const { data: learningUnits } = useQuery('learningUnits', () => getLearningUnitsByStudentRole(), {
    refetchOnWindowFocus: false,
  });

  const { data: learningObjectives, isLoading: isLoadingLearningObjectives } = useQuery('learningObjectives', () => getLearningObjectivesByStudentRole(), {
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    const learningObjectiveId = qs.parse(history.location.search, { ignoreQueryPrefix: true }).learningObjectiveId as string
    setQueryLearningObjective(learningObjectiveId)
    if (learningObjectiveId !== undefined) {
      if (learningObjectives && learningObjectives.filter(x => x._id === learningObjectiveId).length > 0) {
        const learningObjective = learningObjectives.filter(x => x._id === learningObjectiveId)[0]
        setFilterLearningObjectives([
          {
            ...learningObjective,
            label: `${learningObjective.code} - ${i18n.language === 'zh' && learningObjective.nameChi !== null ? learningObjective.nameChi : learningObjective.nameEng}`,
            value: learningObjective._id,
          }
        ])
      }
    }
  }, [history, learningObjectives])


  return (
    <>

      <div style={{
        backgroundImage: `url(${choose_subject_background})`,
        height: '100%',
        width: '100%',
        overflow: 'scroll',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
      }}>
        {isLoading || (queryLearningObjective !== undefined && isLoadingLearningObjectives) || isFetching ? (
          <Center flex={1} px="3" w="full" h="full">
            <Spinner size="lg" />
          </Center>
        ) : (
          <Box h='full' paddingTop={'50px'}>
            <Heading size="md" textAlign="center" w='full'>
              {t('Learning Material')}
            </Heading>
            <Center px="3" w="full" marginTop={10}>
              {learningMaterials?.length === 0 ? (
                <Text>{t('No learning materials')}</Text>
              ) : (
                <Flex w={['100%', '700']} alignItems={'center'}>
                  <VStack w={['100%']} space={2} marginBottom={6}>
                    <Select
                      placeholder={t('Choose Subject')}
                      styles={selectStyles}
                      menuPortalTarget={document.body}
                      isMulti
                      value={filterSubjects}
                      onChange={(newValue) => setFilterSubjects(newValue)}
                      options={subjects?.map((subject) => ({
                        ...subject,
                        label: `${i18n.language === 'zh' && subject.nameChi !== null ? subject.nameChi : subject.nameEng
                          }`,
                        value: subject._id,
                      }))}
                    />

                    <Select
                      placeholder={t('Choose Learning Unit')}
                      styles={selectStyles}
                      menuPortalTarget={document.body}
                      isMulti
                      value={filterLearningUnits}
                      onChange={(newValue) => setFilterLearningUnits(newValue)}
                      options={learningUnits?.map((learningUnit) => ({
                        ...learningUnit,
                        label: `${learningUnit.code} - ${i18n.language === 'zh' && learningUnit.nameChi !== null ? learningUnit.nameChi : learningUnit.nameEng
                          }`,
                        value: learningUnit._id,
                      }))}
                    />

                    <Select
                      placeholder={t('Choose Learning Objective')}
                      styles={selectStyles}
                      menuPortalTarget={document.body}
                      isMulti
                      value={filterLearningObjectives}
                      onChange={(newValue) => setFilterLearningObjectives(newValue)}
                      options={learningObjectives?.map((learningObjective) => ({
                        ...learningObjective,
                        label: `${learningObjective.code} - ${i18n.language === 'zh' && learningObjective.nameChi !== null ? learningObjective.nameChi : learningObjective.nameEng}`,
                        value: learningObjective._id,
                      }))}
                    />
                  </VStack>

                  {learningMaterialsDisplay.length === 0 && (
                    <Text>{t('No learning materials')}</Text>
                  )}

                  {learningMaterialsDisplay
                    ?.filter((learningMaterial) => learningMaterial.isActive)
                    .map((learningMaterial) => (
                      <Pressable
                        key={learningMaterial._id}
                        onPress={() => onLearningMaterialPress(learningMaterial)}
                      >
                        <div style={{
                          backgroundImage: `url(${brown_map})`,
                          backgroundRepeat: 'no-repeat',
                          backgroundSize: 'contain',
                          width: 350,
                          height: 200,
                          maxWidth: '100%',
                        }}
                        >
                          <Center w='full' h='full'>
                            <Text
                              fontWeight={'700'}
                              fontSize={'20px'}
                              color='primary.900'
                              textAlign={'center'}
                            >
                              {i18n.language === 'zh' ? learningMaterial.learningObjective.learningUnit.subject.nameChi : learningMaterial.learningObjective.learningUnit.subject.nameEng}
                            </Text>
                            <Text
                              fontWeight={'700'}
                              fontSize={'20px'}
                              color='primary.900'
                              textAlign={'center'}
                            >
                              {learningMaterial.learningObjective.code} {i18n.language === 'zh' ? learningMaterial.learningObjective.nameChi : learningMaterial.learningObjective.nameEng}
                            </Text>
                            <Text
                              fontWeight={'700'}
                              fontSize={'20px'}
                              color='primary.900'
                              textAlign={'center'}
                            >
                              {learningMaterial.name}
                            </Text>
                          </Center>
                        </div>
                      </Pressable>
                    ))}
                </Flex>
              )}
            </Center>
          </Box>
        )}
      </div>

      <LearningMaterialModel
        isModalShown={isModalShown}
        learningMaterial={selectedLearningMaterial}
        onClose={handleModalOnClose}
      />
    </>
  );
};

export default LearningMaterialPage;
