import { useEffect, useState } from 'react'
import { ILearningMaterial } from '../../types/LearningMaterialType';
import {
  Button,
  CheckIcon,
  FormControl,
  HStack,
  Input,
  Modal,
  Select,
  Spinner,
  Text,
  VStack,
  Alert,
  WarningOutlineIcon,
  Switch,
  Center,
  Heading,
  Icon,
  useTheme,
} from 'native-base';
import { useTranslation } from 'react-i18next';
import LearningMaterialContentSegment from '../../components/LearningMaterialContentSegment';
import { useQuery } from 'react-query';
import { getQuizLearningUnitsByStudentAndSubject, getSubjectById } from '../../api';
import { useHistory } from 'react-router';
interface LearningMaterialModelProps {
  isModalShown: boolean
  learningMaterial: ILearningMaterial | undefined;
  onClose: () => void;
}

const LearningMaterialModel: React.FC<LearningMaterialModelProps> = (props) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const [canGoToQuiz, setCanGoToQuiz] = useState(false);

  const {
    data: quizLearningUnits,
  } = useQuery(
    ['quizLearningUnits', props.learningMaterial?.learningObjective.learningUnit.subject._id],
    () => getQuizLearningUnitsByStudentAndSubject(props.learningMaterial?.learningObjective.learningUnit.subject._id ?? ''),
    {
      enabled: props.learningMaterial !== undefined
    }
  );
  useEffect(() => {
    if (quizLearningUnits && props.learningMaterial) {
      const learningObjectiveId = props.learningMaterial.learningObjective._id
      const learningUnitId = props.learningMaterial.learningObjective.learningUnit._id
      const filteredLearningUnit = quizLearningUnits.find(x => x._id === learningUnitId)
      if (filteredLearningUnit) {
        const filteredLearningObjectives = filteredLearningUnit.learningObjectives
        if (filteredLearningObjectives.filter(x => x._id === learningObjectiveId && x.isUnlocked === true).length > 0) {
          setCanGoToQuiz(true)
        }
      }
    }
  }, [quizLearningUnits, props.learningMaterial])

  return (
    <Modal isOpen={props.isModalShown} onClose={props.onClose} size="full" closeOnOverlayClick={false} _backdrop={{ height: '100%' }}>
      <Modal.Content height="100%" maxHeight="100%">
        <Modal.CloseButton />
        <Modal.Header background="primary.500" textTransform="capitalize">
          {t('Learning Material')}
        </Modal.Header>
        <Modal.Body minHeight="md" p="3">
          {props.learningMaterial && (
            <>
              <Heading>{props.learningMaterial.name}</Heading>
              <LearningMaterialContentSegment learningMaterial={props.learningMaterial} />
            </>
          )}
        </Modal.Body>
        {canGoToQuiz && (

          <Modal.Footer>
            <Button
              width={'100%'}
              onPress={() => history.push(
                `/subjects/${props.learningMaterial?.learningObjective.learningUnit.subject._id}/learning-unit/${props.learningMaterial?.learningObjective.learningUnit._id}/learning-objective`)
              }>{t('Start the quiz')}</Button>
          </Modal.Footer>
        )}
      </Modal.Content>
    </Modal >
  )

}
export default LearningMaterialModel;