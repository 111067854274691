import { FC, useEffect, useState } from "react";
import {
  Button,
  Center,
  CheckIcon,
  Flex,
  FormControl,
  HStack,
  Input,
  Modal,
  Radio,
  Select,
  Spinner,
  Text,
  VStack,
  Alert,
  WarningOutlineIcon,
  TextArea,
  Switch,
  Checkbox,
} from "native-base";
import { IoAdd, IoRemove } from "react-icons/io5";
import { useMutation, useQueries, useQuery } from "react-query";
import {
  createQuestion,
  getLearningObjectives,
  getLearningObjectivesIsPublic,
  getLearningUnits,
  getLearningUnitsIsPublic,
  getSubjects,
  getSubjectsIsPublic,
  updateQuestion,
} from "../api";
import { ISubject } from "../types/SubjectTypes";
import Edit from "@material-ui/icons/Edit";
import { ILearningUnit } from "../types/LearningUnitType";
import { ILearningObjective } from "../types/LearningObjectiveType";
import RichTextEditor from "./RichTextEditor";
import { createMarkup } from "../utils/htmlHelper";
import { INewQuestion, IQuestion } from "../types/QuestionTypes";
import { useTranslation } from "react-i18next";
interface QuestionManagementModalProps {
  isModalShown: boolean;
  status: string;
  question: any;
  currentRole: string | null;
  onClose: () => void;
  onEditClick: () => void;
  onSubmitSuccess?: (status: string, question?: IQuestion) => void;
  onEditCsv?: (question: INewQuestion) => void;
}

const QuestionManagementModal: FC<QuestionManagementModalProps> = (props) => {
  const { t, i18n } = useTranslation();

  const [optionNumber, setOptionNumber] = useState<string>("0");
  const [question, setQuestion] = useState<INewQuestion>(props.question);
  const [emptyInputs, setEmptyInputs] = useState<string[]>([]);
  const [error, setError] = useState<string>("");
  const [titleError, setTitleError] = useState<string>("");
  const [optionError, setOptionError] = useState<string[]>([]);
  const [isEmpty, setIsEmpty] = useState({
    learningUnits: false,
    learningObjectives: false,
  });

  const {
    data: subjects,
    isLoading: isSubjectLoading,
    isFetching: isSubjectFetching,
  } = useQuery(
    "subjects",
    () =>
      props.currentRole !== "contentProvider"
        ? getSubjects()
        : getSubjectsIsPublic(),
    {
      refetchOnWindowFocus: false,
    }
  );
  const {
    data: learningUnits,
    isLoading: isLearningUnitLoading,
    isFetching: isLearningUnitFetching,
  } = useQuery(
    ["learningUnits", question?.subjectId],
    () =>
      props.currentRole !== "contentProvider"
        ? getLearningUnits(question?.subjectId)
        : getLearningUnitsIsPublic(question?.subjectId),
    {
      enabled: question !== undefined && question.subjectId !== "",
      refetchOnWindowFocus: false,
    }
  );
  const {
    data: learningObjectives,
    isLoading: isLearningObjectiveLoading,
    isFetching: isLearningObjectiveFetching,
  } = useQuery(
    ["learningObjectives", question?.learningUnitId],
    () =>
      props.currentRole !== "contentProvider"
        ? getLearningObjectives(question?.learningUnitId)
        : getLearningObjectivesIsPublic(question?.learningUnitId),
    {
      enabled: question !== undefined && question.learningUnitId !== "",
      refetchOnWindowFocus: false,
    }
  );

  const createMutation = useMutation(createQuestion, {
    onSuccess: (data) => {
      if (props.onSubmitSuccess) {
        setQuestion({
          questionText: "",
          questionType: "",
          subjectId: "",
          learningUnitId: "",
          learningObjectiveId: "",
          learningUnitAreaId: "",
          questionOptions: [],
          correctAnswer: "",
          level: "",
          isActive: true,
          deleted: false,
        });
        props.onSubmitSuccess("create", data);
        props.onClose();
      }
    },
    onError: (err: any) => {
      setError(err.message);
    },
  });

  const updateMutation = useMutation(updateQuestion, {
    onSuccess: (data) => {
      if (props.onSubmitSuccess) {
        props.onSubmitSuccess("edit", data);
        props.onClose();
      }
    },
    onError: (err: any) => {
      setError(err.message);
    },
  });

  useEffect(() => {
    if (props.question && props.status !== "edit csv") {
      // only mc ans is rich text -> convert all pure text to rich text to prevent ui bug for mc
      const answer =
        props.question.questionType === "multipleChoice"
          ? convertPureTextToHtml(props.question.correctAnswer)
          : props.question.correctAnswer;
      // prevent unnecessary attributes begin passed as request body and consistency concern
      setQuestion({
        _id: props.question._id,
        questionOptions: props.question.questionOptions.map((option: string) =>
          convertPureTextToHtml(option)
        ),
        correctAnswer: answer,
        deleted: props.question.deleted,
        questionText: convertPureTextToHtml(props.question.questionText),
        questionType: props.question.questionType,
        subjectId: props.question.subject._id,
        learningUnitAreaId: props.question.learningUnitArea._id,
        learningObjectiveId: props.question.learningObjective._id,
        level: props.question.level,
        learningUnitId: props.question.learningUnit._id,
        isActive: props.question.isActive,
        isPublic: props.currentRole === "contentProvider",
      });
      setOptionNumber(String(props.question.questionOptions.length));
    } else {
      const answer =
        props.question?.questionType === "multipleChoice"
          ? convertPureTextToHtml(props.question.correctAnswer)
          : props.question?.correctAnswer ?? "";
      setQuestion({
        questionOptions: props.question
          ? props.question.questionOptions.map((option: string) =>
              convertPureTextToHtml(option)
            )
          : [],
        correctAnswer: answer,
        deleted: false,
        questionText: props.question?.questionText
          ? convertPureTextToHtml(props.question.questionText)
          : "",
        questionType: props.question?.questionType ?? "",
        subjectId: props.question?.subject._id ?? "",
        learningUnitAreaId: props.question?.learningUnit.area?._id ?? "",
        learningObjectiveId: props.question?.learningObjective._id ?? "",
        level: props.question?.level ?? "",
        learningUnitId: props.question?.learningUnit._id ?? "",
        isActive: props.question?.isActive ?? true,
        isPublic: props.currentRole === "contentProvider",
      });
      setOptionNumber(
        props.question?.questionOptions.length
          ? String(props.question.questionOptions.length)
          : "0"
      );
    }
  }, [props.question, props.status]);

  useEffect(() => {
    if (learningUnits && learningObjectives) {
      const isLearningUnitRelevant = learningUnits.some(
        (learningUnit) => learningUnit._id === question?.learningUnitId
      );
      const isLearningObjectiveRelevant = learningObjectives.some(
        (learningObjective) =>
          learningObjective._id === question?.learningObjectiveId
      );
      let updatedIsEmpty = { ...isEmpty };
      if (learningUnits.length > 0 && learningObjectives.length > 0) {
        if (isLearningUnitRelevant) {
          if (isLearningObjectiveRelevant) {
            updatedIsEmpty = {
              learningUnits: false,
              learningObjectives: false,
            };
          } else {
            updatedIsEmpty = { learningUnits: false, learningObjectives: true };
          }
        } else if (!isLearningUnitRelevant) {
          updatedIsEmpty = { learningUnits: true, learningObjectives: true };
        }
      }
      setIsEmpty(updatedIsEmpty);
    }
  }, [
    learningUnits,
    learningObjectives,
    question?.subjectId,
    question?.learningUnitId,
    question?.learningObjectiveId,
  ]);

  const handleInputOnChange = (name: string, value: string) => {
    if (name === "subjectId") {
      // clear original learning unit id and learning objective id
      setQuestion({
        ...question!,
        [name]: value,
        learningUnitId: "",
        learningObjectiveId: "",
      });
    } else if (name === "learningUnitId") {
      // clear original learning objective id and create learning unit area id
      if (learningUnits) {
        const area = learningUnits.find(
          (learningUnit) => learningUnit._id === value
        )?.area;
        setQuestion({
          ...question!,
          [name]: value,
          learningUnitAreaId: area!._id,
          learningObjectiveId: "",
        });
      }
    } else {
      setQuestion({ ...question!, [name]: value });
    }
  };

  const handleOptionNumberOnChange = (value: string) => {
    if (question?.questionOptions) {
      let options = [...question.questionOptions];
      if (value) {
        const newNumber = Number(value);
        const length = question.questionOptions.length;
        if (newNumber > length) {
          options = options.concat(Array(newNumber - length).fill(""));
        } else if (newNumber < length) {
          options.splice(-(length - newNumber), length - newNumber);
        }
      }
      setQuestion({ ...question!, questionOptions: options });
      setOptionNumber(value);
    }
  };

  const handleOptionOnChange = (index: number, value: string) => {
    if (question?.questionOptions) {
      setQuestion((prev) => {
        let updatedQuestion = { ...prev };
        if (updatedQuestion.questionOptions) {
          if (
            updatedQuestion.questionOptions[index] === question?.correctAnswer
          ) {
            updatedQuestion.correctAnswer = "";
          }
          updatedQuestion.questionOptions[index] = value;
        }
        return updatedQuestion;
      });
    }
  };

  const isQuillEmpty = (value: string) => {
    return (
      value.replace(/<(.|\n)*?>/g, "").trim().length === 0 &&
      !value.includes("<img")
    );
  };

  const handleSubmit = () => {
    // check if inputs are empty first
    let emptyInputsTmp: string[] = [];
    let optionErrorTmp: string[] = [];

    Object.entries(question!).forEach(([key, value]) => {
      if (
        !value &&
        key !== "questionOptions" &&
        key !== "deleted" &&
        key !== "questionText" &&
        key !== "correctAnswer" &&
        key !== "isActive" &&
        key !== "isPublic"
      ) {
        emptyInputsTmp.push(key);
      }
      // check isEmpty for rich text
      if (
        (key === "questionText" || key === "correctAnswer") &&
        isQuillEmpty(String(value))
      ) {
        emptyInputsTmp.push(key);
      }
    });
    if (
      question?.questionType === "multipleChoice" &&
      question.questionOptions
    ) {
      // if options length < 2
      if (question.questionOptions.length < 2) {
        optionErrorTmp.push("Should have at least 2 options");
      } // if no correct ans
      else if (emptyInputsTmp.includes("correctAnswer")) {
        optionErrorTmp.push("Please select a correct answer");
      }

      // if empty option exists
      if (question.questionOptions.indexOf("") !== -1) {
        emptyInputsTmp.push("questionOptions");
        optionErrorTmp.push("Empty option is not allowed");
      }
      // if duplicated options exist
      const optionSet = new Set(question.questionOptions);
      if (optionSet.size !== question.questionOptions.length) {
        optionErrorTmp.push("Duplicated options are not allowed");
      }
    }
    if (optionErrorTmp.length > 0) {
      emptyInputsTmp.push("questionOptions");
    }
    setOptionError(optionErrorTmp);
    setEmptyInputs(emptyInputsTmp);

    if (
      question?.questionType === "fillInTheBlank" &&
      !emptyInputsTmp.includes("questionText") &&
      !question?.questionText.includes("___")
    ) {
      setTitleError("Missing underscores");
    } else if (optionErrorTmp.length === 0 && emptyInputsTmp.length === 0) {
      setTitleError("");
      if (props.status === "edit csv" && question && props.onEditCsv) {
        props.onEditCsv(question);
        props.onClose();
      } else {
        let mutation;
        if (props.status === "create") {
          mutation = createMutation;
        } else if (props.status === "edit") {
          mutation = updateMutation;
        }
        mutation?.mutate(question);
      }
    }
  };

  const convertPureTextToHtml = (value: string) => {
    if (!/<\/?[a-z][\s\S]*>/i.test(value)) {
      return `<p>${value}</p>`;
    } else {
      return value;
    }
  };

  useEffect(() => {
    console.log(
      props.question,
      isSubjectFetching,
      isLearningUnitFetching,
      isLearningObjectiveFetching
    );
  }, []);

  const onCorrectAnswerCheckboxChange = (
    isSelected: boolean,
    index: number
  ) => {
    if (isSelected) {
      setQuestion((prev) => ({
        ...prev,
        correctAnswer: prev.questionOptions[index],
      }));
    } else {
      setQuestion((prev) => ({ ...prev, correctAnswer: "" }));
    }
  };

  return (
    <Modal
      isOpen={props.isModalShown}
      onClose={props.onClose}
      size="full"
      closeOnOverlayClick={false}
      _backdrop={{ height: "100%" }}
    >
      <Modal.Content height="100%" maxHeight="100%">
        <Modal.CloseButton />
        <Modal.Header background="primary.500" textTransform="capitalize">
          <HStack>
            {props.status === "view" && t("View Question")}
            {props.status === "create" && t("Create New Question")}
            {props.status === "edit" && t("Edit Question")}
            {props.status === "edit csv" && t("Edit Imported Question")}
            {props.status === "view" && (
              <Button
                ml="2"
                variant="unstyled"
                p="0"
                onPress={props.onEditClick}
              >
                <Edit style={{ width: 20, height: 20 }} />
              </Button>
            )}
          </HStack>
        </Modal.Header>
        <Modal.Body minHeight="md" p="3">
          {props.question &&
          (isSubjectFetching ||
            isLearningUnitFetching ||
            isLearningObjectiveFetching) ? (
            <Center flex={1} px="3" w="full" h="full">
              <Spinner size="lg" />
            </Center>
          ) : (
            <>
              {question?._id && (
                <Text bold>
                  {t("Question")} ID: <Text>{question?._id}</Text>
                </Text>
              )}
              <Flex
                flexDirection={["column", "column", "row"]}
                alignItems="center"
                justifyContent={["center", "center", "space-between"]}
              >
                {/* subject */}
                <FormControl
                  isRequired={props.status !== "view" || isSubjectLoading}
                  w={["100%", "100%", "33%"]}
                  isInvalid={emptyInputs.includes("subjectId")}
                  my="2"
                >
                  <FormControl.Label>{t("Subject")}</FormControl.Label>
                  <Select
                    isDisabled={props.status === "view"}
                    w="100%"
                    _selectedItem={{
                      bg: "teal.600",
                      endIcon: <CheckIcon size="5" />,
                    }}
                    selectedValue={question?.subjectId}
                    onValueChange={(value: string) =>
                      handleInputOnChange("subjectId", value)
                    }
                    placeholder={t("Choose Subject")}
                  >
                    {subjects ? (
                      subjects.map((subject) => (
                        <Select.Item
                          key={subject._id}
                          label={
                            i18n.language === "zh"
                              ? subject.nameChi
                              : subject.nameEng
                          }
                          value={subject._id}
                        />
                      ))
                    ) : (
                      <></>
                    )}
                  </Select>
                </FormControl>

                {/* learning unit code */}
                <FormControl
                  isRequired={props.status !== "view"}
                  w={["100%", "100%", "33%"]}
                  isInvalid={emptyInputs.includes("learningUnitId")}
                  my="2"
                >
                  <HStack alignItems="center">
                    <FormControl.Label>{t("Learning Unit")}</FormControl.Label>
                    {isLearningUnitLoading && (
                      <Spinner accessibilityLabel="Loading learning unit code" />
                    )}
                  </HStack>
                  <Select
                    isDisabled={
                      props.status === "view" ||
                      !question?.subjectId ||
                      isLearningUnitLoading
                    }
                    w="100%"
                    _selectedItem={{
                      bg: "teal.600",
                      endIcon: <CheckIcon size="5" />,
                    }}
                    selectedValue={question?.learningUnitId}
                    onValueChange={(value: string) =>
                      handleInputOnChange("learningUnitId", value)
                    }
                    placeholder={t("Choose Learning Unit")}
                  >
                    {learningUnits ? (
                      learningUnits.map((learningUnit) => (
                        <Select.Item
                          key={learningUnit._id}
                          label={`${learningUnit.code} ${
                            i18n.language === "zh" &&
                            learningUnit.nameChi !== null
                              ? learningUnit.nameChi
                              : learningUnit.nameEng
                          }`}
                          value={learningUnit._id}
                        />
                      ))
                    ) : (
                      <></>
                    )}
                    <Select.Item key="empty" label="" value="" />
                  </Select>
                </FormControl>

                {/* learning objective code */}
                <FormControl
                  isRequired={props.status !== "view"}
                  w={["100%", "100%", "33%"]}
                  isInvalid={emptyInputs.includes("learningObjectiveId")}
                  my="2"
                >
                  <HStack alignItems="center">
                    <FormControl.Label>
                      {t("Learning Objective")}
                    </FormControl.Label>
                    {isLearningObjectiveLoading && (
                      <Spinner accessibilityLabel="Loading learning objective code" />
                    )}
                  </HStack>
                  <Select
                    isDisabled={
                      props.status === "view" ||
                      !question?.learningUnitId ||
                      isLearningObjectiveLoading
                    }
                    w="100%"
                    _selectedItem={{
                      bg: "teal.600",
                      endIcon: <CheckIcon size="5" />,
                    }}
                    selectedValue={question?.learningObjectiveId}
                    onValueChange={(value: string) =>
                      handleInputOnChange("learningObjectiveId", value)
                    }
                    placeholder={t("Choose Learning Objective")}
                  >
                    {learningObjectives ? (
                      learningObjectives.map((learningObjective) => (
                        <Select.Item
                          key={learningObjective._id}
                          label={`${learningObjective.code} ${
                            i18n.language === "zh" &&
                            learningObjective.nameChi !== null
                              ? learningObjective.nameChi
                              : learningObjective.nameEng
                          }`}
                          value={learningObjective._id}
                        />
                      ))
                    ) : (
                      <></>
                    )}
                    <Select.Item key="empty" label="" value="" />
                  </Select>
                </FormControl>
              </Flex>
              <Flex
                flexDirection={["column", "column", "row"]}
                justifyContent={["center", "center", "space-between"]}
              >
                {/* question type */}
                <FormControl
                  isRequired={props.status !== "view"}
                  w={["100%", "100%", "49.8%"]}
                  isInvalid={emptyInputs.includes("questionType")}
                  my="2"
                >
                  <FormControl.Label>{t("Question Type")}</FormControl.Label>
                  <Select
                    isDisabled={props.status === "view"}
                    _selectedItem={{
                      bg: "teal.600",
                      endIcon: <CheckIcon size="5" />,
                    }}
                    selectedValue={question?.questionType}
                    onValueChange={(value: string) =>
                      handleInputOnChange("questionType", value)
                    }
                    placeholder={t("Choose Question Type")}
                  >
                    <Select.Item
                      label={t("Multiple Choice")}
                      value="multipleChoice"
                    />
                    <Select.Item label={t("Free Text")} value="freeText" />
                    <Select.Item
                      label={t("Fill In The Blank")}
                      value="fillInTheBlank"
                    />
                  </Select>
                </FormControl>

                {/* level */}
                <FormControl
                  isRequired={props.status !== "view"}
                  w={["100%", "100%", "49.8%"]}
                  isInvalid={emptyInputs.includes("level")}
                  my="2"
                >
                  <FormControl.Label>{t("Level")}</FormControl.Label>
                  <Select
                    isDisabled={props.status === "view"}
                    w="100%"
                    _selectedItem={{
                      bg: "teal.600",
                      endIcon: <CheckIcon size="5" />,
                    }}
                    selectedValue={question?.level}
                    onValueChange={(value: string) =>
                      handleInputOnChange("level", value)
                    }
                    placeholder={t("Choose Level")}
                  >
                    <Select.Item label={t("Easy")} value="easy" />
                    <Select.Item label={t("Middle")} value="middle" />
                    <Select.Item label={t("High")} value="high" />
                  </Select>
                </FormControl>
              </Flex>
              <HStack alignItems={"center"} space={4}>
                <Text fontWeight={"500"}>{t("Visible to student")}</Text>
                <Switch
                  size="sm"
                  isChecked={question?.isActive}
                  onToggle={() =>
                    setQuestion({ ...question!, isActive: !question?.isActive })
                  }
                />
              </HStack>
              {/* question title */}
              <FormControl
                isRequired={props.status !== "view"}
                w="100%"
                isInvalid={
                  emptyInputs.includes("questionText") || Boolean(titleError)
                }
                my="2"
              >
                <FormControl.Label
                  mb={
                    props.status === "view" ||
                    props.question?.questionType === "fillInTheBlank" ||
                    (question && question.questionType === "fillInTheBlank")
                      ? 0
                      : 2
                  }
                >
                  {t("Question Text")}
                </FormControl.Label>
                {props.status === "view" ? (
                  <div
                    style={{ fontSize: 15 }}
                    dangerouslySetInnerHTML={createMarkup(
                      question?.questionText ?? ""
                    )}
                  />
                ) : (
                  <>
                    <FormControl.HelperText mb={3}>
                      {t("Use \\frac{x}{y} to display fraction")}
                    </FormControl.HelperText>
                    {props.question?.questionType === "fillInTheBlank" ||
                      (question &&
                        question.questionType === "fillInTheBlank" && (
                          <FormControl.HelperText mb={3}>
                            Use 3 consecutive underscores (___) to indicate the
                            blank
                          </FormControl.HelperText>
                        ))}
                    <RichTextEditor
                      value={question?.questionText ?? ""}
                      onChange={(value: string) =>
                        handleInputOnChange("questionText", value)
                      }
                    />
                  </>
                )}
                <FormControl.ErrorMessage
                  leftIcon={<WarningOutlineIcon size="xs" />}
                >
                  {titleError ? titleError : t("Question Text cannot be empty")}
                </FormControl.ErrorMessage>
              </FormControl>
              {/* mc options */}
              {question?.questionType === "multipleChoice" && (
                <FormControl
                  zIndex={-1}
                  flexDirection="row"
                  my="2"
                  isDisabled={props.status === "view"}
                  isInvalid={emptyInputs.includes("questionOptions")}
                >
                  <FormControl.Label mt="2">{t("Options")}</FormControl.Label>
                  <VStack w="50%">
                    {/* option input */}
                    <Input
                      textAlign="center"
                      InputLeftElement={
                        <Button
                          size="xs"
                          rounded="none"
                          w="1/6"
                          h="full"
                          variant="ghost"
                          colorScheme="light"
                          onPress={() =>
                            handleOptionNumberOnChange(
                              String(question.questionOptions!.length - 1)
                            )
                          }
                        >
                          <IoRemove color="primary" />
                        </Button>
                      }
                      InputRightElement={
                        <Button
                          size="xs"
                          rounded="none"
                          w="1/6"
                          h="full"
                          variant="ghost"
                          colorScheme="light"
                          onPress={() =>
                            handleOptionNumberOnChange(
                              String(question.questionOptions!.length + 1)
                            )
                          }
                        >
                          <IoAdd />
                        </Button>
                      }
                      size="md"
                      variant="outline"
                      placeholder="Option"
                      type="number"
                      onChangeText={(value: string) => {
                        handleOptionNumberOnChange(value);
                      }}
                      value={optionNumber}
                    />
                    {/* option radio btn */}
                    {question.questionOptions!.length > 0 && (
                      <>
                        {question.questionOptions?.map(
                          (option: string, index: number) => (
                            // use div instead of HStack to prevent dropdown of editor overlapping
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                margin:
                                  props.status === "view" ? 0 : "0.5rem 0",
                                border:
                                  question.correctAnswer === option &&
                                  props.status === "view"
                                    ? "2px solid #fece4d"
                                    : 0,
                                borderRadius: 5,
                              }}
                              key={index}
                            >
                              <Text
                                bold
                                mx="5"
                                textAlign="center"
                                textTransform="uppercase"
                              >
                                {(index + 10).toString(36)}
                              </Text>
                              {props.status === "view" ? (
                                <>
                                  <div
                                    style={{
                                      fontSize: 13,
                                      width: "100%",
                                    }}
                                    dangerouslySetInnerHTML={createMarkup(
                                      option
                                    )}
                                  />
                                </>
                              ) : (
                                <>
                                  <RichTextEditor
                                    value={option}
                                    onChange={(value: string) =>
                                      handleOptionOnChange(index, value)
                                    }
                                  />
                                  <Checkbox
                                    ml="5"
                                    isChecked={
                                      question.questionOptions[index] !== "" &&
                                      question.questionOptions[index] ===
                                        question.correctAnswer
                                    }
                                    key={index}
                                    accessibilityLabel={option}
                                    value={option}
                                    isInvalid={emptyInputs.includes(
                                      "correctAnswer"
                                    )}
                                    onChange={(isSelected) =>
                                      onCorrectAnswerCheckboxChange(
                                        isSelected,
                                        index
                                      )
                                    }
                                  ></Checkbox>
                                </>
                              )}
                            </div>
                          )
                        )}
                      </>
                    )}
                  </VStack>
                  {/* error msg for options */}
                  <VStack ml="5rem">
                    {optionError.map((msg: string, index: number) => (
                      <FormControl.ErrorMessage
                        key={index}
                        leftIcon={<WarningOutlineIcon size="xs" />}
                      >
                        {msg}
                      </FormControl.ErrorMessage>
                    ))}
                  </VStack>
                </FormControl>
              )}
              {/* input ans for fill in the blank or free text */}
              {question?.questionType &&
                question.questionType !== "multipleChoice" && (
                  <FormControl
                    my="2"
                    isRequired={props.status !== "view"}
                    w="100%"
                    isInvalid={emptyInputs.includes("correctAnswer")}
                  >
                    <FormControl.Label
                      marginBottom={props.status === "view" ? 0 : 2}
                    >
                      {t("Correct Answer")}
                    </FormControl.Label>
                    {question.questionType === "freeText" && (
                      <TextArea
                        value={question?.correctAnswer}
                        isDisabled={props.status === "view"}
                        onChangeText={(value: string) =>
                          handleInputOnChange("correctAnswer", value)
                        }
                      />
                    )}
                    {question.questionType === "fillInTheBlank" && (
                      <Input
                        value={question.correctAnswer}
                        isDisabled={props.status === "view"}
                        onChangeText={(value: string) =>
                          handleInputOnChange("correctAnswer", value)
                        }
                      />
                    )}
                  </FormControl>
                )}
              {/* error msg */}
              {error && (
                <Alert w="100%" status="error">
                  <VStack space={2} flexShrink={1} w="100%">
                    <HStack
                      flexShrink={1}
                      space={2}
                      justifyContent="space-between"
                    >
                      <HStack
                        w="full"
                        space={2}
                        flexShrink={1}
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <HStack space={2} flexShrink={1} alignItems="center">
                          <Alert.Icon />
                          <Text fontSize="sm" color="coolGray.800">
                            {error}
                          </Text>
                        </HStack>
                      </HStack>
                    </HStack>
                  </VStack>
                </Alert>
              )}
            </>
          )}
        </Modal.Body>
        {props.status !== "view" && (
          <Modal.Footer alignItems="center" justifyContent="center">
            <Button
              w="full"
              variant="subtle"
              onPress={handleSubmit}
              isLoading={createMutation.isLoading || updateMutation.isLoading}
            >
              {t("Submit")}
            </Button>
          </Modal.Footer>
        )}
      </Modal.Content>
    </Modal>
  );
};

export default QuestionManagementModal;
