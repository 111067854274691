import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../redux/reducers';
import { Heading, Center, useToast, ScrollView, Text } from 'native-base';
import { tableIcons } from '../MaterialTableIcons';
import MaterialTable, { Column } from 'material-table';
import { useQuery } from 'react-query';
import {
  getLearningUnits,
  addLearningUnit,
  updateLearningUnit,
  getLearningUnitAreas,
  getSubjects,
  deleteLearningUnit,
  getLearningUnitsIsPublic,
  getLearningUnitAreasIsPublic,
  getSubjectsIsPublic,
} from '../api';
import { ILearningUnit } from '../types/LearningUnitType';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';

const mapStateToProps = (state: RootState) => ({
  userObj: state.auth.user,
  currentRole: state.auth.currentRole,
});

const connector = connect(mapStateToProps);
type PropsType = ConnectedProps<typeof connector> & RouteComponentProps;

const LearningUnitManagementPage: React.FC<PropsType> = (props) => {
  const toast = useToast();
  const { t, i18n } = useTranslation();

  const publicDataRole = ['systemAdmin', 'contentProvider'];

  const {
    data: learningUnits,
    isLoading,
    isFetching,
    refetch,
  } = useQuery('learningUnits', () => (!publicDataRole.includes(props.currentRole || '') ? getLearningUnits() : getLearningUnitsIsPublic()), {
    refetchOnWindowFocus: false,
    onError: () => {
      toast.show({
        title: 'Something went wrong',
        status: 'error',
        description: 'Cannot fetch learning unit',
      });
    },
  });

  const {
    data: learningUnitAreas,
    isLoading: isLoadingLearningUnitAreas,
    refetch: refetchLearningUnitAreas,
  } = useQuery('learningUnitArea', () => (!publicDataRole.includes(props.currentRole || '') ? getLearningUnitAreas() : getLearningUnitAreasIsPublic()));
  const {
    data: subjects,
    isLoading: isLoadingSubjects,
    refetch: refetchSubjects,
  } = useQuery('subject', () => (!publicDataRole.includes(props.currentRole || '') ? getSubjects() : getSubjectsIsPublic()));

  const [areaMap, setAreaMap] = useState<{ [key: string]: string }>();
  const [subjectMap, setSubjectMap] = useState<{ [key: string]: string }>();

  const checkCircularPrerequisites = (prerequisiteIds: string[], target: string): boolean => {
    if (prerequisiteIds.length === 0) {
      return true;
    } else if (prerequisiteIds.includes(target)){
      return false;
    } else {
      let result =  true;
      prerequisiteIds.forEach((prerequisiteId) => {
        let data = learningUnits?.find((unit) => unit._id === prerequisiteId);
        let ids = data?.prerequisites ? data?.prerequisites.map(
            (learningUnit) => learningUnit._id
        ): [];
        if (ids) {
          result = result && checkCircularPrerequisites(ids, target);
        }
      })
      return result;
    }
  }

  useEffect(() => {
    const _areaMap = learningUnitAreas?.reduce<{ [key: string]: string }>(
      (map, obj) => {
        return { ...map, [obj._id]: i18n.language === 'zh' && obj.nameChi !== null ? obj.nameChi : obj.nameEng }
      },
      {}
    );
    setAreaMap(_areaMap);
  }, [learningUnitAreas, i18n]);

  useEffect(() => {
    const _subjectMap = subjects?.reduce<{ [key: string]: string }>(
      (map, obj) => {
        return { ...map, [obj._id]: i18n.language === 'zh' && obj.nameChi !== null ? obj.nameChi : obj.nameEng }
      },
      {}
    );
    setSubjectMap(_subjectMap);
  }, [subjects, i18n]);

  useEffect(() => {
    setColumnObject([
      {
        title: 'ID',
        type: 'string',
        field: '_id',
        hidden: true,
      },
      {
        title: `${t('Name')}  (${t('Eng')})`,
        type: 'string',
        field: 'nameEng',
      },
      {
        title: `${t('Name')}  (${t('Chi')})`,
        type: 'string',
        field: 'nameChi',
      },
      {
        title: t('Subject'),
        type: 'string',
        lookup: subjectMap,
        field: 'subject._id',
      },
      {
        title: t('Code'),
        type: 'string',
        field: 'code',
      },
      {
        title: t('Level'),
        type: 'string',
        field: 'level',
      },
      {
        title: t('Learning Unit Area'),
        type: 'string',
        lookup: areaMap,
        field: 'area._id',
      },
      {
        title: t('Number'),
        type: 'string',
        field: 'number',
      },
      {
        title: t('Prerequisites'),
        type: 'string',
        render: (rowData) =>
          rowData.prerequisites
            .map((prerequisite) => prerequisite.code)
            .join(', '),
        field: 'prerequisites',
        editComponent: (props) => {
          return (
              <>
                <Select
                  isMulti
                  defaultValue={props.rowData.prerequisites?.map(
                    (learningUnit) => ({
                      ...learningUnit,
                      value: learningUnit._id,
                      label: learningUnit.code,
                    })
                  )}
                  onChange={(newValue) => props.onChange(newValue)}
                  options={learningUnits?.map((learningUnit) => ({
                    ...learningUnit,
                    label: learningUnit.code,
                    value: learningUnit._id,
                  }))}
                />
                {props.error && 
                  <Text 
                    color="red.600"
                    fontSize="xs">
                    {`*${t('Circular Prerequisites')}`}
                  </Text>}
              </>
          );
        },
        validate: (rowData) => {
          let prerequisiteIds = rowData.prerequisites ? rowData.prerequisites.map(
            (learningUnit) => learningUnit._id
          ): [];
          return checkCircularPrerequisites(prerequisiteIds, rowData._id);
        }
      },
      {
        title: t('Created By'),
        type: 'string',
        field: 'updatedAt',
        render: (rowData) => {
          return (i18n.language === 'zh' && rowData.createdBy?.lastNameChi !== null && rowData.createdBy?.firstNameChi !== null ? `${rowData.createdBy?.lastNameChi} ${rowData.createdBy?.firstNameChi}` : `${rowData.createdBy?.lastNameEng} ${rowData.createdBy?.firstNameEng}`);
        },
        editable: 'never',
      },
      {
        title: t('Updated By'),
        type: 'string',
        field: 'updatedAt',
        render: (rowData) => {
          return (rowData.updatedBy === undefined ? 
            (i18n.language === 'zh' && rowData.createdBy?.lastNameChi !== null && rowData.createdBy?.firstNameChi !== null ? `${rowData.createdBy?.lastNameChi} ${rowData.createdBy?.firstNameChi}` : `${rowData.createdBy?.lastNameEng} ${rowData.createdBy?.firstNameEng}`) : 
            (i18n.language === 'zh' && rowData.updatedBy?.lastNameChi !== null && rowData.updatedBy?.firstNameChi !== null ? `${rowData.updatedBy?.lastNameChi} ${rowData.updatedBy?.firstNameChi}` : `${rowData.updatedBy?.lastNameEng} ${rowData.updatedBy?.firstNameEng}`));
        },
        editable: 'never',
      },
      {
        title: t('Created Date'),
        type: 'datetime',
        field: 'createdAt',
        editable: 'never',
      },
      {
        title: t('Updated Date'),
        type: 'datetime',
        field: 'updatedAt',
        editable: 'never',
      },
    ]);
  }, [areaMap, subjectMap, learningUnits, t]);

  //Need to define the columns in state, so that when filter is applied, and the data is refetched, the filter is kept
  //https://github.com/mbrn/material-table/issues/491#issuecomment-541011677
  const [columnObject, setColumnObject] = useState<Column<ILearningUnit>[]>([]);

  return (
    <ScrollView paddingTop={["35px", '0']}>
      <Heading>{t('Unit Management')}</Heading>
      <Center padding="10px">
        <MaterialTable
          icons={tableIcons}
          style={{ width: '100%' }}
          title={t('Learning Unit')}
          isLoading={isLoading || isFetching}
          editable={{
            onRowAdd: (props.currentRole !== 'contentProvider') ? async (newData) => {
              let newLearningUnit = {
                ...newData,
                areaId: newData.area._id,
                subjectId: newData.subject._id,
                prerequisiteIds: newData.prerequisites ? newData.prerequisites.map(
                  (learningUnit) => learningUnit._id
                ): [],
                isPublic: (props.currentRole === 'systemAdmin'),
              };
              const { subject, area, ...newLearningUnitRequest } =
                newLearningUnit;
              console.log(newLearningUnitRequest);
              await addLearningUnit(newLearningUnitRequest);
              refetch();
            } : undefined,
            onRowUpdate: (props.currentRole !== 'contentProvider') ? async (newData, oldData) => {
              let newLearningUnit = {
                ...newData,
                areaId: newData.area._id,
                subjectId: newData.subject._id,
                prerequisiteIds: newData.prerequisites.filter(learningUnit => learningUnit._id !== newData._id).map(
                  (learningUnit) => learningUnit._id
                ),
              };
              const { subject, area, ...newLearningUnitRequest } =
                newLearningUnit;
              await updateLearningUnit(newLearningUnitRequest);
              refetch();
            } : undefined,
            onRowDelete: (props.currentRole !== 'contentProvider') ? async (oldData) => {
              await deleteLearningUnit(oldData._id);
              refetch();
            } : undefined,
          }}
          columns={columnObject}
          data={learningUnits ?? []}
          options={{
            pageSize: 10,
            pageSizeOptions: [10, 25, 50],
            filtering: true,
            addRowPosition: 'first',
          }}
        />
      </Center>
    </ScrollView>
  );
};

export default withRouter(connector(LearningUnitManagementPage));
