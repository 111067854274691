import { useEffect, useState } from 'react'
import { CreateLearningMaterialRequest, ILearningMaterial } from '../types/LearningMaterialType';
import {
  Button,
  CheckIcon,
  FormControl,
  HStack,
  Input,
  Modal,
  Select,
  Spinner,
  Text,
  VStack,
  Alert,
  WarningOutlineIcon,
  Switch,
  Center,
  Heading,
  Icon,
  useTheme,
} from 'native-base';
import { useTranslation } from 'react-i18next';
import axios from "../config/axios";
import { AiOutlineDownload } from 'react-icons/ai';
import AttachmentListItem from '../components/AttachmentListItem'
import { createMarkup } from '../utils/htmlHelper';

interface LearningMaterialContentSegmentProps {
  learningMaterial: ILearningMaterial | CreateLearningMaterialRequest;
}

const LearningMaterialContentSegment: React.FC<LearningMaterialContentSegmentProps> = (props) => {
  const { t, i18n } = useTranslation();
  const { colors } = useTheme()

  const [viewing, setViewing] = useState<'content' | 'video' | 'audio' | 'attachment'>('content');
  const [numberOfVideo, setNumberOfVideo] = useState<number>(0);
  const [numberOfAudio, setNumberOfAudio] = useState<number>(0);
  const [numberOfAttachment, setNumberOfAttachment] = useState<number>(0);

  useEffect(() => {
    let numberOfVideo = props.learningMaterial.attachments?.filter(attachment => attachment.mimetype.includes('video')).length ?? 0
    numberOfVideo += props.learningMaterial.youtubeLinks?.length ?? 0
    setNumberOfVideo(numberOfVideo)
    setNumberOfAudio(props.learningMaterial.attachments?.filter(attachment => attachment.mimetype.includes('audio')).length ?? 0)
    setNumberOfAttachment(props.learningMaterial.attachments?.filter(attachment => !attachment.mimetype.includes('video') && !attachment.mimetype.includes('audio')).length ?? 0)
  }, [props])

  return (
    <>
      <Center marginY={4}>
        <Button.Group isAttached mx={{
          base: "auto",
          md: 0
        }} size="sm">
          <Button
            variant={viewing === 'content' ? 'solid' : 'outline'}
            _text={{ color: 'black' }}
            onPress={() => setViewing('content')} >
            {t('Content')}
          </Button>
          <Button
            variant={viewing === 'video' ? 'solid' : 'outline'}
            _text={{ color: 'black' }}
            onPress={() => setViewing('video')} >
            <HStack alignItems={'center'}>
              <Text fontWeight={'bold'} fontSize={12}>{t('Video')}</Text>
              <Text fontWeight={'bold'} fontSize={12}>({numberOfVideo})</Text>
            </HStack>
          </Button>
          <Button
            variant={viewing === 'audio' ? 'solid' : 'outline'}
            _text={{ color: 'black' }}
            onPress={() => setViewing('audio')} >
            <HStack alignItems={'center'}>
              <Text fontWeight={'bold'} fontSize={12}>{t('Audio')}</Text>
              <Text fontWeight={'bold'} fontSize={12}>({numberOfAudio})</Text>
            </HStack>
          </Button>
          <Button
            variant={viewing === 'attachment' ? 'solid' : 'outline'}
            _text={{ color: 'black' }}
            onPress={() => setViewing('attachment')} >
            <HStack alignItems={'center'}>
              <Text fontWeight={'bold'} fontSize={12}>{t('Attachment')}</Text>
              <Text fontWeight={'bold'} fontSize={12}>({numberOfAttachment})</Text>
            </HStack>
          </Button>
        </Button.Group>
      </Center>
      <VStack space={2}>
        {viewing === 'content' && (
          <div style={{ fontSize: 15 }} dangerouslySetInnerHTML={createMarkup(props.learningMaterial.content ?? '')} />
        )}
        {viewing === 'video' && (
          <VStack>
            {numberOfVideo === 0 &&
              <Text>{t('No videos')}</Text>
            }
            <VStack space={2}>
              {props.learningMaterial.youtubeLinks && props.learningMaterial.youtubeLinks.map((youtubeLink) => {
                let embedLink = youtubeLink.replace('watch?v=', 'embed/');
                embedLink = embedLink.replace('youtu.be/', 'www.youtube.com/embed/');
                console.log(embedLink)
                return (
                  <iframe
                    width="560"
                    height="315"
                    src={embedLink}
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen></iframe>
                )
              }
              )}
            </VStack>

            {props.learningMaterial.attachments && props.learningMaterial.attachments.filter(attachment => attachment.mimetype.includes('video')).map((attachment) => (
              <VStack space={2}>
                <Text>{attachment.originalname}</Text>
                <video src={axios.defaults.baseURL + attachment.uri} controls />
              </VStack>
            ))}
          </VStack>
        )}
        {viewing === 'audio' && (
          <VStack>
            {numberOfAudio === 0 &&
              <Text>{t('No audios')}</Text>
            }
            {props.learningMaterial.attachments && props.learningMaterial.attachments.filter(attachment => attachment.mimetype.includes('audio')).map((attachment) => (
              <VStack space={2}>
                <Text>{attachment.originalname}</Text>
                <audio src={axios.defaults.baseURL + attachment.uri} controls />
              </VStack>
            ))}
          </VStack>
        )}
        {viewing === 'attachment' && (
          <VStack>
            {numberOfAttachment === 0 &&
              <Text>{t('No attachments')}</Text>
            }
            {props.learningMaterial.attachments && props.learningMaterial.attachments.filter(attachment => !attachment.mimetype.includes('video') && !attachment.mimetype.includes('audio')).map((attachment) => (
              <Center>
                <AttachmentListItem file={attachment} />
              </Center>
              // <a href={axios.defaults.baseURL + attachment.uri} download>={attachment.originalname}</a>
            ))}
          </VStack>
        )}
      </VStack>
    </>
  )
}

export default LearningMaterialContentSegment;