import React, { useState, useEffect } from 'react';
import { signOut } from '@firebase/auth';
import { Divider, HamburgerIcon, HStack, Menu, Pressable, useToast, Text, ScrollView } from 'native-base';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { resetIAuthState, setCurrentRole } from '../redux/actions/authAction';
import { RootState } from '../redux/reducers';
import { IUserProfile } from '../types/AuthTypes';
import { auth } from '../utils/authHelper';
import ChangeLanguageButton from './ChangeLanguageButton';
import { useTranslation } from 'react-i18next';
import BackButton from './BackButton';
import { version } from '../version'

const mapStateToProps = (state: RootState) => ({
  userObj: state.auth.user,
  currentRole: state.auth.currentRole,
});

const mapDispatch = {
  resetIAuthState: resetIAuthState,
  setCurrentRole: setCurrentRole,
};

const connector = connect(mapStateToProps, mapDispatch);
type PropsType = ConnectedProps<typeof connector> & RouteComponentProps;

const Navbar: React.FC<PropsType> = (props) => {
  const { t, i18n } = useTranslation('translation');

  const [userRoles, setUserRoles] = useState<string[]>([]);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

  const toast = useToast();

  useEffect(() => {
    let title = '';
    if (process.env.NODE_ENV === 'production') {
      if ((process.env.REACT_APP_CUSTOM_ENV)?.toLowerCase() === 'uat') {
        title = 'You are running on UAT environment';
      }
    } else {
      title = 'You are running on DEVELOPMENT environment';
    }
    if (title !== '') {
      toast.show({
        title: title,
        status: 'info',
        duration: 5000,
      })
    }
  }, [])

  useEffect(() => {
    let roles = [];
    for (const [key, value] of Object.entries(
      props.userObj?.userProfile as IUserProfile
    )) {
      if (value.isActive === true) {
        roles.push(key);
      }
    }
    setUserRoles(roles);
  }, [props.userObj]);

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user && user.emailVerified) {
        setIsAuthenticated(true);
      }
    });
  }, []);

  const handleLogout = async () => {
    await signOut(auth);
    props.resetIAuthState();
  };

  const handleSwitchRole = (role: string) => {
    props.setCurrentRole(role);
    props.history.push('/');
  };

  const menuItems = [
    {
      title: t('Dashboard'),
      route: '/dashboard',
      roles: ['student', 'teacher'],
    },
    {
      title: t('Quizzes'),
      route: '/subjects',
      roles: ['student']
    },
    {
      title: t('Reports'),
      route: '/studentreports',
      roles: ['student']
    },
    {
      title: t('Learning Material'),
      route: '/learningmaterials',
      roles: ['student']
    },
    {
      title: t('Student Quiz Reports'),
      route: '/studentreports',
      roles: ['teacher'],
    },
    {
      title: t('Subject Management'),
      route: '/managements/subjects',
      roles: ['teacher'],
    },
    {
      title: t('School Management'),
      route: '/managements/schools',
      roles: ['systemAdmin'],
    },
    {
      title: t('Area Management'),
      route: '/managements/learningunitareas',
      roles: ['teacher'],
    },
    {
      title: t('Unit Management'),
      route: '/managements/learningunits',
      roles: ['teacher'],
    },
    {
      title: t('Objective Management'),
      route: '/managements/objectives',
      roles: ['teacher'],
    },
    {
      title: t('Question Management'),
      route: '/managements/questions',
      roles: ['teacher'],
    },
    {
      title: t('Learning Material Management'),
      route: '/managements/learningmaterials',
      roles: ['teacher'],
    },
    {
      title: t('Student Management'),
      route: '/managements/students',
      roles: ['teacher'],
    },
    {
      title: t('User Management'),
      route: '/managements/users',
      roles: ['systemAdmin'],
    },
    {
      title: t('Subject Management'),
      route: '/managements/subjects',
      roles: ['systemAdmin'],
    },
    {
      title: t('Area Management'),
      route: '/managements/learningunitareas',
      roles: ['systemAdmin'],
    },
    {
      title: t('Unit Management'),
      route: '/managements/learningunits',
      roles: ['systemAdmin'],
    },
    {
      title: t('Objective Management'),
      route: '/managements/objectives',
      roles: ['systemAdmin'],
    },
    {
      title: t('Subject Management'),
      route: '/managements/subjects',
      roles: ['contentProvider'],
    },
    {
      title: t('Area Management'),
      route: '/managements/learningunitareas',
      roles: ['contentProvider'],
    },
    {
      title: t('Unit Management'),
      route: '/managements/learningunits',
      roles: ['contentProvider'],
    },
    {
      title: t('Objective Management'),
      route: '/managements/objectives',
      roles: ['contentProvider'],
    },
    {
      title: t('Question Management'),
      route: '/managements/questions',
      roles: ['contentProvider'],
    },
    {
      title: t('Learning Material Management'),
      route: '/managements/learningmaterials',
      roles: ['contentProvider'],
    }
  ];

  return (
    <div style={{
      position: 'fixed',
      top: 10,
      left: 10,
    }}>
      <HStack space={2}
        style={{
          display: 'flex',
          alignItems: 'center',
        }}>
        <Menu
          m={2}
          defaultIsOpen={false}
          w="190"
          closeOnSelect={true}
          trigger={(triggerProps) => {
            return (
              <Pressable {...triggerProps}>
                <HamburgerIcon color='primary.800' />
              </Pressable>
            );
          }}
        > 
          <ScrollView h="2xl">
            {menuItems
              .filter((item) =>
                item.roles.some((role) => role == props.currentRole)
              )
              .map((item, index) => (
                <Menu.Item
                  onPress={() => props.history.push(item.route)}
                  key={index}
                >
                  {item.title}
                </Menu.Item>
              ))}
            <Divider mt="3" w="100%" />
            <Menu.Group title={t('Settings')}>
              <>
                {isAuthenticated && (
                  <Menu.Item onPress={() => props.history.push('/profile')}>
                    {t('Profile')}
                  </Menu.Item>
                )}
              </>
              <>
                {userRoles && userRoles.length > 1 && (
                  <Menu.OptionGroup
                    title="Switch Role"
                    type="radio"
                    defaultValue={
                      props.currentRole !== null ? props.currentRole : undefined
                    }
                  >
                    <>
                      {userRoles.map((role, index) => (
                        <Menu.ItemOption
                          key={index}
                          value={role}
                          textTransform="capitalize"
                          onPress={() => handleSwitchRole(role)}
                        >
                          {role}
                        </Menu.ItemOption>
                      ))}
                    </>
                  </Menu.OptionGroup>
                )}
              </>
              <Menu.Item>
                <HStack>
                  <ChangeLanguageButton />
                </HStack>
              </Menu.Item>
              <Menu.Item onPress={handleLogout}>{t('Logout')}</Menu.Item>
            </Menu.Group>
            {(process.env.NODE_ENV === 'production' && (process.env.REACT_APP_CUSTOM_ENV)?.toLowerCase() === 'uat') && (
              <Menu.Group title='Environment'>
                <Menu.Item>
                  <Text>UAT</Text>
                </Menu.Item>
              </Menu.Group>
            )}
            {process.env.NODE_ENV === 'development' && (
              <Menu.Group title='Environment'>
                <Menu.Item>
                  <Text>DEVELOPMENT</Text>
                </Menu.Item>
              </Menu.Group>
            )}
            { (props.currentRole === 'student' || props.currentRole === 'teacher') ? (
              <Menu.Group title='Version'>
                <Menu.Item>
                  <Text>{(props.currentRole === 'teacher' ? `${t('School Version')} - ${i18n.language === 'zh' && 
                    props.userObj?.userProfile.teacher?.school.nameChi !== null ? props.userObj?.userProfile.teacher?.school.nameChi : props.userObj?.userProfile.teacher?.school.nameEng}` : 
                  (props.userObj?.userProfile?.student?.school === null ? t('Public Version') :`${t('School Version')} - ${i18n.language === 'zh' && 
                    props.userObj?.userProfile.student?.school.nameChi !== null ? props.userObj?.userProfile.student?.school.nameChi : props.userObj?.userProfile.student?.school.nameEng}`))}
                  </Text>
                </Menu.Item>
                <Menu.Item>
                  <Text>{version}</Text>
                </Menu.Item>
              </Menu.Group >
            ) : (
              <Menu.Group title='Version'>
                <Menu.Item>
                  <Text>{version}</Text>
                </Menu.Item>
              </Menu.Group >
            )}
          </ScrollView>
        </Menu>
        <BackButton />
      </HStack>
    </div>
  );
};

export default withRouter(connector(Navbar));
