import React from 'react';
import { IconButton } from 'native-base';
import { RouteComponentProps, withRouter } from 'react-router';
import { IoChevronBackCircle } from 'react-icons/io5';

const BackButton: React.FC<RouteComponentProps> = (props) => {
  return (
    <>
      {window.location.pathname !== "/" && window.location.pathname !== "/dashboard" &&
        <IconButton
          size="8"
          variant="unstyled"
          color='primary.800'
          icon={<IoChevronBackCircle size="32" />}
          onPress={() => {
            props.history.goBack()
          }}
        />
      }
    </>
  );
};

export default withRouter(BackButton);
