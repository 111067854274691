import {
  IAuthState,
  CURRENT_ROLE_UPDATED,
  LOGGED_IN,
  USER_RESET,
  LOGIN_FAILED,
  REQUEST_USER_OBJ,
} from '../../types/AuthTypes';

const initialState: IAuthState = {
  user: null,
  currentRole: null,
  error: '',
  isRequest: false,
};

export const authReducer = (state = initialState, action: any): IAuthState => {
  switch (action.type) {
    case USER_RESET:
      return { ...state, user: null, error: '', isRequest: false };
    case LOGGED_IN:
      return { ...state, user: action.data, error: '', isRequest: false };
    case LOGIN_FAILED:
      return { ...state, error: action.data, isRequest: false };
    case REQUEST_USER_OBJ:
      return { ...state, error: '', isRequest: true };
    case CURRENT_ROLE_UPDATED:
      return { ...state, currentRole: action.data };
    default:
      return state;
  }
};
