export const componentsTheme = {
  Heading: {
    defaultProps: {
      size: 'md',
      textAlign: 'center',
      marginTop: 5,
      marginBottom: 5,
    },
  },
  Box: {
    variants: {
      dashboardBox: () => {
        return {
          borderRadius: 30,
          shadow: 5
        };
      },
      responsive: () => {
        return {
          flexDirection: ['column', 'row'],
          maxWidth: '100%'
        }
      }
    },
  },
  HStack: {
    variants: {
      responsive: () => {
        return {
          flexDirection: ['column', 'row']
        }
      }
    }
  },
  Button: {
    baseStyle: {
      _text: {
        fontWeight: 700,
      },
    },
    defaultProps: {
      colorScheme: 'primary',
    },
    variants: {
      ghost: ({ colorScheme }: any) => {
        return {
          _text: {
            color: `${colorScheme}.700`,
          },
        };
      },
      link: ({ colorScheme }: any) => {
        return {
          _text: {
            color: `${colorScheme}.700`,
          },
        };
      },
      outline: ({ colorScheme }: any) => {
        return {
          _text: {
            color: `${colorScheme}.600`,
          },
        };
      },
      subtle: ({ colorScheme }: any) => {
        return {
          _text: {
            color: `${colorScheme}.700`,
          },
        };
      },
      quizNext: ({ colorScheme }: any) => {
        return {
          backgroundColor: `${colorScheme}.500`,
          w: 'full',
          h: 50,
        };
      },

    }
  },
  Spinner: {
    defaultProps: {
      color: 'primary.500',
    }
  },
  Text: {
    variants: {
      keyLabel: () => {
        return {
          fontSize: 'md',
          width: '150',
          maxWidth: '100%',

        }
      },
      valueLable: () => {
        return {
          fontSize: 'md',
          fontWeight: 'semibold',
          textAlign: ['left', 'right'],
          width: '300',
          maxWidth: '100%',
        }
      },
    }
  },
}