import { Box, Button, Text, HStack, VStack, Container, Center, Divider, useBreakpointValue } from 'native-base';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { RootState } from '../redux/reducers';
import unicorn from '../images/unicorn.png';
import rocket_boy from '../images/rocket_boy.png';
import quote from '../images/quote.png';
import winner from '../images/winner.png';
import student from '../images/student.png';
import { PieChart } from 'react-minimal-pie-chart';
import PageContainer from '../components/PageContainer';
import { useQuery } from 'react-query';
import { getSubjects } from '../api';
import tree_1 from '../images/tree_1.png'
import compass from '../images/compass.png'
import camp_fire from '../images/camp_fire.png'
import ship from '../images/ship.png'
import gold_box from '../images/gold_box.png'
import login_island from '../images/login_island.png'
import { useTranslation } from 'react-i18next';

const mapStateToProps = (state: RootState) => ({
  currentRole: state.auth.currentRole,
});

const connector = connect(mapStateToProps);
type PropsType = ConnectedProps<typeof connector> & RouteComponentProps;

const enrolled_modules = [
  { score: 0, module: 'Number (N)' },
  { score: 0, module: 'Measures (M)' },
  { score: 0, module: 'Shape and Space (S)' },
  { score: 0, module: 'Data Handling (D)' },
  { score: 0, module: 'Algebra (A)' }
]

const DashboardPage: React.FC<PropsType> = (props) => {

  const { t } = useTranslation();

  const flexDir = useBreakpointValue({
    base: "column",
    lg: "row",
  })

  return (
    <>
      <div style={{
        backgroundImage: `url(${login_island})`,
        height: '100%',
        width: '100%',
        overflow: 'scroll',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
      }}>

        <Box
          padding={['5', '5', '5', '50']}
          paddingTop={'60px'}
          height={'full'}
        >
          <HStack
            flexDirection={['column', 'column', 'column', 'row']}
            justifyContent={['center']}
            alignItems={['center']}
            space='sm'
            flex={1}
          >
            <Box
              variant="dashboardBox"
              bgColor="#FEC837"
              w={['90%', '90%', '60%']}
              p='5'
            >
              <HStack
                w='full'
                space='sm'
                padding='5'
                justifyContent={['center']}
                style={{
                  flexDirection: flexDir,
                }}>
                <VStack w={['100%', '100%', '100%', '30%']} display="flex" alignItems='center'>
                  <img src={tree_1} width='180px' height='176px' style={{ maxWidth: '100%' }} />
                </VStack>
                <VStack w={['100%', '100%', '100%', '35%']} display="flex" alignItems='center' justifyContent='center'>
                  <Text color="white" fontSize='2xl' fontWeight='500' textAlign={'center'}>
                    {t('Welcome')}
                  </Text>
                  <Text color="white" fontSize='xl' fontWeight='400' textAlign={'center'}>
                    {t('Let\'s explore the world of Edventures')}
                  </Text>
                  {props.currentRole === 'student' &&
                    <Button bgColor='white' borderRadius='20' m='5px' h='28px' p='20px'
                      onPress={() => {
                            props.history.push(`/subjects`);
                      }
                      }
                    >
                      <Text color='blue.500' fontSize='lg' fontWeight='400'>
                        {t('Start Now')}
                      </Text>
                    </Button>
                  }
                </VStack>
                <VStack w={['100%', '100%', '100%', '30%']} display="flex" alignItems='center' justifyContent='center'>
                  <img src={compass} width='181px' height='auto' style={{ maxWidth: '100%' }} />
                </VStack>
              </HStack>
            </Box>
            {/* <Box
            w={['100%', '100%', '100%', '30%']}
            variant="dashboardBox"
            bgColor="#ACD7AE"
            mb='24px'
            mt={['24px', '24px', '24px', '0']}
            p={'5'}
          >
          <HStack
              w='full'
              style={{
                flexDirection: flexDir,
              }}
            >
              <VStack w={['100%', '100%', '100%', '65%']} display="flex">
                <Text color="white" fontSize='xl' fontWeight='500' >
                  Quote of the Day
                </Text>
                <Text color="white" fontSize='lg'>
                  Education is not preparation for life; education is life itself.
                </Text>
              </VStack >
              <VStack w={['100%', '100%', '100%', '35%']} display="flex" alignItems='center' >
                <img src={quote} width='135px' height='108px' />
              </VStack>
            </HStack>
            </Box>*/}
          </HStack >
          {/* <HStack
            h={['100%', 'auto']}
            w='100%'
            space='lg'
            display="flex"
            mt='15px'
            style={{
              flexDirection: flexDir,
            }}> */}
          {/* <VStack
              w={['100%', '100%', '100%', '50%']}
              h='auto'
            >
              <Box
                variant="dashboardBox"
                bgColor='#C0E3FD'
                h='full'
              >
                <HStack w='full' pt='24px'>
                  <VStack w='60%' display="flex" justifyContent='center'>
                    <Text color="white" fontSize='3xl' fontWeight='500' bgColor='#858796' mt='25px' ml='10px'>
                      Enrolled Modules
                    </Text>
                  </VStack>
                  <VStack w='40%' >
                    <img src={ship} width='120px' height='139px' />
                  </VStack>

                </HStack>
                <Box
                  variant="dashboardBox"
                  bgColor='white'
                >
                  {enrolled_modules.map((data, i) => (
                    <Box
                      variant="dashboardBox"
                      bgColor='#f8f8f8'
                      maxH='70px'
                      h='auto'
                      m='10px'
                      p='10px'
                      key={i}
                    >
                      <HStack space='md' h='full'>
                        <VStack w='10%' display='flex' alignContent='center'>
                          <PieChart
                            data={[
                              { title: 'score', value: data.score, color: '#D68B3E' },
                            ]}
                            label={() => data.score + '%'}
                            labelStyle={{
                              fontSize: '25px',
                              fill: '#6ab4ac',
                            }}
                            labelPosition={0}
                            lineWidth={10}
                            background='#F1E9E1'
                          >
                          </PieChart>
                        </VStack>
                        <VStack w={['100%', '100%', '100%', '80%']} display='flex' justifyContent='center'>
                          <Text color="#9A9A9A" fontSize='lg'>
                            {data.module}
                          </Text>
                        </VStack>
                      </HStack>
                    </Box>
                  ))}
                </Box>
              </Box>
            </VStack> */}
          {/* <VStack
              w={['100%', '100%', '100%', '45%']}
              h='auto'
            > */}
          {/* <Box
                variant="dashboardBox"
                h='auto'
                p='20px'
                mt={['24px', '24px', '24px', '0']}
              >
                <Center textAlign='center'>
                  <img src={gold_box} width='134px' height='113px' />
                  <Text color="#9A9A9A" fontSize='2xl' fontWeight='500' mt='30px'>
                    Top Scored Learning Unit
                  </Text>
                  <Text color="#9A9A9A" fontSize='lg' fontWeight='500'>
                    Anyone who stops learning is old, whether at twenty or eighty.
                  </Text>
                  <Box
                    variant="dashboardBox"
                    bgColor='#9ecffb'
                    h='100px'
                    w='90%'
                    p='15px'
                    mt='30px'
                  >
                    <HStack
                      display='flex' alignContent='center' justifyContent='center' h='full'
                    >
                      <VStack display='flex' alignContent='center' justifyContent='center' textAlign='center' p='20px'>
                        <Text color="white" fontSize='xl'>
                          Unit
                        </Text>
                        <Text color="white" fontSize='xl'>
                          -
                        </Text>
                      </VStack>
                      <Divider orientation="vertical" bgColor="#5593d0" />
                      <VStack display='flex' alignContent='center' justifyContent='center' textAlign='center' p='20px'>
                        <Text color="white" fontSize='xl'>
                          Marks
                        </Text>
                        <Text color="white" fontSize='xl'>
                          -
                        </Text>
                      </VStack>
                      <Divider orientation="vertical" bgColor="#5593d0" />
                      <VStack display='flex' alignContent='center' justifyContent='center' textAlign='center' p='20px'>
                        <Text color="white" fontSize='xl'>
                          Time
                        </Text>
                        <Text color="white" fontSize='xl'>
                          -
                        </Text>
                      </VStack>
                    </HStack>
                  </Box> */}
          {/* </Center >
              </Box > */}
          {/* </VStack > */}
          {/* </HStack > */}
        </Box>
      </div>
    </>
  );
};

export default withRouter(connector(DashboardPage));
