import { Flex, HStack, Input, Text, TextArea } from 'native-base';
import { useTranslation } from 'react-i18next';
import { IQuestionWithUserAnswer } from '../types/QuestionTypes';
import { createMarkup } from '../utils/htmlHelper';
import QuizRadioButton from './QuizRadioButton';
interface QuizQuestionProps {
  question: IQuestionWithUserAnswer;
  currentQuestionNumber: number;
  isReadOnly?: boolean;
  answerOnChange?: Function;
}

const QuizQuestion: React.FC<QuizQuestionProps> = (props) => {
  const { i18n } = useTranslation();

  const renderFrontPart = (value: string) => {
    const index = value.indexOf('___');
    // fix ui bug due to missing p tag
    return `${value.slice(0, index)}</p>`;
  };

  const renderBackPart = (value: string) => {
    const index = value.indexOf('___') + 3;
    // fix ui bug due to missing p tag
    return `<p>${value.slice(index, value.length)}`;
  };

  return (
    <div style={{ margin: '1rem' }} key={props.question._id}>
      <Text fontSize="md" bold>
        {i18n.language === 'zh' ?
          `第${props.currentQuestionNumber + 1}題`
          :
          `Question ${props.currentQuestionNumber + 1}`
        }
      </Text>
      {props.question.questionType === 'fillInTheBlank' ? (
        <HStack alignItems="center">
          <span
            style={{ fontSize: 16 }}
            dangerouslySetInnerHTML={createMarkup(
              renderFrontPart(props.question.questionText)
            )}
          />
          <Input
            mx="2"
            isDisabled={!Boolean(props.answerOnChange)}
            placeholder="Enter your answer"
            value={props.question.userAnswer}
            onChangeText={
              props.answerOnChange
                ? (value: string) => {
                  props.answerOnChange!(value, props.question._id);
                }
                : undefined
            }
          />
          <span
            style={{ fontSize: 16 }}
            dangerouslySetInnerHTML={createMarkup(
              renderBackPart(props.question.questionText)
            )}
          />
        </HStack>
      ) : (
        <div
          style={{ fontSize: 16, margin: '1em 0' }}
          dangerouslySetInnerHTML={createMarkup(props.question.questionText)}
        />
      )}
      <>
        {props.question.questionType === 'multipleChoice' && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              flexDirection: 'column',
              marginTop: '0.5rem',
            }}
            onChange={
              props.answerOnChange
                ? (e: any) => {
                  props.answerOnChange!(e.target.value, props.question._id);
                }
                : undefined
            }
          >
            <Flex
              flexDirection="row"
              flexWrap="wrap"
              w="full"
              justifyContent="center"
            >
              {props.question.questionOptions?.map((option, index) => (
                <QuizRadioButton
                  key={index}
                  option={option}
                  isSelected={props.question.userAnswer === option}
                  isCorrect={props.question.correctAnswer === option}
                  isReadOnly={props.isReadOnly ?? false}
                />
              ))}
            </Flex>
          </div>
        )}
        {props.question.questionType === 'freeText' && (
          <TextArea
            w="full"
            placeholder="Enter your answer"
            value={props.question.userAnswer}
            isDisabled={!Boolean(props.answerOnChange)}
            onChangeText={
              props.answerOnChange
                ? (value: string) => {
                  props.answerOnChange!(value, props.question._id);
                }
                : undefined
            }
          />
        )}
      </>
    </div>
  );
};

export default QuizQuestion;
