import { FC, useEffect, useState } from 'react';
import { Button, HStack, Modal, Box, Text, IconButton, VStack } from 'native-base';
import QuizQuestion from './QuizQuestion';
import { IReport } from '../types/ReportTypes';
import QuestionNumberButton from './QuestionNumberButton';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import { RouteComponentProps, withRouter } from 'react-router';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { IoCloseOutline } from 'react-icons/io5';
interface QuizReportModalProps {
  onClose: () => void;
}

type PropsType = QuizReportModalProps & IReport & RouteComponentProps;

const QuizReportModal: FC<PropsType> = (props) => {
  const { t, i18n } = useTranslation()
  const [currentQuestionNumber, setCurrentQuestionNumber] = useState<number>(0);

  const isReadOnly = props.location.pathname.split('/')[1] === 'studentreports';
  const [elaspedTime, setElaspedTime] = useState<string>();

  useEffect(() => {
    const elapsedTime = props.elapsedTimeMs;
    let elapsedTimeDisplay = '';
    elapsedTimeDisplay = calTimeDisplay(elapsedTime);
    setElaspedTime(elapsedTimeDisplay)
  }, [props])

  const calTimeDisplay = (time: number | undefined): string => {
    if (time === undefined) {
      return '';
    } else {
      let timeDisplay = '';
      if (time / 1000 / 60 / 60 > 1) {
        timeDisplay = moment.utc(time).format('HH:mm:ss');
      } else {
        timeDisplay = moment.utc(time).format('mm:ss');
      }
      return timeDisplay;
    }
  }

  return (
    <Modal
      isOpen
      onClose={props.onClose}
      size="full"
      closeOnOverlayClick={false}
      _backdrop={{ height: '100%' }}
    >
      <Modal.Content height="100%" maxHeight="100%">
        <Modal.Header background="primary.500"
          display={'flex'}
          flexDirection={['column', 'column', 'row']}
          alignItems={['flex-start', 'flex-start', 'center']}
          justifyContent={'space-between'}
        >
          <Text fontSize={'16px'} fontWeight={'bold'} >
            {props.learningObjective.code} {i18n.language === 'zh' && props.learningObjective.nameChi !== '' ? props.learningObjective.nameChi : props.learningObjective.nameEng}
          </Text>
          <Text marginRight={'40px'}>
            {props.questions[currentQuestionNumber].oldUserAnswer ? 
              (props.questions[currentQuestionNumber].oldUserAnswer !== props.questions[currentQuestionNumber].userAnswer ? 
                `${t('The answer has been modified')}    ` : '') : ''}
            {props.questions[currentQuestionNumber].responseTimeMs ? 
              `${t('Response Time')}: ${calTimeDisplay(props.questions[currentQuestionNumber].responseTimeMs)}    ` : ''}
            {props.questions[currentQuestionNumber].reVisitTimeMs ? 
              `${t('Revisit Time')}: ${calTimeDisplay(props.questions[currentQuestionNumber].reVisitTimeMs)}    ` : ''}
            {`${t('Time used')}: ${elaspedTime}`}
          </Text>
          <IconButton
            style={{ position: 'absolute', right: 10, top: 10 }}
            icon={<IoCloseOutline size={20} />}
            onPress={props.onClose}
          />
        </Modal.Header>
        <Modal.Body minHeight="md">
          <HStack>
            {props.questions.map((question, index) => (
              <QuestionNumberButton
                disabled={false}
                key={index}
                questionNumber={index + 1}
                isCurrent={index === currentQuestionNumber}
                isAnswered
                onClick={() => {
                  setCurrentQuestionNumber(index);
                }}
              />
            ))}
          </HStack>
          <QuizQuestion
            question={props.questions[currentQuestionNumber]}
            currentQuestionNumber={currentQuestionNumber}
            isReadOnly={isReadOnly}
          />
        </Modal.Body>
        <Modal.Footer
          alignItems="center"
          justifyContent={
            currentQuestionNumber > 0 &&
              currentQuestionNumber <= props.questions.length - 1
              ? 'space-between'
              : 'flex-end'
          }
        >
          {currentQuestionNumber > 0 &&
            currentQuestionNumber <= props.questions.length - 1 && (
              <Button
                size="lg"
                width="30%"
                variant="quizNext"
                startIcon={<IoIosArrowBack size={20} color="white" />}
                onPress={() =>
                  setCurrentQuestionNumber(currentQuestionNumber - 1)
                }
              >
                {t('Previous')}
              </Button>
            )}
          {currentQuestionNumber < props.questions.length - 1 ? (
            <Button
              width="30%"
              size="lg"
              variant="quizNext"
              endIcon={<IoIosArrowForward size={20} color="white" />}
              onPress={() => {
                setCurrentQuestionNumber(currentQuestionNumber + 1);
              }}
            >
              {t('Next')}
            </Button>
          ) : (
            <Button
              width="30%"
              variant="quizNext"
              size="lg"
              onPress={props.onClose}
            >
              {t('Done')}
            </Button>
          )}
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
};

export default withRouter(QuizReportModal);
