import { Center, Flex, Heading, Spinner, Text, useToast, Box } from 'native-base';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { getQuizLearningUnitsByStudentAndSubject } from '../api';
import QuizModal from '../components/QuizModal';
import QuizProgressButton from '../components/QuizProgressButton';
import { RootState } from '../redux/reducers';
import { IQuizLearningObjective, IQuizLearningUnit } from '../types/QuizTypes';
import water_map from '../images/water_map.png'
import island_1 from '../images/islands/island_1.png'
import island_2 from '../images/islands/island_2.png'
import island_3 from '../images/islands/island_3.png'
import island_4 from '../images/islands/island_4.png'
import island_5 from '../images/islands/island_5.png'
import island_6 from '../images/islands/island_6.png'
import island_7 from '../images/islands/island_7.png'
import island_8 from '../images/islands/island_8.png'
import { useTranslation } from 'react-i18next';

const mapStateToProps = (state: RootState) => ({
  userObj: state.auth.user,
});

const connector = connect(mapStateToProps);
type PropsType = ConnectedProps<typeof connector> & RouteComponentProps;

const QuizLearningObjectivePage: React.FC<PropsType> = (props) => {
  const [learningUnit, setLearningUnit] = useState<IQuizLearningUnit>();
  const [learningObjectives, setLearningObjectives] = useState<
    IQuizLearningObjective[]
  >([]);
  const [selectedObjective, setSelectedObjective] =
    useState<IQuizLearningObjective>();

  const subjectId = props.location.pathname.split('/')[2];
  const learningUnitId = props.location.pathname.split('/')[4];

  const toast = useToast();
  const { t, i18n } = useTranslation()

  const { data: learningUnitData, isLoading, refetch } = useQuery({
    queryKey: ['quizLearningUnits', subjectId],
    queryFn: () => getQuizLearningUnitsByStudentAndSubject(subjectId),
    refetchOnWindowFocus: false,
    onError: () => {
      toast.show({
        title: 'Something went wrong',
        status: 'error',
        description: 'Cannot fetch learning objective',
      });
    },
  });

  useEffect(() => {
    if (learningUnitData) {
      const unit = learningUnitData.find(
        (unit: IQuizLearningUnit) => unit._id === learningUnitId
      );
      setLearningUnit(unit);
      setLearningObjectives(unit!.learningObjectives);
    }
  }, [learningUnitData])

  useEffect(() => {
    if (props.location.state !== undefined) {
      const learningUnit = props.location.state as IQuizLearningUnit;
      setLearningUnit(learningUnit)
      setLearningObjectives(learningUnit.learningObjectives);
    }
  }, [props])

  const handleObjectiveOnSelect = (objective: IQuizLearningObjective) => {
    if (objective.remainingAttempts > 0) {
      setSelectedObjective(objective);
    } else {
      toast.show({
        title: 'No attempts left',
        status: 'warning',
        description: 'You have no attempts left.',
      });
    }
  };

  const handleModalOnClose = () => {
    setSelectedObjective(undefined);
    refetch();
  };

  const islands = [
    island_1,
    island_1,
    island_2,
    island_3,
    island_4,
    island_5,
    island_6,
    island_7,
    island_8,
  ]


  return (
    <>
      <div style={{
        width: '100vw',
        height: '100vh',
        backgroundImage: `url(${water_map})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: selectedObjective ? 'hidden' : 'scroll'
      }}>
        {isLoading ? (
          <Center flex={1} px="3" w="full" h="full">
            <Spinner size="lg" />
          </Center>
        ) : (
          <>
            <Flex alignItems="center" h='100vh' w='100vw'>
              {learningObjectives?.length === 0 ? (
                <Text>{t('No learning objectives')}</Text>
              ) : (
                <>
                  {learningUnit &&
                    <>
                      <div style={{
                        width: '90%',
                        backgroundImage: `url(${islands[learningUnit?.number]})`,
                        backgroundSize: '100% 100%',
                        backgroundRepeat: 'no-repeat',
                        display: 'flex',
                        flex: 1,
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                        paddingTop: 100,
                        gap: 0,
                      }}>
                        {learningObjectives.map((objective) => (
                          <QuizProgressButton
                            type='learningObjective'
                            key={objective._id}
                            {...objective}
                            onPress={() => handleObjectiveOnSelect(objective)}
                          />
                        ))}
                      </div>
                    </>
                  }
                </>
              )}
            </Flex>
            {selectedObjective && (
              <QuizModal onClose={handleModalOnClose} {...selectedObjective} />
            )}
            <Heading size="md" textAlign="center" style={{ position: 'absolute', top: 30 }}>
              {`${learningUnit?.code}  ${i18n.language === 'zh' && learningUnit?.nameChi !== '' ? learningUnit?.nameChi : learningUnit?.nameEng}`}
            </Heading>
          </>
        )}
      </div>
    </>
  )
}

export default withRouter(connector(QuizLearningObjectivePage));
