import { FC } from 'react'
import { FileUploadResponse } from '../types/LearningMaterialType'
import { HStack, Button, Text, useTheme } from 'native-base'
import { AiOutlineDownload } from 'react-icons/ai';
import { downloadFile } from '../api/Attachment'

interface AttachmentListItemProps {
  file: FileUploadResponse
}
const AttachmentListItem: FC<AttachmentListItemProps> = (props) => {
  const { colors } = useTheme()

  return (
    <HStack alignItems={'center'} space={2} marginY={1}>
      <Text
        w='80%'>
        {props.file.originalname
        }</Text>
      <Button
        variant="subtle"
        endIcon={<AiOutlineDownload color={colors.primary[700]} />}
        onPress={() => downloadFile(props.file.filename)}
      >
        Download
      </Button>
    </HStack>
  )
}

export default AttachmentListItem