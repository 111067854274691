import FileSaver from "file-saver";
import axios from "../config/axios";

export const uploadFile = async (file: Blob, fileName: string) => {
  const formData = new FormData();
  // Update the formData object
  formData.append(
    "attachments",
    file,
    fileName
  );
  return axios.post("/attachments/upload", formData).then(res => res.data);
}

export const downloadFile = async (fileName: string) => {
  return axios({
    url: '/attachments/downloads/' + fileName,
    method: 'GET',
    responseType: 'blob'
  }).then(res => FileSaver.saveAs(res.data, fileName));
}