import { Box, Center } from 'native-base';
import { useTranslation } from 'react-i18next';
import MaterialTable from '@material-table/core';
import { tableIcons } from '../MaterialTableIcons';
import { useEffect, useState } from 'react';
import { IQuiz } from '../types/QuizTypes';
import { IUser } from '../types/AuthTypes';
import { ExportCsv } from '@material-table/exporters';
import dayjs from 'dayjs';

interface TeacherSummaryStudentListTableProps {
  data: IQuiz[] | undefined;
}
  
const TeacherSummaryStudentListTable: React.FC<TeacherSummaryStudentListTableProps> = (props) => {
  const { t, i18n } = useTranslation();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [students, setStudents] = useState<IUser[] | undefined>([]);

  useEffect(() => {
    setIsLoading(true);
    let temp: IUser[] = [];
    props.data?.forEach((quiz) => {
      if (!temp.some(student => student._id === quiz?.user?._id)) {
        temp = [...temp, quiz?.user]
      }
    })
    // Sort by class and number 
    temp.sort((a: any, b: any) => {
      let classA = a?.userProfile?.student?.class;
      let classB = b?.userProfile?.student?.class;
      if (classA < classB){
        return -1;
      } else if (classA > classB){
        return 1;
      } else {
        let numA = a?.userProfile?.student?.number;
        let numB = b?.userProfile?.student?.number;
        if (numA < numB) {
          return -1;
        } else if (numA > numB) {
          return 1;
        }
      }
      return 0;
    })
    setStudents(temp);
    setIsLoading(false);
  }, [props]);

  return (
    <Box>
      <Center padding="10px">
        <MaterialTable
          style={{ width: '100%' }}
          title={t('Student')}
          icons={tableIcons}
          isLoading={isLoading}
          columns={[
            {
              title: t('Class'),
              type: 'string',
              field: 'userProfile.student.class',
            },
            {
              title: t('Student Number'),
              type: 'numeric',
              field: 'userProfile.student.number',
              cellStyle: {
                textAlign: "left"
              }
            },
            {
              title: `${t('Last Name')} (${t('Eng')})`,
              type: 'string',
              editPlaceholder: `${t('Last Name')}  (${t('Eng')}) *`,
              field: 'lastNameEng',
            },
            {
              title: `${t('First Name')} (${t('Eng')})`,
              type: 'string',
              editPlaceholder: `${t('First Name')}  (${t('Eng')}) *`,
              field: 'firstNameEng',
            },
            {
              title: `${t('Last Name')} (${t('Chi')})`,
              type: 'string',
              field: 'lastNameChi',
            },
            {
              title: `${t('First Name')} (${t('Chi')})`,
              type: 'string',
              field: 'firstNameChi',
            },
          ]}
          data={students ?? []}
          options={{
            pageSize: 10,
            pageSizeOptions: [10, 25, 50],
            filtering: true,
            exportAllData: true,
            exportMenu: [
              {
                label: t('Export Summary'),
                exportFunc: (cols, data) => {
                  return ExportCsv(cols, data, `Student Summary Export ${dayjs().format('YYYYMMDDHHmmss')}`);
                },
              },
            ],
          }}
        />
      </Center>
    </Box>
  )
}

export default TeacherSummaryStudentListTable;
