import { getAuth } from 'firebase/auth';
import { firebase } from '../config/firebase';
import i18n from 'i18next';

export const auth = getAuth(firebase);

export const generateAuthErrorMessage = (errorCode: string) => {
  switch (errorCode) {
    case 'auth/invalid-email':
    case 'auth/user-not-found':
    case 'auth/invalid-password':
    case 'auth/wrong-password':
      return i18n.t('Invalid email or password');

    case 'auth/email-already-in-use':
    case 'auth/email-already-exists':
      return i18n.t('Email has already been used');

    case 'auth/internal-error':
      return i18n.t('Internal error');

    case 'auth/too-many-requests':
      return i18n.t('Too many attempts, please try again later');

    default:
      return i18n.t('Something went wrong');
  }
};

export const checkEmailFormat = (email: string) => {
  const regx =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regx.test(email.toLowerCase());
};
